import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import Cookie from 'js-cookie';

import {
  companysListReducer,
  companysHistoriqueReducer,
  companysFavoriteReducer
} from './reducers/companyReducers';

import {
  userSigninReducer,
  userRegisterReducer,
  userUpdateReducer,
  userDetailsReducer,
  userDeleteReducer,
} from './reducers/userReducers';

const userInfo = Cookie.get('DC_Info') || null;
const historicalInfo = Cookie.get('DC_Historical') || null;
const favoritInfo = Cookie.get('DC_Favorite') || null;

const DC_Info = userInfo ? JSON.parse(userInfo) : null;
const DC_Historical = historicalInfo ? JSON.parse(historicalInfo) : null;
const DC_Favorite = favoritInfo ? JSON.parse(favoritInfo) : null;

const initState = {
  userSignin: { DC_Info : DC_Info },
  companysHistorique: { DC_Historical : DC_Historical },
  companysFavorite: { DC_Favorite : DC_Favorite },
}; 

const reducer = combineReducers({
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  userUpdate: userUpdateReducer,
  userDetails : userDetailsReducer,
  userDelete : userDeleteReducer,
  companysList : companysListReducer,
  companysHistorique: companysHistoriqueReducer,
  companysFavorite : companysFavoriteReducer
});
 
const store = createStore(
    reducer,
    initState,
    compose(applyMiddleware(thunk))
);

export default store;