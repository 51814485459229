import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, DefaultRootState } from 'react-redux';
import { deleteAccount, signin, update } from '../actions/userActions'
import { ModalConfirmation } from '../components/modals/ModalConfirmation';

// import './Account.css'

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = (props: { [x: string]: any; password?: string; formErrors: any; }) => {
    const { formErrors, ...rest } = props;
    let valid = true;

    // validate form errors being empty
    Object.values(formErrors).forEach((val: any) => {
        val.length > 0 && (valid = false);
    });

    // validate the form was filled out
    Object.values(rest).forEach(val => {
        val === null && (valid = false);
    });

    return valid;
};

function Account(props: { location: { search: string | string[][] | Record<string, string> | URLSearchParams | undefined; }; history: string[]; }) {

    const forms = {
        password: '',
        formErrors: {
            password: ""
        }
    };

    const [state, setState] = useState(forms);
    const userSignin = useSelector((state: any) => state.userSignin);
    const { DC_Info, message } = userSignin;

    const userUpdate = useSelector((state: any) => state.userUpdate);
    const { loading, DC_Info:updateSuccess ,error } = userUpdate;
    const [showModal, setShowModal] = useState(false);
    // console.log("val DC_Info : " + JSON.stringify(DC_Info)+" send : "+statut);
    // const params = new URLSearchParams(props.location.search);
    // const verifyKey = params.get('verifyKey') ? params.get('verifyKey') : '';

    const dispatch = useDispatch();

    const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        console.log(" state : " + JSON.stringify(state));
        if (formValid(state)) {
            console.log(`
            --SUBMITTING--
            Password: ${state.password}
          `);
            dispatch(update(state.password));
        } else {
            console.error("FORM INVALID - DISPLAY ERROR MESSAGE");
        }
    };

    const handleChange = (e: { preventDefault: () => void; target: { name: any; value: any; }; }) => {
        e.preventDefault();
        const { name, value } = e.target;
        let newState = { ...state };

        switch (name) {
            case "password":
                newState.formErrors.password =
                    value.length < 6 ? "6 charactères minimum sont requis" : "";
                newState.password = value;
                break;
            default:
                break;
        }

        setState(newState);
    };

    useEffect(() => {
        if (!DC_Info) {
            props.history.push("/");
        }

    }, [DC_Info]);

    return (
        <div className='centerForm'>
            <div className='mb-4 col-md-4 col-12  p-4 shadow card'>
                <form className=" " onSubmit={handleSubmit} noValidate>
                    <div className="row">

                        <div className="col-12 text-center ">
                            <h1 className=" mb-4" >Modifier</h1>
                        </div>

                        <div className="col-12 text-center mb-3">
                            <input
                                placeholder="Adresse mail"
                                className=' form-control '

                                type="email"
                                name="email"
                                value={DC_Info?.email}
                                disabled={true}
                            />
                        </div>
                        {/* <div className="col-12 text-center ">
            {state.formErrors.email.length > 0 && (
              <span className="errorMessage">{state.formErrors.email}</span>
            )}
          </div> */}
                        <div className='col-12'>
                            <label>Nouveau mot de passe</label>
                        </div>
                        <div className="col-12 text-center mb-4">
                            <input
                                className={' form-control ' + (state.formErrors.password.length > 0 ? "error " : null)}
                                placeholder="nouveau mot de passe"
                                type="password"
                                name="password"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-12 text-center ">
                            {state.formErrors.password.length > 0 && (
                                <span className="errorMessage">{state.formErrors.password}</span>
                            )}
                        </div>

                        <div className="col-12 text-center ">
                            <button className="btn btn-primary" type="submit">Modifier</button>
                        </div>
                    </div>
                </form>

                <div className=' text-center mt-4'>

                    <ModalConfirmation
                        textButton="Supprimer mon compte"
                        title="Suppression de compte"
                        content="Etes vous sûr de bien vouloir supprimer votre compte ?"
                        show={showModal}
                        handleShow={(show: boolean) => setShowModal(show)}
                        save={() => { setShowModal(false); dispatch(deleteAccount()) }}
                    />

                </div>
                {loading && "loading..."}
                {updateSuccess &&
                    <p className='text-primary font-weight-bold ml-auto mr-auto mt-2'>Modifications éffectuées</p>
                }
                {error}
            </div>
        </div>
    )
}

export default Account