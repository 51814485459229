import React from 'react';
import axios from "axios";

import {
  listCompanys,
  getUrlDownload
} from '../actions/companyActions';

import { CodeAccountType } from '../enums/AccountType';
import { ApiCustomQuery, FormCompanyQueryModel } from '../models/CustomQuery';
import { Tools } from './tools';
import { NB_RESULTS_MAX } from '../constants/companyConstants';
import { connect } from 'react-redux';
import CompanyContainer from '../components/Company';

import * as d3 from 'd3';
import './Home.scss';
import { ModalDownloadCSV } from '../components/modals/ModalDownloadCSV';
import { ModalContext } from '../ModalContext';
import { ModalTypes } from '../enums/ModalTypes.eum';
import { DefaultQuery } from '../constants/queryConstants';
import FormCompanyQuerys from '../components/FormCompanyQuerys';
import { BsBookmarkHeartFill, BsFillArrowUpCircleFill } from 'react-icons/bs';
import { FaDownload, FaHistory, FaQuestionCircle } from 'react-icons/fa';
import HistoryButtons from '../components/HistoryButtons';
import { AiFillCloseCircle } from 'react-icons/ai';
import { InfoTypes } from '../enums/infoTypes.enum';

type MyProps = {
  listCompanys: any,
  companysList: any,
  history: any,
  location: any
};

type MyState = {
  formCompanyQuery: FormCompanyQueryModel,
  nbResults: number,
  companys: any[],
  listCompanysByPage: any,
  csvData: any[],
  detailsCompanys: any,
  errorMessage: string | null,
  urlDownload: string,
  currentApiQuery: ApiCustomQuery | undefined
};

class Home extends React.Component<MyProps, MyState> {

  static contextType = ModalContext // important pour le contexte !
  source: any;
  CancelToken = axios.CancelToken;
  helpQuery: boolean = true;
  //
  listRef = React.createRef<HTMLDivElement>();
  firstAppear: boolean;

  constructor(props: any) {
    super(props);
    this.source = this.CancelToken.source();

    this.state = {
      formCompanyQuery: {
        accountType: CodeAccountType.TOUT,
        criteres: [],
        autocomplete: [],
        querys: [],
        currentQuery: { ...DefaultQuery },
        page: 1,
        listSize: NB_RESULTS_MAX,
      },
      companys: [],
      listCompanysByPage: {},
      csvData: [],
      detailsCompanys: {},
      errorMessage: '',
      nbResults: NB_RESULTS_MAX,
      urlDownload: '',
      currentApiQuery: undefined
    };
    this.firstAppear = false;
    //
    this.listRef = React.createRef<HTMLDivElement>()   // Create a ref object 
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(): void {
    //d3.csv("canvas/querys/Canevas_1_C.csv").then(csv => console.log('csv :>> ', Tools.formatCSVCanvas(csv)));

    this.setState({ formCompanyQuery: this.getFormCompanyQuerysParams() })

  }

  getApiCustomQueryByParams(params: URLSearchParams): ApiCustomQuery {
    const accountType: CodeAccountType = Tools.getAccounType(params.get('accountType')) || CodeAccountType.TOUT;
    const querys = Tools.getCustomQueryOfParams(params);
    const pageParams = querys && params.get('page') && Tools.isInt('page') ? params.get('page') : '';
    const page: number = pageParams ? parseInt(pageParams) : 1;
    const reqestTime = params.get('reqestTime') || '0';
    const date = params.get('date') || '';

    //console.log('page', page, 'accounType :>> ', accountType);
    const apiCustomQuery: ApiCustomQuery = {
      listQuerys: querys,
      page: 1,
      accountType: accountType,
      reqestTime: reqestTime,
      date,
      limit: NB_RESULTS_MAX
    }

    return apiCustomQuery;
  }


  handleSubmit(formCompanyQuery: FormCompanyQueryModel): void {
    //console.log("handlesubmit", formCompanyQuery);
    if (formCompanyQuery) {
      //console.log("enter Submit ");
      const { querys, accountType, page } = formCompanyQuery;

      // d3.csv(Tools.getCSVRoot(accountType))
      //   .then(csv => this.setState({ csvData: Tools.formatCSV(csv) }));
      //console.log('formCompanyQuery :>> ', formCompanyQuery);
      if (querys.length > 0) {
        console.log("query exist");
        const options = { cancelToken: this.source.token };

        // call api
        this.props.listCompanys(page, accountType, querys, options);

      }
      else {
        console.log("empty");
      }

      this.setState({ formCompanyQuery })
    }
  }

  getFormCompanyQuerysParams(): FormCompanyQueryModel {
    const params = new URLSearchParams(this.props.location.search);
    const apiCustomQuery: ApiCustomQuery = this.getApiCustomQueryByParams(params);
    const criteres = Tools.getCriteresList(apiCustomQuery.accountType);
    const listSize = (apiCustomQuery.page - 1) * NB_RESULTS_MAX;
    const formCompanyQuery: FormCompanyQueryModel = {
      criteres: criteres,
      autocomplete: criteres,
      accountType: apiCustomQuery.accountType,
      querys: apiCustomQuery.listQuerys,
      page: apiCustomQuery.page,
      listSize,
      currentQuery: { ...DefaultQuery }
    }

    return formCompanyQuery;
  }

  // function call before changement, is not newState => oldState
  componentDidUpdate(prevProps: any, oldState: any) {
    //const { currentQuery } = this.state;
    const { formCompanyQuery } = this.state;

    // if (this.state.formCompanyQuery !== oldState.formCompanyQuery) {
    //   console.log("start submit");
    //   //this.handleSubmit(formCompanyQuery);
    // }
    if (this.props.location.search !== prevProps.location.search) {

      const params = new URLSearchParams(this.props.location.search);
      const apiCustomQuery: ApiCustomQuery = this.getApiCustomQueryByParams(params);
      const listSize = (apiCustomQuery.page - 1) * NB_RESULTS_MAX;

      this.setState({
        formCompanyQuery: {
          ...formCompanyQuery,
          accountType: apiCustomQuery.accountType,
          querys: apiCustomQuery.listQuerys,
          page: apiCustomQuery.page,
          listSize
        },
      })
      // currentQuery ne doit pas changé
    }
    // Utilisation classique (pensez bien à comparer les props) :notesUser
    if (this.props.companysList !== prevProps.companysList) {
      const { companys, error, querys } = this.props.companysList;

      //console.log('this.props.companyList :>> ', this.props.companysList);

      if (error) {
        this.setState({
          ...this.state,
          errorMessage: error
        });
      }
      else if (companys) {

        const { listCompanysByPage, formCompanyQuery: { page } } = this.state;
        console.log('querys :>> ', querys);
        const listSize = companys.results?.length;
        //console.log('formCompanyQuery :>> ', formCompanyQuery);
        this.setState({
          listCompanysByPage: {
            ...listCompanysByPage, [page]: companys.results,
          },
          formCompanyQuery: {
            ...formCompanyQuery,
            listSize
          },
          currentApiQuery: querys
        });

      }
    }
  }

  // loadUrlOfCompany(accountType: string, idCompany: string): void {
  //   console.log('accounType :>> ', accountType, " idCompany ", idCompany);
  //   const link = getUrlDownload(accountType, idCompany)
  //     .then(response => response.text())
  //     .then((response) => {
  //       // console.log("link : ",response)
  //       this.setState({ urlDownload: response })
  //     })
  //     .catch(err => console.log("ERROR: ", err))

  // }

  componentWillUnmount() {
    // clearInterval(this.timerAfterSelect);
    // clearInterval(this.timerAfterSelectAddress);
  }

  scrollList = (posX: number, posY: number) => {
    //this.listRef?.current?.scrollTo(posX, posY);
    window.scrollTo({
      top: posX,
      left: posY,
      behavior: "smooth"
    },);
  }

  // Annule l'appel Api
  cancelApiCall(): void {
    this.source.cancel();
    this.source = this.CancelToken.source();
  }

  openModalDownload() {
    const { currentApiQuery } = this.state;

    const { showModal } = this.context;
    //console.log('currentApiQuery :>> ', currentApiQuery);

    showModal({
      modalType: ModalTypes.DOWNLOAD,
      currentApiQuery
    });

  }

  openModalInfo() {
    const { formCompanyQuery: { accountType }, urlDownload } = this.state;
    const { showModal } = this.context;

    showModal({
      modalType: ModalTypes.INFO,
      infoType: InfoTypes.SEARCH,
    })

  }
  openModalInfoDownload() {
    const { formCompanyQuery: { accountType }, urlDownload } = this.state;
    const { showModal } = this.context;

    showModal({
      modalType: ModalTypes.INFO,
      infoType: InfoTypes.INFODOWNLOAD,
    })

  }

  openModalDetail(company: any) {
    const { formCompanyQuery: { accountType }, urlDownload } = this.state;
    const { showModal } = this.context;

    const newCompany: any = {};

    for (const key in company) {
      newCompany[key] = company[key];
    }

    //this.loadUrlOfCompany(accountType, company._id);
    showModal({
      modalType: ModalTypes.DETAILS,
      urlDownload: urlDownload,
      detailsCompanys: company,
      accountType: accountType
    })

    this.setState({ detailsCompanys: company });

  }

  render() {
    const { listCompanysByPage, currentApiQuery, formCompanyQuery } = this.state;
    const { page } = formCompanyQuery;
    const { error, success, loading } = this.props.companysList;
    return (
      <div className="">

        <div className='bloc-decor-left '>
          <div className='dc-decor-left' id="decor-left-first">
          </div>
          <div className='dc-decor-left' id="decor-left-second">
          </div>
          <div className='dc-decor-left' id="decor-left-third">
          </div>
          <div className='dc-decor-left' id="decor-left-fourth">
          </div>
        </div>
        <div className='bloc-decor-right'>

          <div className='dc-decor-right' id="decor-right-first">
          </div>
          <div className='dc-decor-right' id="decor-right-second">
          </div>
          <div className='dc-decor-right' id="decor-right-third">
          </div>
          <div className='dc-decor-right' id="decor-right-fourth">
          </div>
        </div>

        <div className='container'>
          <div className='home-title-container'>
            <h1 className='home-title'>DATA COMPANIES</h1>
          </div>

          <div className='d-flex justify-content-between'>
            <button className='btn btn-ligth'
              onClick={() => this.openModalInfo()}>
              <FaQuestionCircle size={25} color={"rgba(128, 128, 128, 0.582)"} />
            </button>
            <HistoryButtons />
          </div>
        </div>

        <FormCompanyQuerys
          formCompanyQuery={formCompanyQuery}
          changeFormCompanyQuery={(formCompanyQuery: FormCompanyQueryModel) => this.setState({ ...this.state, formCompanyQuery })}
          handleSubmit={(formCompanyQuery: FormCompanyQueryModel) => this.handleSubmit(formCompanyQuery)}
          listCompanysByPage={listCompanysByPage}
          loading={loading} />

        <div className='container'>
          <div className='d-flex justify-content-end'>

            {/* {<ModalDownloadCSV apiQquery={currentApiQuery} 
            hideModal={undefined} />} */}

            <button className='btn btn-ligth' disabled={!currentApiQuery}
              onClick={() => this.openModalDownload()}>
              <FaDownload size={25} color="green" />
            </button>
            <button className='btn btn-ligth'
              onClick={() => this.openModalInfoDownload()}>
              <FaQuestionCircle size={25} color={"rgba(128, 128, 128, 0.582)"} />
            </button>
          </div>
        </div>

        {(!listCompanysByPage[page] && !loading) &&
          <div className='col-12 font-weight-bold text-center' style={{ padding: '50px' }}>
            <p>
              Collectez toutes les informations légales, juridiques et financières sur les entreprises de votre choix (statuts, comptes sociaux, marques, dirigeants, actionnaires).Affinez votre analyse avec notre moteur de recherche avancé.
            </p>
          </div>
        }

        {/* Resultats liste entreprise */}
        <div className="p-3 mt-4 container h-100" >

          {success && listCompanysByPage[page] &&
            <div className='border-top '>Nombre de resultat sur la page : {listCompanysByPage[page].length} </div>}

          {error && <div className='border-top '><p className='text-error'>Une erreur s'est produite</p></div>}


          {loading &&

            <div className='text-center col-12 mt-2 border-top p-3'>
              <div className=" spinner-border text-secondary" style={{ width: "3rem", height: "3rem" }} role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className=' mt-2'>
                <button type="button" onClick={() => this.cancelApiCall()}
                  className='btn btn-light'  >
                  <AiFillCloseCircle color='grey' size={30} />
                </button>

              </div>
            </div>
          }

          {listCompanysByPage[page] &&

            // && listCompanysByPage[page].length > 0 &&
            //   listCompanysByPage[page][0] && listCompanysByPage[page][0]._id &&
            <div className=''>
              {listCompanysByPage[page].map((comp: any, index: number) =>
                // Composant qui affiche l'entreprise en fonction de son type 
                <CompanyContainer company={comp}
                  showDetail={() => this.openModalDetail(comp)}
                  index={index}
                />
              )}

              <div className='px-md-2 mt-2 text-center arrow-top-scroll-top'>
                <button onClick={() => this.scrollList(0, 0)} className="custom-icon">
                  <BsFillArrowUpCircleFill size={50} color={"rgba(128, 128, 128, 0.582)"} />
                </button>
              </div>
            </div>
          }

        </div>
      </div>
    );

  }
}

const mapStateToProps = (state: { companysList: any; }) => {
  return {
    companysList: state.companysList,
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    listCompanys: (listSize: number, accountType: CodeAccountType, listQuerys: any[], options: any) =>
      dispatch(listCompanys(listSize, accountType, listQuerys, options)),
    getUrlDownload: (type: string, id: string) => dispatch(getUrlDownload(type, id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);


