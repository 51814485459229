import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useEffect, useState } from 'react';
import { Tools } from '../../screen/tools';
import Form from 'react-bootstrap/Form';
import { getUrlCSV } from '../../actions/companyActions';
import { CSVRequest } from '../../models/RequestCSV.model';
import { useDispatch } from 'react-redux';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { CritereDownload, CritereSelect } from '../../models/Critere';
import { ApiCustomQuery } from '../../models/CustomQuery';
import { NB_RESULTS_MAX } from '../../constants/companyConstants';
import { QuerysFilters } from '../../models/QuerysFilters';
import AutoCompleteInputSelectComponent from '../AutocompleteInputSelect';
import ModalNotification from './Notification';
import { ModalTypes } from '../../enums/ModalTypes.eum';
import { ModalContext } from '../../ModalContext';
import React, { useContext } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const resultSizeList = [NB_RESULTS_MAX, 1000, 10000, 100000];

export const ModalDownloadCSV
    : React.FC<{ apiQquery: ApiCustomQuery | undefined, hideModal: any }> = ({ apiQquery, hideModal }) => {

        //const [critereList, setCritereList] = useState<CritereSelect[]>([]);
        const [critereDownload, setCritereDownload] = useState<CritereDownload>({ accounts: [], ratios: [] });
        const [urlCSV, setUrlCSV] = useState<string>();
        const [loading, setLoading] = useState<boolean>(false);
        const [searchText, setSearchText] = useState<string>('');
        const [allAccountActive, setAllAccountActive] = useState<boolean>(false);
        const [allRatioActive, setAllRatioActive] = useState<boolean>(false);
        const [resultSize, setResultSize] = useState<number>(apiQquery?.limit || resultSizeList[0]);
        const [showLittleData, setShowLittleData] = useState<boolean>(true);

        const dispatch = useDispatch();

        const sortBySubstring = (criteres: CritereSelect[], match: string): CritereSelect[] => {
            let newCritereList: CritereSelect[] = criteres.filter(cr => Tools.compareString(cr.traduction, match));
            return newCritereList.concat(criteres.filter(cr => !Tools.compareString(cr.traduction, match)));
        }

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            setSearchText(value);
        }

        // filter the display critere show on the screen
        const handleChangeListCriterDownload = () => {

        }

        useEffect(() => {
            //setCritereList(sortBySubstring(critereList, searchText));
            setCritereDownload({ accounts: sortBySubstring(critereDownload.accounts, searchText), ratios: sortBySubstring(critereDownload.ratios, searchText) });
        }, [searchText]);

        useEffect(() => {
            console.log('props :>> ', apiQquery);
            if (apiQquery?.accountType) {

                // setCritereList(Tools.getCritereList(apiQquery.accountType).map((cr => {
                //     return { ...cr, selected: false }
                // })));
                setCritereDownload({
                    accounts: Tools.getCritereList(apiQquery.accountType).map((cr => {
                        return { ...cr, selected: false }
                    })),
                    ratios: Tools.getRatioList().map((rat => {
                        return { ...rat, selected: false }
                    }))
                });
                console.log("enter query", critereDownload);
            }

        }, [apiQquery])

        const selectCritere = (code: string) => {
            const newCritereList: CritereSelect[] = critereDownload.accounts.map(cr => {
                return { ...cr, selected: (cr.code == code) ? !cr.selected : cr.selected }
            });
            setCritereDownload({ ...critereDownload, accounts: newCritereList });
        }
        const selectRatio = (code: string) => {
            const newCritereList: CritereSelect[] = critereDownload.ratios.map(cr => {
                return { ...cr, selected: (cr.code == code) ? !cr.selected : cr.selected }
            });
            setCritereDownload({ ...critereDownload, ratios: newCritereList });
        }

        const submit = (e: { preventDefault: () => void; }) => {
            e.preventDefault();
            console.log("submit");
            // if (critereDownload.accounts.find(cr => cr.selected) || critereDownload.ratios.find(cr => cr.selected)) {
            //     getUrlDownload();
            // }
            // else {
            //     alert('fields can not be empty',)
            // }
        }

        const getUrlDownload = () => {

            //console.log('api search :>> ', apiQquery);
            // est ce que fileds peut être vide ?

            if (critereDownload.accounts.find(cr => cr.selected) || critereDownload.ratios.find(cr => cr.selected)) {

                if (apiQquery?.accountType) {
                    setLoading(true);
                    //console.log('apiQquery :>> ', apiQquery);
                    const querysFilters: QuerysFilters = Tools.initializeQuerysFilters(apiQquery.listQuerys);

                    if (Object.keys(querysFilters.more_than).length === 0) {
                        delete querysFilters.more_than;
                    }
                    if (Object.keys(querysFilters.equals_to).length === 0) {
                        delete querysFilters.equals_to;
                    }
                    if (Object.keys(querysFilters.less_than).length === 0) {
                        delete querysFilters.less_than;
                    }
                    if (Object.keys(querysFilters.and).length === 0) {
                        delete querysFilters.and;
                    }

                    const offset: number = ((apiQquery.page - 1) * NB_RESULTS_MAX);

                    const request: CSVRequest = {
                        fields: critereDownload.accounts.filter(cr => cr.selected).map(cr => cr.code),
                        limit: resultSize,
                        offset,
                        ...querysFilters
                    };

                    console.log('request :>> ', request);


                    getUrlCSV({ request })
                        .then((response: any) => response.text())
                        .then((response: any) => {
                            if (!response.error) {
                                console.log("link : ", response)
                                setUrlCSV(response)
                                setLoading(false);
                            }
                        })
                        .catch(err => {
                            setLoading(false);
                            alert(`ERROR: ${err}`)

                        });
                }
            }
            if (critereDownload.ratios.find(cr => cr.selected)) {
                alert("Ratio download ?")
            }
            else {
                alert('fields can not be empty',)
            }
        }

        const clickAllAccountBox = () => {

            if (allAccountActive) {
                setCritereDownload({ ...critereDownload, accounts: critereDownload.accounts.map(cr => { return { ...cr, selected: false } }) })
            }
            else {
                setCritereDownload({ ...critereDownload, accounts: critereDownload.accounts.map(cr => { return { ...cr, selected: true } }) })
            }

            setAllAccountActive(!allAccountActive);
        }

        const clickAllRatioBox = () => {

            if (allRatioActive) {
                setCritereDownload({ ...critereDownload, ratios: critereDownload.ratios.map(rat => { return { ...rat, selected: false } }) })
            }
            else {
                setCritereDownload({ ...critereDownload, ratios: critereDownload.ratios.map(rat => { return { ...rat, selected: true } }) })
            }

            setAllRatioActive(!allRatioActive);
        }

        const downloadCSV = () => {
            if (urlCSV) {
                //console.log('urlCSV :>> ', urlCSV);
                window.open(urlCSV)
                hideModal();
                setUrlCSV('');
            }
            else {
                alert("Aucun lien trouvé")
            }
        }

        return (
            <Modal show={true} onHide={() => hideModal()} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Téléchargement </Modal.Title>
                </Modal.Header>

                <Modal.Body >

                    <form onSubmit={submit} className="">

                        <div className='d-flex mb-4 justify-content-center'>
                            <input value={searchText} onChange={handleChange} className='form-control col-9 ' placeholder='name...' />
                            <div className='pl-1'>

                                <AutoCompleteInputSelectComponent
                                    propsInput={{
                                        value: resultSize,
                                        name: "nbValue",
                                    }}
                                    propsList={{
                                        show: true,
                                        tabIndex: 2,
                                        items: resultSizeList.map((at: number) => {
                                            return { code: String(at), value: String(at), data: at }
                                        }),
                                        selectItem: (at: any) => setResultSize(at)
                                    }}
                                />
                            </div>

                            <div className='text-center'>
                                <button className="custom-icon" onClick={() => setSearchText('')}>
                                    <AiOutlineCloseCircle size={30} />
                                </button>
                            </div>
                        </div>

                        <div className='col-12 d-flex justify-content-between'>

                            <div className='download-shadow p-2 rounded' style={{width: "49%"}}>
                                <p>Comptes</p>

                                <input type="checkbox" className='col-1' id="allCritereList" name="allCritereList"
                                    onChange={clickAllAccountBox} checked={allAccountActive} />
                                <label >&nbsp; Tout</label>
                                <div className='scroll-container' >

                                    {showLittleData ? critereDownload.accounts.slice(0, 5).map((cr, i) =>
                                        <div key={`${i}${cr.code}checkAccount`}
                                            className={"mb-3 p-1 border rounded " + (searchText && Tools.compareString(cr.traduction, searchText) && 'bg-success p-1 text-light')}
                                            onClick={() => selectCritere(cr.code)}
                                        >
                                            <input type="checkbox" className='col-1' id="allCritereList" name="allCritereList"
                                                onChange={() => selectCritere(cr.code)} checked={critereDownload.accounts[i].selected || false}
                                            />
                                            <label >&nbsp; {`${cr.traduction}`}</label>
                                            {/* <Form.Check
                                            type={"checkbox"}
                                            id={"checkbox"}
                                            label={`${cr.traduction}`}
                                            onChange={() => selectCritere(cr.code)}
                                            checked={critereList[i].selected || false}
                                            
                                            /> */}
                                        </div>
                                    ) : critereDownload.accounts.map((cr, i) =>
                                        <div key={`${i}${cr.code}checkAccount`}
                                            className={"mb-3 p-1 border rounded " + (searchText && Tools.compareString(cr.traduction, searchText) && 'bg-success p-1 text-light')}
                                            onClick={() => selectCritere(cr.code)}
                                        >
                                            <input type="checkbox" className='col-1' id="allCritereList" name="allCritereList"
                                                onChange={() => selectCritere(cr.code)} checked={critereDownload.accounts[i].selected || false}
                                            />
                                            <label >&nbsp; {`${cr.traduction}`}</label>
                                        </div>
                                    )}
                                </div>

                            </div>
                            <div className='download-shadow p-2 rounded' style={{width: "49%"}}>
                                <p>Ratios</p>

                                <input type="checkbox" className='col-1' id="allRatioList" name="allRatioList"
                                    onChange={clickAllRatioBox} checked={allRatioActive} />
                                <label >&nbsp; Tout</label>
                                <div className='scroll-container' >
                                    {showLittleData ? critereDownload.ratios.slice(0, 5).map((rat, i) =>
                                        <div key={`${i}${rat.code}checkRatio`}
                                            className={"mb-3 p-1 border rounded " + (searchText && Tools.compareString(rat.traduction, searchText) && 'bg-success p-1 text-light')}
                                            onClick={() => selectRatio(rat.code)}
                                        >
                                            <input type="checkbox" className='col-1' id="allRatioList" name="allRatioList"
                                                onChange={() => selectRatio(rat.code)} checked={critereDownload.ratios[i].selected || false}
                                            />
                                            <label >&nbsp; {`${rat.traduction}`}</label>
                                        </div>
                                    ) :
                                        critereDownload.ratios.map((rat, i) =>
                                            <div key={`${i}${rat.code}checkRatio`}
                                                className={"mb-3 p-1 border rounded " + (searchText && Tools.compareString(rat.traduction, searchText) && 'bg-success p-1 text-light')}
                                                onClick={() => selectRatio(rat.code)}
                                            >
                                                <input type="checkbox" className='col-1' id="allRatioList" name="allRatioList"
                                                    onChange={() => selectRatio(rat.code)} checked={critereDownload.ratios[i].selected || false}
                                                />
                                                <label >&nbsp; {`${rat.traduction}`}</label>
                                            </div>
                                        )
                                    }
                                </div>

                            </div>
                            

                        </div>

                        <div className='d-flex flex-row-reverse mt-2 justify-content-center '>
                                    <button onClick={() => setShowLittleData(!showLittleData)} className="custom-icon">
                                        {showLittleData ?
                                            <IoIosArrowDown size={50} color={"rgba(128, 128, 128, 0.582)"} /> :
                                            <IoIosArrowUp size={50} color={"rgba(128, 128, 128, 0.582)"} />
                                        }
                                    </button>
                                </div>

                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => hideModal(false)}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={() => getUrlDownload()}>
                        Valider
                    </Button>
                    {loading ? "Lien en cours..." : urlCSV &&
                        <Button variant="success" onClick={() => downloadCSV()}>
                            Telecharger
                        </Button>
                    }
                </Modal.Footer>

            </Modal>
        );
    }
