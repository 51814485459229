import React, { useState, useEffect } from 'react';

import Button from 'react-bootstrap/Button'

import Alert from 'react-bootstrap/Alert'


function AlertInfo(props: { title: any; text: any; variant: any; showAlert: any; }) {
    const { title , text , variant, showAlert} = props;
    const [show, setShow] = useState(false);
    
    useEffect(() => {
      console.log("change alert : "+showAlert
      
      )
      let clearAlert: NodeJS.Timeout;

      if(showAlert){
        setShow(true);
        clearAlert = setTimeout(() => {
          setShow(false);
        },4000);
      }

      return () => {
        clearTimeout(clearAlert);
      }
    }, [showAlert]);

    return (
      show && 
      <div className="alertInfo " >
        <Alert variant={variant} className="col-md-6 col-10 shadow ml-auto mr-auto"
        onClose={() => setShow(false)} dismissible>
          <Alert.Heading>{title}</Alert.Heading>
          <p>
            {text}
          </p>
        </Alert>
      </div>
    );
}
  
function MessageInfo(props: { style: string | number; text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) {
    const styleTab = {
      danger : "border-danger text-danger",
      success : "border-success text-success",
      primary : "border-primary text-primary"
    }
    const keyTab = props.style === 'danger' ? 'danger' : props.style === 'success' ? 'success' : 'primary';
    return (
      <div className={"font-weight-bold p-2 border rounded " + styleTab[keyTab] }>
        {props.text}
      </div>
    );
}

export { AlertInfo, MessageInfo }