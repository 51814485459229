//import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom'

//import logo from './logo.svg';
import './App.scss';

import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Home from './screen/Home';
import Register from './screen/Register';
import SignIn from './screen/SignIn';
import About from './screen/About';
import Account from './screen/Account';
//import ModalContext from './ModalContext';
//import { ProviderModal } from './components/GlobalModal';
import { useState } from 'react';
//import { ProviderModal, Consumer, ModalContext } from './components/GlobalModal';
import React from 'react';
import { ModalProvider } from './ModalContext';

function App() {

  return (
    <BrowserRouter>
      <ModalProvider>
        <Navbar />
        <div id="webSiteContent" >
          <Switch>
            <Route path='/' exact  component={Home} />
            <Route path='/register' exact component={Register} />
            <Route path='/signIn' exact component={SignIn} />
            <Route path='/about' exact component={About} />
            <Route path='/account' exact component={Account} />
          </Switch>
        </div>
        <Footer />
        </ModalProvider>
    </BrowserRouter>
  );
}

export default App;