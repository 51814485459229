import React, { useEffect, useState } from 'react';
import { usePapaParse } from 'react-papaparse';
import { getUrlDownload } from '../../actions/companyActions';
import { CodeAccountType } from '../../enums/AccountType';
import { Tools } from '../../screen/tools';

const ModalDetails: React.FC<{
  hideModal: any, detailsCompanys: any, accountType: CodeAccountType
}> = ({ hideModal, detailsCompanys, accountType }) => {

  const [canvas, setCanvas] = useState<any[]>();
  const [urlDownload, seTurlDownload] = useState<string>('');

  const { readRemoteFile } = usePapaParse();

  const loadUrlOfCompany = (accountType: string, idCompany: string)=> {
    console.log('accounType :>> ', accountType, " idCompany ", idCompany);
    const link = getUrlDownload(accountType, idCompany)
      .then(response => response.text())
      .then((link) => {
        console.log("link : ",link)
        seTurlDownload(link);
        //this.setState({ urlDownload: link })
      })
      .catch(err => console.log("ERROR: ", err))

  }

  useEffect(() => {
    // chargement des donnéer en fonction du canvas
    const rootCSV = Tools.getCSVRoot(accountType);
    console.log("accountType ", accountType , ' rootCSV :>> ', rootCSV);
      readRemoteFile(rootCSV, {
        complete: (results) => {
          console.log('results.data :>> ', results.data);
          const canvaResult = Tools.getCSVFormat(results.data,detailsCompanys);
        //  console.log('canvaResult :>> ', canvaResult);
          setCanvas(canvaResult);
        },
        download: true
    });

    loadUrlOfCompany(accountType, detailsCompanys._id)
  }, [])

  return (

  <div className="modal-content">

    <div className="modal-header">

      {/* ----------------------- Titre  ------------------------ */}
      <h5 className="modal-title" id="detailCompanyModalLabel">{detailsCompanys && detailsCompanys.denomination}</h5>

      {/* ----------------------- bouton pour fermer  ------------------------ */}
      <button type="button" className="close" onClick={() => hideModal()} aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div className="modalCustom-body p-4">
      <table width="100%" >
        <tbody className='text-center'>

        {canvas?.map((c: any, i: any) => 

              <tr key={i + "tr"} >
                {c[0] ?
                  c.map((content:any,x:number) =>
                    x == 0 ?
                      <th key={x + "th" + content} className="border p-1">  {content} </th>
                      : 
                      <td key={x + "td" + content} className="border p-1">  {content} </td>
                    
                  )
                  : 
                  c.map((content:any,y:number) =>
                  <th key={y + "td" + content} className="border p-1">  {content} </th>
                  )
                }
              </tr>
        )}

        {/* 
                  {canvas.map((c: any, i: any) =>
                    (i < 16 && detailsCompanys[c[0]]) ?
                      <tr key={i + " tr "}>
                        <th key={i + "th" + c[0]}>{c[0]} </th>
        
                        <td key={i + "td" + detailsCompanys[c[0]]}>{detailsCompanys[c[0]]}  </td>
        
                      </tr>
                      :
        
                      (c[0].length > 0 && c[1].length > 0 && detailsCompanys[c[0]]) ?
                        <tr key={i + "tr"}>*/}
                          {/* <th key={i + c[0]}>{c[0]}</th> */}{/*
                          <th key={i + "th-p11" + c[1]}>{c[1]}</th>
                          <td key={i + "td-p12" + detailsCompanys[c[0]][0]}>  {detailsCompanys[c[0]][0]} </td>
                          <td key={i + "td-p13" + detailsCompanys[c[0]][1]}>  {detailsCompanys[c[0]][1]} </td>
                          <td key={i + "td-p14" + detailsCompanys[c[0]][2]}>  {detailsCompanys[c[0]][2]} </td>
                          <td key={i + "td-p15" + detailsCompanys[c[0]][3]}>  {detailsCompanys[c[0]][3]} </td>
                          <td key={i + "td-p16" + detailsCompanys[c[0]][4]}>  {detailsCompanys[c[0]][4]} </td>
        
                        </tr>
                        :
                        c[0] === "__" &&
                        <tr key={i + "tr"}>*/}
                          {/* {c.map((d,ind) => {
                                        <th key={ind + d}>{d}</th> 
                                    
                                      })} */}
                          {/* <th key={i + c[0]}>{c[0]}</th> */}
        
        {/*
                          <th key={i + "th-p11" + c[1]}>{c[1]}</th>
                          {c[2] ?
                            <th key={i + "th-p12" + c[2]}>{c[2]}</th> : null
                          }
                          {c[3] ?
                            <th key={i + "th-p13" + c[3]}>{c[3]}</th> : null
                          }
                          {c[4] ?
                            <th key={i + "th-p14" + c[4]}>{c[4]}</th> : null
                          }
                          {c[5] ?
                            <th key={i + "th-p15" + c[5]}>{c[5]}</th> : null
                          }
                          {c[6] ?
                            <th key={i + "th-p16" + c[6]}>{c[6]}</th> : null
                          }
        
                        </tr>
                  )} */}
        </tbody>
        </table>
        
    </div>
                  
    <div className="modal-footer">
      <button type="button" className="btn btn-secondary" onClick={() => hideModal()}>Retour</button>
      {urlDownload &&
      <a href={urlDownload} className="btn btn-primary">
        Télécharger
      </a> }

    </div>
  </div>
)
}
;


export default ModalDetails;