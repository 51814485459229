import { Critere } from "../models/Critere";


  
  const listCritereStringObject: Critere[] = [
    {
      code : "siren",
      traduction : "Siren"
    },
    {
      code : "adresse",
      traduction : "Adresse"
    },
    {
      code : "date_depot",
      traduction : "Date de depot"
    }, {
      code : "date_cloture_exercice",
      traduction : "Date cloture exercice"
    },
    {
      code : "denomination",
      traduction : "Denomination"
    },
    {
      code : "code_activite",
      traduction : "Code d'activité"
    },
  ];

  const listCritereString: string [] = listCritereStringObject.map(cr => cr.code);

  export { listCritereString, listCritereStringObject}