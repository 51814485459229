import Axios from "axios";
import Cookie from 'js-cookie';

import {
  USER_SIGNIN_REQUEST, USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL, USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS, USER_REGISTER_FAIL, USER_LOGOUT, USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL
} from "../constants/userConstants";

const register = (email: string, password: string) => async (dispatch: (arg0: { type: string; payload: { email: string; password: string; }; }) => void) => {
  dispatch({ type: USER_REGISTER_REQUEST, payload: { email: email, password: password} });
  try {
    console.log("register envoi : " + " email : " + email + " password : " + password)
    const { data } = await Axios.post(`${process.env.REACT_APP_DATACOMPANIES_API_LINK}createUser`, { email, password});

    console.log('register fonction action ' + JSON.stringify(data));
    
    const DC_Info_data = { email, password , uid:data} ;
    
    var in4Hours = 8 / 48;
    Cookie.set('DC_Info', JSON.stringify(DC_Info_data), {
     expires: in4Hours
    });

    dispatch({ type: USER_REGISTER_SUCCESS, payload: DC_Info_data }); // pour que loading disparaisse
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: DC_Info_data });


  } catch (error:any) {
    dispatch({ type: USER_REGISTER_FAIL, payload: error.response ? error.response.data.message : "Erreur veuillez vérifier vos identifiants" });
  }
}

const signin = (email: string, password: string) => async (dispatch: (arg0: { type: string; payload?: { email: any; password: any; } | string; }) => void) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: { email, password } });
  try {
    console.log("connexion envoi : email : " + email + " password : " + password )

    const { data } = await Axios.post(`${process.env.REACT_APP_DATACOMPANIES_API_LINK}connectUser`, { email, password });
    console.log("recu : " + JSON.stringify(data));
    if(data != false){
      const { uid } = data;
      const DC_Info_data = { email, password , uid} ;
      
      var in4Hours = 8 / 48;
      Cookie.set('DC_Info', JSON.stringify(DC_Info_data), {
      expires: in4Hours
      });

      dispatch({ type: USER_SIGNIN_SUCCESS, payload: DC_Info_data });
    }
    else{
      console.log("data false")
      dispatch({ type: USER_SIGNIN_FAIL, payload: "Erreur veuillez vérifier vos identifiants" });
    }

  } catch (error:any) {
    console.log("erreur de connexion ")
    dispatch({ type: USER_SIGNIN_FAIL, payload: error.response ? error.response.data.message : "Erreur service" });
  }
}

const details = () => async (dispatch: (arg0: { type: string; payload: any; }) => void, getState: () => { userSignin: { DC_Info: any; }; }) => {
  try {
    const {
      userSignin: { DC_Info },
    } = getState();
    
    dispatch({ type: USER_DETAILS_REQUEST, payload: DC_Info.uid });
    
    const { data } = await Axios.get(`${process.env.REACT_APP_DATACOMPANIES_API_LINK}getUser/fIQR16WOCIZThqwpe3IDJCfCDU22`,
    {
      headers: {
        Authorization: 'Bearer ' + DC_Info.token
      }
    });
   
    console.log("data : "+ JSON.stringify(data))
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data});

  } catch (error:any) {
    dispatch({ type: USER_DETAILS_FAIL, payload: error.response ? error.response.data.message : "Erreur service" });
  }
}

const update = (password: string ) => async (dispatch: (arg0: { type: string; payload: any; }) => void, getState: () => { userSignin: { DC_Info: any; }; }) => {
  const { userSignin: { DC_Info } } = getState();
  dispatch({ type: USER_UPDATE_REQUEST, payload: password });
  try {
    console.log("password : " + JSON.stringify(password));
    
    const { data } = await Axios.post(process.env.REACT_APP_DATACOMPANIES_API_LINK + "updateUser/" + DC_Info.uid,
      {"passwordHash" : password}, {
      headers: {
        Authorization: 'Bearer ' + DC_Info.token
      }
    });

    dispatch({ type: USER_UPDATE_SUCCESS, payload: data });

    //const { email : DC_Info.email, password} = password;
    // var in4Hours = 8 / 48;
    // Cookie.set('DC_Info', JSON.stringify(data), {
    //  expires: in4Hours
    // });

  } catch (error:any) {
    dispatch({ type: USER_UPDATE_FAIL, payload: error.response ? error.response.data.message : "Erreur service" });
  }
}

const deleteAccount = () => async (dispatch: (arg0: { type: string; payload?: any; success?: boolean; }) => void, getState: () => { userSignin: { DC_Info: any; }; }) => {
  try {
    const {
      userSignin: { DC_Info },
    } = getState();
    //console.log("voici suppression ");
    dispatch({ type: USER_DELETE_REQUEST });
    const { data } = await Axios.delete(`${process.env.REACT_APP_DATACOMPANIES_API_LINK}deleteUser/${DC_Info.uid}`, {
      headers: {
        Authorization: 'Bearer ' + DC_Info.token,
      },
    });
    //console.log("data: ",data);
    dispatch({ type: USER_DELETE_SUCCESS, payload: data, success: true });
    Cookie.remove("DC_Info");
    dispatch({ type: USER_LOGOUT });
  } catch (error:any) {
    dispatch({ type: USER_DELETE_FAIL, payload: error.response ? error.response.data.message : "Erreur service" });
  }
}

const logout = () => (dispatch: (arg0: { type: string; }) => void) => {
  Cookie.remove("DC_Info");
  dispatch({ type: USER_LOGOUT });
}

export {
  register,
  signin,
  details,
  update,
  logout,
  deleteAccount
};