import { Critere } from "../models/Critere";

export const ratioList: Critere[] = [
    {
        "code": "ratio1",
        "traduction": "Ratio 1"
    },
    {
        "code": "ratio2",
        "traduction": "Ratio 2"
    },
    {
        "code": "ratio3",
        "traduction": "Ratio 3"
    },
    {
        "code": "ratio4",
        "traduction": "Ratio 4"
    },
    {
        "code": "ratio5",
        "traduction": "Ratio 5"
    },
    {
        "code": "ratio6",
        "traduction": "Ratio 6"
    },
    {
        "code": "ratio7",
        "traduction": "Ratio 7"
    },
    {
        "code": "ratio8",
        "traduction": "Ratio 8"
    },
    {
        "code": "ratio9",
        "traduction": "Ratio 9"
    },
    {
        "code": "ratio10",
        "traduction": "Ratio 10"
    },
    {
        "code": "ratio11",
        "traduction": "Ratio 11"
    },
    {
        "code": "ratio12",
        "traduction": "Ratio 12"
    },
    {
        "code": "ratio13",
        "traduction": "Ratio 13"
    },
    {
        "code": "ratio14",
        "traduction": "Ratio 14"
    },
    {
        "code": "ratio15",
        "traduction": "Ratio 15"
    }
]