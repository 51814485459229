import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tools } from '../../screen/tools';

const ModalNotification: React.FC<{
    message: string, type: "classic" | "success" | "danger", position: "bottom" | "top"
}> = ({ message, type }) => {

    let timerMesage: NodeJS.Timeout;
    const [show, setShow] = useState<boolean>(false);

    const showMesage = () => {
        setShow(true);
        timerMesage = setTimeout(() => {
            //setShow(false);
        }, 3000)
    }

    useEffect(() => {
        if (message) {
            showMesage();
        }

        return (() => {
            clearTimeout(timerMesage);
        })
    }, [message]);

    return (
        <>
            {show &&
                <div className="custom-pos-notif">
                    <div className="alert alert-dark" role="alert">
                        This is a dark alert—check it out!
                    </div>
                </div>
            }
        </>
    )
}

export default ModalNotification;