export const COMPANY_LIST_REQUEST = 'COMPANY_LIST_REQUEST';
export const COMPANY_LIST_SUCCESS = 'COMPANY_LIST_SUCCESS';
export const COMPANY_LIST_FAIL = 'COMPANY_LIST_FAIL';

export const COMPANY_CLEAN_QUERY = 'COMPANY_CLEAN_QUERY';
export const COMPANY_SAVE_QUERY = 'COMPANY_SAVE_QUERY';

export const COMPANY_SAVE_FAVORITE = 'COMPANY_SAVE_FAVORITE';
export const COMPANY_REMOVE_FAVORITE = 'COMPANY_REMOVE_FAVORITE';
export const COMPANY_REMOVE_ALL_FAVORITE = 'COMPANY_REMOVE_ALL_FAVORITE';

export const NB_RESULTS_MAX: number = 100;

//export const IP_DATACOMPANIES = "18.222.229.232";