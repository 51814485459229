import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, DefaultRootState } from 'react-redux';
import { signin} from '../actions/userActions'
import { Link } from 'react-router-dom';

// import './SignIn.css'

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = (props: { [x: string]: any; email?: string; password?: string; formErrors: any; }) => {
  const { formErrors, ...rest } = props;
  let valid = true;

  // validate form errors being empty
  Object.values(formErrors).forEach((val:any) => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  Object.values(rest).forEach(val => {
    val === null && (valid = false);
  });

  return valid;
};

function SignIn(props: { location: { search: string | string[][] | Record<string, string> | URLSearchParams | undefined; }; history: string[]; }) {

  const forms = {
    email: '',
    password: '',
    formErrors: {
      email: "",
      password: ""
    }
  };

  const [state, setState] = useState(forms);
  const userSignin = useSelector((state:any) => state.userSignin);
  const { loading, DC_Info, message , error} = userSignin;
  // console.log("val DC_Info : " + JSON.stringify(DC_Info)+" send : "+statut);
  // const params = new URLSearchParams(props.location.search);
  // const verifyKey = params.get('verifyKey') ? params.get('verifyKey') : '';

  const dispatch = useDispatch();

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    console.log(" state : " + JSON.stringify(state));
    if (formValid(state)) {
      console.log(`
            --SUBMITTING--
            Email: ${state.email}
            Password: ${state.password}
          `);
      dispatch(signin(state.email, state.password));
    } else {
      console.error("FORM INVALID - DISPLAY ERROR MESSAGE");
    }
  };

  const handleChange = (e: { preventDefault: () => void; target: { name: any; value: any; }; }) => {
    e.preventDefault();
    const { name, value } = e.target;
    let newState = { ...state };

    switch (name) {
      case "email":
        newState.formErrors.email = emailRegex.test(value)
          ? ""
          : "invalid email address";
        newState.email = value;
        break;
      case "password":
        newState.formErrors.password =
          value.length < 6 ? "6 charactères minimum sont requis" : "";
        newState.password = value;
        break;
      default:
        break;
    }

    setState(newState);
  };

  useEffect(() => {

    if (DC_Info) {
      //console.log("DC_Info : " + JSON.stringify(DC_Info));
      props.history.push("/");
    }

  }, [props, DC_Info]);

  return (
    <div className='centerForm'>

    <form className=" mb-4 col-md-4 col-12 card p-4 shadow" onSubmit={handleSubmit} noValidate>
        <div className="row">

          <div className="col-12 text-center ">
            <h1 className=" mb-4" >Connexion</h1>
          </div>

          <div className="col-12 text-center mb-3">
            <input
              className={' form-control ' + (state.formErrors.email.length > 0 ? "error" : null)}
              placeholder="Adresse mail"
              type="email"
              name="email"
              onChange={handleChange}
            />
          </div>
          <div className="col-12 text-center ">
            {state.formErrors.email.length > 0 && (
              <span className="errorMessage">{state.formErrors.email}</span>
            )}
          </div>

          <div className="col-12 text-center mb-4">
            <input
              className={' form-control ' + (state.formErrors.password.length > 0 ? "error " : null)}
              placeholder="mot de passe"
              type="password"
              name="password"
              onChange={handleChange}
            />
          </div>
          <div className="col-12 text-center ">
            {state.formErrors.password.length > 0 && (
              <span className="errorMessage">{state.formErrors.password}</span>
            )}
          </div>

          {/* <div className="col-12 text-center mb-3">
                <small>mot de passe oublié</small>
              </div> */}

          <div className="col-12 text-center ">
            <button className="btn btn-primary" type="submit">Connexion</button>
          </div>

          <div className="col-12 text-center mt-4 " >
            <Link
              to='/register'
              className=''
            >
              je n'ai pas encore de compte 
            </Link>
          </div>

            <div className="col-12 text-center mt-4" >

            {(loading && 'chargement...')}
            
            {error && 
              <p className='text-danger font-weight-bold'>{error}</p>
            }

            {message &&
              <div className="alert alert-warning" role="alert">
                {message}
              </div>
            }

          </div>

        </div>
      </form>
      </div>
  )
}

export default SignIn