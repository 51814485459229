import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ApiCustomQuery } from '../../models/CustomQuery';
import { Tools } from '../../screen/tools';

const ModalHistorical: React.FC<{
    hideModal: any, historical: ApiCustomQuery[]
}> = ({ hideModal, historical = []}) => {


    return (
    <div className="modal-content">

        <div className="modal-header">
            Historique des requêtes
        </div>

        <div className="modalCustom-body p-2">
            {historical.map((request: ApiCustomQuery, index) =>
                <div key={index+"request"} className=' p-1 ' onClick={() => hideModal()}>
                    <Link to={Tools.getUrlSearchCompany(request)}
                    className="list-group-item list-group-item-action">
                    <p>
                        Type de compte : {request.accountType} Page : {request.page} Temps de requête : {request.reqestTime}
                        Date {request.date.toLocaleString()}
                    </p>
                    <div className=' d-flex flex-wrap querys-modal-history'>
                        {request.listQuerys.map((currentQuery, index) =>
                            <div key={index+"currentQuery"} className='query d-flex '>
                                {currentQuery.critereName + " " + currentQuery.operator + " à " + currentQuery.value}
                            </div>
                        )}
                        </div>

                        {/* <button className='btn' onClick={() => searchCompany(request)}> Rechercher </button> */}
                        </Link>
                </div>
            )}
        </div>

        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => hideModal()}>Retour</button>
        </div>
    </div>
)
}

export default ModalHistorical;