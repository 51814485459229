import {
    USER_SIGNIN_REQUEST, USER_SIGNIN_SUCCESS, USER_SIGNIN_FAIL,
    USER_REGISTER_REQUEST, USER_REGISTER_SUCCESS, USER_REGISTER_FAIL,
    USER_LOGOUT, USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_DELETE_REQUEST, USER_DELETE_SUCCESS, USER_DELETE_FAIL
  }
    from "../constants/userConstants";
  
  function userRegisterReducer(state = {}, action: { type: any; payload: any; }) {
    switch (action.type) {
      case USER_REGISTER_REQUEST:
        return { loading: true };
      case USER_REGISTER_SUCCESS:
        return { loading: false, user : action.payload };
      case USER_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }
  
  function userSigninReducer(state = {}, action: { type: any; payload: any; statut: any; }) {
    switch (action.type) {
      case USER_SIGNIN_REQUEST:
        return { loading: true };
      case USER_SIGNIN_SUCCESS:
        return { loading: false, DC_Info: action.payload };
      case USER_SIGNIN_FAIL:
        return { loading: false, error: action.payload };
      case USER_LOGOUT:
        return { loading: false, DC_Info: action.payload };
      default: return state;
    }
  }
  
  function userUpdateReducer(state = {}, action: { type: any; payload: any; }) {
    switch (action.type) {
      case USER_UPDATE_REQUEST:
        return { loading: true };
      case USER_UPDATE_SUCCESS:
        return { loading: false, DC_Info: action.payload };
      case USER_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }
  
  
  function userDetailsReducer(state = { parking: {} }, action: { type: any; payload: any; }) {
    switch (action.type) {
      case USER_DETAILS_REQUEST:
        return { loading: true, DC_Info: {} };
      case USER_DETAILS_SUCCESS:
        return { loading: false, DC_Info: action.payload};
      case USER_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }
  
  function userDeleteReducer(state = {}, action: { type: any; payload: any; }) {
    switch (action.type) {
      case USER_DELETE_REQUEST:
        return { loading: true };
      case USER_DELETE_SUCCESS:
        return { loading: false, DC_Info: action.payload };
      case USER_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }
  
  
  export {
    userSigninReducer, userRegisterReducer, userUpdateReducer, userDetailsReducer, userDeleteReducer
  }