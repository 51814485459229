import {

  COMPANY_LIST_REQUEST,
  COMPANY_LIST_SUCCESS,
  COMPANY_LIST_FAIL,
  COMPANY_SAVE_QUERY,
  COMPANY_CLEAN_QUERY,
  COMPANY_REMOVE_ALL_FAVORITE,
  COMPANY_REMOVE_FAVORITE,
  COMPANY_SAVE_FAVORITE,
} from '../constants/companyConstants';

function companysListReducer(state = { companys: [] }, action: { type: any; payload: any; querys: any }) {
  switch (action.type) {
    case COMPANY_LIST_REQUEST:
      return { loading: true, companys: [] };
    case COMPANY_LIST_SUCCESS:
      return { loading: false, companys: action.payload, success: true, querys: action.querys };
    case COMPANY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function companysHistoriqueReducer(state = {}, action: { type: any; payload: any; }) {
  switch (action.type) {
    case COMPANY_SAVE_QUERY:
      return { DC_Historical: action.payload };
    case COMPANY_CLEAN_QUERY:
      return { DC_Historical: action.payload };
    default:
      return state;
  }
}
function companysFavoriteReducer(state = {}, action: { type: any; payload: any; }) {
  switch (action.type) {
    case COMPANY_SAVE_FAVORITE:
      return { DC_Favorite: action.payload };
    case COMPANY_REMOVE_FAVORITE:
      return { DC_Favorite: action.payload };
    case COMPANY_REMOVE_ALL_FAVORITE:
      return { DC_Favorite: [] };
    default:
      return state;
  }
}

export {
  companysListReducer,
  companysHistoriqueReducer,
  companysFavoriteReducer
};
