import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'


export function ModalConfirmation(props:any) {
  
  return (
    <>
      <Button variant="light" onClick={() => props.handleShow(true)}>
        {props.textButton}
      </Button>

      <Modal show={props.show} onHide={() => props.handleShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.content}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.handleShow(false)}>
            Non
          </Button>
          <Button variant="danger" onClick={props.save}>
            oui
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
