import React from 'react';
import { ModalTypes } from '../../enums/ModalTypes.eum';
import ModalDetails from './ModalDetails';
import { ModalDownloadCSV } from './ModalDownloadCSV';
import ModalHistorical from './ModalHistorical';
import ModalNotification from './Notification';
import ModalInfo from './ModalInfo';

type MyProps = {};

type MyState = {};

export default class GlobalModal extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);

  }

  static getModal(modalType: ModalTypes, modalData: any) {
    console.log('modalData :>> ', modalData);
    switch (modalType) {
      case ModalTypes.HISTORY:
        return this.ModalHistorical(modalData);
      case ModalTypes.DOWNLOAD:
        return this.ModalDownload(modalData);
      case ModalTypes.DETAILS:
        return this.ModalDetail(modalData);
      case ModalTypes.INFO:
        return this.ModalInfo(modalData);
      case ModalTypes.INFODOWNLOAD:
        return this.ModalInfoDownload(modalData);
      case ModalTypes.NOTIFICATION:
        return this.ModalNotification(modalData);
        // modal confirmation
    }
  }

  static ModalHistorical = (props: any) => (
    <div className='modalCustom-page'>
      <div className='modalCustom '>
        <ModalHistorical hideModal={props.hideModal} historical={props.historical} />
      </div>
    </div>
  );

  
  static ModalDownload = (props: any) => (
        <ModalDownloadCSV apiQquery={props.currentApiQuery} hideModal={props.hideModal}/>
  );

  static ModalDetail = (props: any) => (
    <div className='modalCustom-page'>
      <div className='modalCustom '>
        <ModalDetails 
          detailsCompanys={props.detailsCompanys}
          hideModal={props.hideModal} 
          accountType={props.accountType} />
      </div>
    </div>
  );

  static ModalInfo = (props: any) => (
    <div className='modalCustom-page'>
      <div className='modalCustom-medium'>
        <ModalInfo 
          hideModal={props.hideModal} 
          infoType={props.infoType} />
      </div>
    </div>
  );

  static ModalInfoDownload = (props: any) => (
    <div className='modalCustom-page'>
      <div className='modalCustom-medium'>
        <ModalInfo 
          hideModal={props.hideModal} 
          infoType={props.infoType} />
      </div>
    </div>
  );

  static ModalNotification = (props: any) => (
    <div className='modalCustom-page'>
      <div className='modalCustom '>
        <ModalNotification message={props.message} type={props.type} position={props.bottom} />
      </div>
    </div>
  );


}
/* 
import React, { useEffect } from 'react';

const ModalDetails: React.FC<{
  urlDownload: any, hideModal: any, detailsCompanys: any, canvas: any
}> = ({ urlDownload, hideModal, detailsCompanys, canvas }) => (

  <div className="modal-content">

    <div className="modal-header">

    </div>

    <div className="modalCustom-body">


    </div>

    <div className="modal-footer">

    </div>
  </div>
);


export default ModalDetails;
*/
