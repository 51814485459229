import React, { useEffect } from 'react';
import { InfoTypes } from '../../enums/infoTypes.enum';

function getInfo(infoType: InfoTypes) {

  switch (infoType) {
    case InfoTypes.SEARCH:
      return <>
        <div className="modal-header">
          <h2>Mode opératoire du moteur de recherche</h2>
        </div>

        <div className="modalCustom-body p-4">

          <h5>1. Sélection du type de compte :</h5>
          <p>
            <span className='h6' >Choisir le type de compte :</span> Sélectionnez parmi les options disponibles : Complet, Simplifié, Consolidés, Assurance, Banque, ou "Tout" pour les champs communs à tous les types de comptes.
          </p>

           <h5>2. Sélection des champs :</h5>
          <p>
            <span className='h6' >Pour "Tout" : </span> Si "Tout" est sélectionné, seuls les champs communs à tous les types de comptes seront affichés pour la sélection.
            <span className='h6' >Pour un type spécifique de compte : </span> Choisissez parmi les champs spécifiques au type de compte sélectionné.
          </p>

           <h5>3. Choix de l'opérateur :</h5>
          <p>
            <span className='h6' >Si les valeurs sont numériques : </span> Sélectionnez l'opérateur approprié {"(<, >, =)"} pour la comparaison numérique.
            <span className='h6' >Si les valeurs ne sont pas numériques : </span> Saisissez le signe "=" pour l'égalité.
          </p>

           <h5>4. Saisie de la valeur à comparer :</h5>
          <p>
            <span className='h6' >Pour les comptes comptables numériques : </span> Saisissez une valeur numérique pour la comparaison.
            <span className='h6' >Pour d'autres champs : </span> Sélectionnez une valeur dans la liste déroulante ou saisissez une valeur alphanumérique.
          </p>

           <h5>5. Validation de la comparaison (Ajout du critère) :</h5>
          <p>
            <span className='h6' >Ajouter un critère : </span> Cliquez sur le bouton « + » pour ajouter un critère au filtre global. Vous pouvez renouveler les étapes précédentes pour ajouter un nouveau critère au filtre global.
          </p>

           <h5>6. Exécution de la recherche</h5>
          <p>
            <span className='h6' >Effectuer la recherche : </span> Cliquez sur la Loupe afin de valider le filtre global et effectuer la recherche
          </p>

           <h5>7. Exemple :</h5>
          <ol>
            <li><span className='h6' >Type de compte : </span> "Complet"</li>
            <li><span className='h6' >Champs sélectionnés : </span> « Chiffres d’affaires nets Montant France année N »</li>
            <li><span className='h6' >Opérateur : </span> "{">"}"</li>
            <li><span className='h6' >Valeur à comparer : </span> 50 000 (supérieurs à 50 000)</li>
            <li><span className='h6' >Exécution de la recherche : </span> En appuyant sur « + » puis la Loupe, cette recherche affiche tous les comptes d’entreprise de Type Complet qui ont un chiffre d’affaires supérieur à 50 000 euros.</li>
          </ol>

        </div>
      </>
    case InfoTypes.INFODOWNLOAD:
      return <>
<div className="modal-header">
  <h2>Mode opératoire de génération de rapport</h2>
</div>
<div className="modalCustom-body p-4">
  <h5>1. Sélection des champs à analyser :</h5>
  <p>
    Identifiez et sélectionnez les champs que vous souhaitez analyser, qui apparaîtront sous forme de colonnes dans votre rapport. Utilisez la barre de recherche pour trouver rapidement les champs désirés (par exemple, "Chiffre d'affaire").
  </p>
  <h5>2. Définition du nombre maximal d'observations :</h5>
  <p>
    Choisissez le nombre maximum d'observations à inclure dans votre rapport. Notez que si le nombre de résultats est inférieur à ce seuil, le fichier contiendra uniquement le nombre d'observations disponibles.
  </p>
  <h5>3. Validation de la requête :</h5>
  <p>
    Cliquez sur le bouton "Valider" pour confirmer vos choix et générer les données.
  </p>
  <h5>4. Téléchargement des données :</h5>
  <p>
    Une fois les données générées, cliquez sur "Télécharger" pour débuter le téléchargement du fichier au format CSV.
  </p>
  <h5>Conseils pour l'utilisation des données :</h5>
  <ul>
    <li><strong>Importation dans Excel :</strong> Nous recommandons d'ouvrir Excel avant de télécharger les données et d'utiliser l'option "Obtenir des données" pour importer le fichier CSV. Cela facilite la manipulation des données sous forme de tableau.</li>
    <li><strong>Attention aux champs peu renseignés :</strong> L'application tente de remplir tous les champs sélectionnés. Cependant, certains champs moins fréquemment utilisés par les entreprises peuvent limiter le volume de résultats obtenus. Par exemple, si vous sélectionnez le champ "Différences négatives de change Total année N-1", le nombre de résultats pourrait être restreint.</li>
  </ul>
</div>
      </>
  }
}

const ModalInfo: React.FC<{
  hideModal: any, infoType: InfoTypes
}> = ({ hideModal, infoType }) => {

  return (
    <div className="modal-content">

      {getInfo(infoType)}

      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={() => hideModal()}>Retour</button>
      </div>
    </div>
  )
}

export default ModalInfo;