import { Critere } from "../models/Critere";
/* 
const listCriteresComplet: Critere[] = [
    {
        code: "FJ.3",
        traduction: "Chiffre d'affaires"
    },
    {
        code: "GG.3",
        traduction: "Resultat d'exploitation"
    },
    {
        code: "DI.1",
        traduction: "Resultat Net"
    },
    {
        code: "CO.3",
        traduction: "Total Actif Net"
    },
    {
        code: "BJ.3",
        traduction: "Immobilisations Nettes"
    },
    {
        code: "CJ.3",
        traduction: "Actifs Circulants Nettes"
    },
    {
        code: "DL.1",
        traduction: "Capitaux propres"
    },
    
    {
        code: "EC.1",
        traduction: "Total Dettes"
    },
    {
        code: "YP.1",
        traduction: "Effectif moyen du personnel"
    }
];


export default listCriteresComplet */
export const listCriteresComplet: Critere[] = [
    {
        "code": "siren",
        "traduction": "siren"
    },
    {
        "code": "date_cloture_exercice",
        "traduction": "date_cloture_exercice"
    },
    {
        "code": "code_greffe",
        "traduction": "code_greffe"
    },
    {
        "code": "num_depot",
        "traduction": "num_depot"
    },
    {
        "code": "num_gestion",
        "traduction": "num_gestion"
    },
    {
        "code": "code_activite",
        "traduction": "code_activite"
    },
    {
        "code": "date_cloture_exercice_n_precedent",
        "traduction": "date_cloture_exercice_n_precedent"
    },
    {
        "code": "duree_exercice_n",
        "traduction": "duree_exercice_n"
    },
    {
        "code": "duree_exercice_n_precedent",
        "traduction": "duree_exercice_n_precedent"
    },
    {
        "code": "date_depot",
        "traduction": "date_depot"
    },
    {
        "code": "code_motif",
        "traduction": "code_motif"
    },
    {
        "code": "code_type_bilan",
        "traduction": "code_type_bilan"
    },
    {
        "code": "code_devise",
        "traduction": "code_devise"
    },
    {
        "code": "code_origine_devise",
        "traduction": "code_origine_devise"
    },
    {
        "code": "code_confidentialite",
        "traduction": "code_confidentialite"
    },
    {
        "code": "denomination",
        "traduction": "denomination"
    },
    {
        "code": "adresse",
        "traduction": "adresse"
    },
    {
        "code": "A.1",
        "traduction": "Montant brut annee N"
    },
    {
        "code": "A.2",
        "traduction": "Amortissement annee N"
    },
    {
        "code": "A.3",
        "traduction": "Net annee N"
    },
    {
        "code": "A.4",
        "traduction": "Net annee N-1"
    },
    {
        "code": "AA.1",
        "traduction": "Capital souscrit non appele Montant brut annee N"
    },
    {
        "code": "AA.2",
        "traduction": "Capital souscrit non appele Amortissement annee N"
    },
    {
        "code": "AA.3",
        "traduction": "Capital souscrit non appele Net annee N"
    },
    {
        "code": "AA.4",
        "traduction": "Capital souscrit non appele Net annee N-1"
    },
    {
        "code": "AB.1",
        "traduction": "Frais d etablissement Montant brut annee N"
    },
    {
        "code": "AB.2",
        "traduction": "Frais d etablissement Amortissement annee N"
    },
    {
        "code": "AB.3",
        "traduction": "Frais d etablissement Net annee N"
    },
    {
        "code": "AB.4",
        "traduction": "Frais d etablissement Net annee N-1"
    },
    {
        "code": "CX.1",
        "traduction": "Frais de developpement ou de recherche et developpement Montant brut annee N"
    },
    {
        "code": "CX.2",
        "traduction": "Frais de developpement ou de recherche et developpement Amortissement annee N"
    },
    {
        "code": "CX.3",
        "traduction": "Frais de developpement ou de recherche et developpement Net annee N"
    },
    {
        "code": "CX.4",
        "traduction": "Frais de developpement ou de recherche et developpement Net annee N-1"
    },
    {
        "code": "AF.1",
        "traduction": "Concessions, brevets et droits similaires Montant brut annee N"
    },
    {
        "code": "AF.2",
        "traduction": "Concessions, brevets et droits similaires Amortissement annee N"
    },
    {
        "code": "AF.3",
        "traduction": "Concessions, brevets et droits similaires Net annee N"
    },
    {
        "code": "AF.4",
        "traduction": "Concessions, brevets et droits similaires Net annee N-1"
    },
    {
        "code": "AH.1",
        "traduction": "Fonds commercial Montant brut annee N"
    },
    {
        "code": "AH.2",
        "traduction": "Fonds commercial Amortissement annee N"
    },
    {
        "code": "AH.3",
        "traduction": "Fonds commercial Net annee N"
    },
    {
        "code": "AH.4",
        "traduction": "Fonds commercial Net annee N-1"
    },
    {
        "code": "AJ.1",
        "traduction": "Autres immobilisations incorporelles Montant brut annee N"
    },
    {
        "code": "AJ.2",
        "traduction": "Autres immobilisations incorporelles Amortissement annee N"
    },
    {
        "code": "AJ.3",
        "traduction": "Autres immobilisations incorporelles Net annee N"
    },
    {
        "code": "AJ.4",
        "traduction": "Autres immobilisations incorporelles Net annee N-1"
    },
    {
        "code": "AL.1",
        "traduction": "Avances et acomptes sur immobilisations incorporelles. Montant brut annee N"
    },
    {
        "code": "AL.2",
        "traduction": "Avances et acomptes sur immobilisations incorporelles. Amortissement annee N"
    },
    {
        "code": "AL.3",
        "traduction": "Avances et acomptes sur immobilisations incorporelles. Net annee N"
    },
    {
        "code": "AL.4",
        "traduction": "Avances et acomptes sur immobilisations incorporelles. Net annee N-1"
    },
    {
        "code": "AN.1",
        "traduction": "Terrains Montant brut annee N"
    },
    {
        "code": "AN.2",
        "traduction": "Terrains Amortissement annee N"
    },
    {
        "code": "AN.3",
        "traduction": "Terrains Net annee N"
    },
    {
        "code": "AN.4",
        "traduction": "Terrains Net annee N-1"
    },
    {
        "code": "AP.1",
        "traduction": "Constructions Montant brut annee N"
    },
    {
        "code": "AP.2",
        "traduction": "Constructions Amortissement annee N"
    },
    {
        "code": "AP.3",
        "traduction": "Constructions Net annee N"
    },
    {
        "code": "AP.4",
        "traduction": "Constructions Net annee N-1"
    },
    {
        "code": "AR.1",
        "traduction": "Installations techniques, materiel et outillage industriels Montant brut annee N"
    },
    {
        "code": "AR.2",
        "traduction": "Installations techniques, materiel et outillage industriels Amortissement annee N"
    },
    {
        "code": "AR.3",
        "traduction": "Installations techniques, materiel et outillage industriels Net annee N"
    },
    {
        "code": "AR.4",
        "traduction": "Installations techniques, materiel et outillage industriels Net annee N-1"
    },
    {
        "code": "AT.1",
        "traduction": "Autres immobilisations corporelles Montant brut annee N"
    },
    {
        "code": "AT.2",
        "traduction": "Autres immobilisations corporelles Amortissement annee N"
    },
    {
        "code": "AT.3",
        "traduction": "Autres immobilisations corporelles Net annee N"
    },
    {
        "code": "AT.4",
        "traduction": "Autres immobilisations corporelles Net annee N-1"
    },
    {
        "code": "AV.1",
        "traduction": "Immobilisations en cours Montant brut annee N"
    },
    {
        "code": "AV.2",
        "traduction": "Immobilisations en cours Amortissement annee N"
    },
    {
        "code": "AV.3",
        "traduction": "Immobilisations en cours Net annee N"
    },
    {
        "code": "AV.4",
        "traduction": "Immobilisations en cours Net annee N-1"
    },
    {
        "code": "AX.1",
        "traduction": "Avances et acomptes Montant brut annee N"
    },
    {
        "code": "AX.2",
        "traduction": "Avances et acomptes Amortissement annee N"
    },
    {
        "code": "AX.3",
        "traduction": "Avances et acomptes Net annee N"
    },
    {
        "code": "AX.4",
        "traduction": "Avances et acomptes Net annee N-1"
    },
    {
        "code": "CS.1",
        "traduction": "Participations evaluees - mise en equivalence Montant brut annee N"
    },
    {
        "code": "CS.2",
        "traduction": "Participations evaluees - mise en equivalence Amortissement annee N"
    },
    {
        "code": "CS.3",
        "traduction": "Participations evaluees - mise en equivalence Net annee N"
    },
    {
        "code": "CS.4",
        "traduction": "Participations evaluees - mise en equivalence Net annee N-1"
    },
    {
        "code": "CU.1",
        "traduction": "Autres participations Montant brut annee N"
    },
    {
        "code": "CU.2",
        "traduction": "Autres participations Amortissement annee N"
    },
    {
        "code": "CU.3",
        "traduction": "Autres participations Net annee N"
    },
    {
        "code": "CU.4",
        "traduction": "Autres participations Net annee N-1"
    },
    {
        "code": "BB.1",
        "traduction": "Creances rattachees a  des participations Montant brut annee N"
    },
    {
        "code": "BB.2",
        "traduction": "Creances rattachees a  des participations Amortissement annee N"
    },
    {
        "code": "BB.3",
        "traduction": "Creances rattachees a  des participations Net annee N"
    },
    {
        "code": "BB.4",
        "traduction": "Creances rattachees a  des participations Net annee N-1"
    },
    {
        "code": "BD.1",
        "traduction": "Autres titres immobilises Montant brut annee N"
    },
    {
        "code": "BD.2",
        "traduction": "Autres titres immobilises Amortissement annee N"
    },
    {
        "code": "BD.3",
        "traduction": "Autres titres immobilises Net annee N"
    },
    {
        "code": "BD.4",
        "traduction": "Autres titres immobilises Net annee N-1"
    },
    {
        "code": "BF.1",
        "traduction": "Prets Montant brut annee N"
    },
    {
        "code": "BF.2",
        "traduction": "Prets Amortissement annee N"
    },
    {
        "code": "BF.3",
        "traduction": "Prets Net annee N"
    },
    {
        "code": "BF.4",
        "traduction": "Prets Net annee N-1"
    },
    {
        "code": "BH.1",
        "traduction": "Autres immobilisations financieres Montant brut annee N"
    },
    {
        "code": "BH.2",
        "traduction": "Autres immobilisations financieres Amortissement annee N"
    },
    {
        "code": "BH.3",
        "traduction": "Autres immobilisations financieres Net annee N"
    },
    {
        "code": "BH.4",
        "traduction": "Autres immobilisations financieres Net annee N-1"
    },
    {
        "code": "BJ.1",
        "traduction": "TOTAL IMMOBILISATIONS Montant brut annee N"
    },
    {
        "code": "BJ.2",
        "traduction": "TOTAL IMMOBILISATIONS Amortissement annee N"
    },
    {
        "code": "BJ.3",
        "traduction": "TOTAL IMMOBILISATIONS Net annee N"
    },
    {
        "code": "BJ.4",
        "traduction": "TOTAL IMMOBILISATIONS Net annee N-1"
    },
    {
        "code": "BL.1",
        "traduction": "Matieres premieres, approvisionnements Montant brut annee N"
    },
    {
        "code": "BL.2",
        "traduction": "Matieres premieres, approvisionnements Amortissement annee N"
    },
    {
        "code": "BL.3",
        "traduction": "Matieres premieres, approvisionnements Net annee N"
    },
    {
        "code": "BL.4",
        "traduction": "Matieres premieres, approvisionnements Net annee N-1"
    },
    {
        "code": "BN.1",
        "traduction": "En cours de production de biens Montant brut annee N"
    },
    {
        "code": "BN.2",
        "traduction": "En cours de production de biens Amortissement annee N"
    },
    {
        "code": "BN.3",
        "traduction": "En cours de production de biens Net annee N"
    },
    {
        "code": "BN.4",
        "traduction": "En cours de production de biens Net annee N-1"
    },
    {
        "code": "BP.1",
        "traduction": "En cours de production de services Montant brut annee N"
    },
    {
        "code": "BP.2",
        "traduction": "En cours de production de services Amortissement annee N"
    },
    {
        "code": "BP.3",
        "traduction": "En cours de production de services Net annee N"
    },
    {
        "code": "BP.4",
        "traduction": "En cours de production de services Net annee N-1"
    },
    {
        "code": "BR.1",
        "traduction": "Produits intermediaires et finis Montant brut annee N"
    },
    {
        "code": "BR.2",
        "traduction": "Produits intermediaires et finis Amortissement annee N"
    },
    {
        "code": "BR.3",
        "traduction": "Produits intermediaires et finis Net annee N"
    },
    {
        "code": "BR.4",
        "traduction": "Produits intermediaires et finis Net annee N-1"
    },
    {
        "code": "BT.1",
        "traduction": "Marchandises Montant brut annee N"
    },
    {
        "code": "BT.2",
        "traduction": "Marchandises Amortissement annee N"
    },
    {
        "code": "BT.3",
        "traduction": "Marchandises Net annee N"
    },
    {
        "code": "BT.4",
        "traduction": "Marchandises Net annee N-1"
    },
    {
        "code": "BV.1",
        "traduction": "Avances et acomptes verses sur commandes Montant brut annee N"
    },
    {
        "code": "BV.2",
        "traduction": "Avances et acomptes verses sur commandes Amortissement annee N"
    },
    {
        "code": "BV.3",
        "traduction": "Avances et acomptes verses sur commandes Net annee N"
    },
    {
        "code": "BV.4",
        "traduction": "Avances et acomptes verses sur commandes Net annee N-1"
    },
    {
        "code": "BX.1",
        "traduction": "Clients et comptes rattaches Montant brut annee N"
    },
    {
        "code": "BX.2",
        "traduction": "Clients et comptes rattaches Amortissement annee N"
    },
    {
        "code": "BX.3",
        "traduction": "Clients et comptes rattaches Net annee N"
    },
    {
        "code": "BX.4",
        "traduction": "Clients et comptes rattaches Net annee N-1"
    },
    {
        "code": "BZ.1",
        "traduction": "Autres creances Montant brut annee N"
    },
    {
        "code": "BZ.2",
        "traduction": "Autres creances Amortissement annee N"
    },
    {
        "code": "BZ.3",
        "traduction": "Autres creances Net annee N"
    },
    {
        "code": "BZ.4",
        "traduction": "Autres creances Net annee N-1"
    },
    {
        "code": "CB.1",
        "traduction": "Capital souscrit et appele, non verse Montant brut annee N"
    },
    {
        "code": "CB.2",
        "traduction": "Capital souscrit et appele, non verse Amortissement annee N"
    },
    {
        "code": "CB.3",
        "traduction": "Capital souscrit et appele, non verse Net annee N"
    },
    {
        "code": "CB.4",
        "traduction": "Capital souscrit et appele, non verse Net annee N-1"
    },
    {
        "code": "CD.1",
        "traduction": "Valeurs mobilieres de placement Montant brut annee N"
    },
    {
        "code": "CD.2",
        "traduction": "Valeurs mobilieres de placement Amortissement annee N"
    },
    {
        "code": "CD.3",
        "traduction": "Valeurs mobilieres de placement Net annee N"
    },
    {
        "code": "CD.4",
        "traduction": "Valeurs mobilieres de placement Net annee N-1"
    },
    {
        "code": "CF.1",
        "traduction": "Disponibilites Montant brut annee N"
    },
    {
        "code": "CF.2",
        "traduction": "Disponibilites Amortissement annee N"
    },
    {
        "code": "CF.3",
        "traduction": "Disponibilites Net annee N"
    },
    {
        "code": "CF.4",
        "traduction": "Disponibilites Net annee N-1"
    },
    {
        "code": "CH.1",
        "traduction": "Charges constatees d avance Montant brut annee N"
    },
    {
        "code": "CH.2",
        "traduction": "Charges constatees d avance Amortissement annee N"
    },
    {
        "code": "CH.3",
        "traduction": "Charges constatees d avance Net annee N"
    },
    {
        "code": "CH.4",
        "traduction": "Charges constatees d avance Net annee N-1"
    },
    {
        "code": "CJ.1",
        "traduction": "TOTAL ACTIFS CIRCULANTS Montant brut annee N"
    },
    {
        "code": "CJ.2",
        "traduction": "TOTAL ACTIFS CIRCULANTS Amortissement annee N"
    },
    {
        "code": "CJ.3",
        "traduction": "TOTAL ACTIFS CIRCULANTS Net annee N"
    },
    {
        "code": "CJ.4",
        "traduction": "TOTAL ACTIFS CIRCULANTS Net annee N-1"
    },
    {
        "code": "CW.1",
        "traduction": "Charges a  repartir ou frais d emission d emprunt Montant brut annee N"
    },
    {
        "code": "CW.2",
        "traduction": "Charges a  repartir ou frais d emission d emprunt Amortissement annee N"
    },
    {
        "code": "CW.3",
        "traduction": "Charges a  repartir ou frais d emission d emprunt Net annee N"
    },
    {
        "code": "CW.4",
        "traduction": "Charges a  repartir ou frais d emission d emprunt Net annee N-1"
    },
    {
        "code": "CM.1",
        "traduction": "Primes de remboursement des obligations (IV) Montant brut annee N"
    },
    {
        "code": "CM.2",
        "traduction": "Primes de remboursement des obligations (IV) Amortissement annee N"
    },
    {
        "code": "CM.3",
        "traduction": "Primes de remboursement des obligations (IV) Net annee N"
    },
    {
        "code": "CM.4",
        "traduction": "Primes de remboursement des obligations (IV) Net annee N-1"
    },
    {
        "code": "CN.1",
        "traduction": "Ecarts de conversion actif (V) Montant brut annee N"
    },
    {
        "code": "CN.2",
        "traduction": "Ecarts de conversion actif (V) Amortissement annee N"
    },
    {
        "code": "CN.3",
        "traduction": "Ecarts de conversion actif (V) Net annee N"
    },
    {
        "code": "CN.4",
        "traduction": "Ecarts de conversion actif (V) Net annee N-1"
    },
    {
        "code": "CO.1",
        "traduction": "TOTAL ACTIF Montant brut annee N"
    },
    {
        "code": "CO.2",
        "traduction": "TOTAL ACTIF Amortissement annee N"
    },
    {
        "code": "CO.3",
        "traduction": "TOTAL ACTIF Net annee N"
    },
    {
        "code": "CO.4",
        "traduction": "TOTAL ACTIF Net annee N-1"
    },
    {
        "code": "CP.1",
        "traduction": "Parts a  moins d un an Montant brut annee N"
    },
    {
        "code": "CP.2",
        "traduction": "Parts a  moins d un an Amortissement annee N"
    },
    {
        "code": "CP.3",
        "traduction": "Parts a  moins d un an Net annee N"
    },
    {
        "code": "CP.4",
        "traduction": "Parts a  moins d un an Net annee N-1"
    },
    {
        "code": "CR.1",
        "traduction": "Parts a  plus d un an Montant brut annee N"
    },
    {
        "code": "CR.2",
        "traduction": "Parts a  plus d un an Amortissement annee N"
    },
    {
        "code": "CR.3",
        "traduction": "Parts a  plus d un an Net annee N"
    },
    {
        "code": "CR.4",
        "traduction": "Parts a  plus d un an Net annee N-1"
    },
    {
        "code": "P.1",
        "traduction": "Montant annee N"
    },
    {
        "code": "P.2",
        "traduction": "Montant annee N-1"
    },
    {
        "code": "P.3",
        "traduction": ""
    },
    {
        "code": "P.4",
        "traduction": ""
    },
    {
        "code": "DA.1",
        "traduction": "Capital social ou individuel Montant annee N"
    },
    {
        "code": "DA.2",
        "traduction": "Capital social ou individuel Montant annee N-1"
    },
    {
        "code": "DA.3",
        "traduction": ""
    },
    {
        "code": "DA.4",
        "traduction": ""
    },
    {
        "code": "DB.1",
        "traduction": "Primes d emission, de fusion, d apport, ... Montant annee N"
    },
    {
        "code": "DB.2",
        "traduction": "Primes d emission, de fusion, d apport, ... Montant annee N-1"
    },
    {
        "code": "DB.3",
        "traduction": ""
    },
    {
        "code": "DB.4",
        "traduction": ""
    },
    {
        "code": "EK.1",
        "traduction": "(dont ecart d equivalence) Montant annee N"
    },
    {
        "code": "EK.2",
        "traduction": "(dont ecart d equivalence) Montant annee N-1"
    },
    {
        "code": "EK.3",
        "traduction": ""
    },
    {
        "code": "EK.4",
        "traduction": ""
    },
    {
        "code": "DC.1",
        "traduction": "Ecarts de reevaluation Montant annee N"
    },
    {
        "code": "DC.2",
        "traduction": "Ecarts de reevaluation Montant annee N-1"
    },
    {
        "code": "DC.3",
        "traduction": ""
    },
    {
        "code": "DC.4",
        "traduction": ""
    },
    {
        "code": "DD.1",
        "traduction": "Reserve legale (1) Montant annee N"
    },
    {
        "code": "DD.2",
        "traduction": "Reserve legale (1) Montant annee N-1"
    },
    {
        "code": "DD.3",
        "traduction": ""
    },
    {
        "code": "DD.4",
        "traduction": ""
    },
    {
        "code": "DE.1",
        "traduction": "Reserves statutaires ou contractuelles Montant annee N"
    },
    {
        "code": "DE.2",
        "traduction": "Reserves statutaires ou contractuelles Montant annee N-1"
    },
    {
        "code": "DE.3",
        "traduction": ""
    },
    {
        "code": "DE.4",
        "traduction": ""
    },
    {
        "code": "B1.1",
        "traduction": "Reserve speciale des provisions pour fluctuation des cours (sur reserves statutaires ou contractuelles) Montant annee N"
    },
    {
        "code": "B1.2",
        "traduction": "Reserve speciale des provisions pour fluctuation des cours (sur reserves statutaires ou contractuelles) Montant annee N-1"
    },
    {
        "code": "B1.3",
        "traduction": ""
    },
    {
        "code": "B1.4",
        "traduction": ""
    },
    {
        "code": "DF.1",
        "traduction": "Reserves reglementees (1) Montant annee N"
    },
    {
        "code": "DF.2",
        "traduction": "Reserves reglementees (1) Montant annee N-1"
    },
    {
        "code": "DF.3",
        "traduction": ""
    },
    {
        "code": "DF.4",
        "traduction": ""
    },
    {
        "code": "EJ.1",
        "traduction": "Reserve relative a  l achat d oeuvres originales d artistes vivants (sur Reserves reglementees (1)) Montant annee N"
    },
    {
        "code": "EJ.2",
        "traduction": "Reserve relative a  l achat d oeuvres originales d artistes vivants (sur Reserves reglementees (1)) Montant annee N-1"
    },
    {
        "code": "EJ.3",
        "traduction": ""
    },
    {
        "code": "EJ.4",
        "traduction": ""
    },
    {
        "code": "DG.1",
        "traduction": "Autres reserves Montant annee N"
    },
    {
        "code": "DG.2",
        "traduction": "Autres reserves Montant annee N-1"
    },
    {
        "code": "DG.3",
        "traduction": ""
    },
    {
        "code": "DG.4",
        "traduction": ""
    },
    {
        "code": "DH.1",
        "traduction": "Report a  nouveau Montant annee N"
    },
    {
        "code": "DH.2",
        "traduction": "Report a  nouveau Montant annee N-1"
    },
    {
        "code": "DH.3",
        "traduction": ""
    },
    {
        "code": "DH.4",
        "traduction": ""
    },
    {
        "code": "DI.1",
        "traduction": "RESULTAT DE L EXERCICE (benefice ou perte) Montant annee N"
    },
    {
        "code": "DI.2",
        "traduction": "RESULTAT DE L EXERCICE (benefice ou perte) Montant annee N-1"
    },
    {
        "code": "DI.3",
        "traduction": ""
    },
    {
        "code": "DI.4",
        "traduction": ""
    },
    {
        "code": "DJ.1",
        "traduction": "Subventions d investissement Montant annee N"
    },
    {
        "code": "DJ.2",
        "traduction": "Subventions d investissement Montant annee N-1"
    },
    {
        "code": "DJ.3",
        "traduction": ""
    },
    {
        "code": "DJ.4",
        "traduction": ""
    },
    {
        "code": "DK.1",
        "traduction": "Provisions reglementees Montant annee N"
    },
    {
        "code": "DK.2",
        "traduction": "Provisions reglementees Montant annee N-1"
    },
    {
        "code": "DK.3",
        "traduction": ""
    },
    {
        "code": "DK.4",
        "traduction": ""
    },
    {
        "code": "DL.1",
        "traduction": "CAPITAUX PROPRES Montant annee N"
    },
    {
        "code": "DL.2",
        "traduction": "CAPITAUX PROPRES Montant annee N-1"
    },
    {
        "code": "DL.3",
        "traduction": ""
    },
    {
        "code": "DL.4",
        "traduction": ""
    },
    {
        "code": "DM.1",
        "traduction": "Produit des emissions de titres participatifs Montant annee N"
    },
    {
        "code": "DM.2",
        "traduction": "Produit des emissions de titres participatifs Montant annee N-1"
    },
    {
        "code": "DM.3",
        "traduction": ""
    },
    {
        "code": "DM.4",
        "traduction": ""
    },
    {
        "code": "DN.1",
        "traduction": "Avances conditionnees Montant annee N"
    },
    {
        "code": "DN.2",
        "traduction": "Avances conditionnees Montant annee N-1"
    },
    {
        "code": "DN.3",
        "traduction": ""
    },
    {
        "code": "DN.4",
        "traduction": ""
    },
    {
        "code": "DO.1",
        "traduction": "AUTRES FONDS PROPRES Montant annee N"
    },
    {
        "code": "DO.2",
        "traduction": "AUTRES FONDS PROPRES Montant annee N-1"
    },
    {
        "code": "DO.3",
        "traduction": ""
    },
    {
        "code": "DO.4",
        "traduction": ""
    },
    {
        "code": "DP.1",
        "traduction": "Provisions pour risques Montant annee N"
    },
    {
        "code": "DP.2",
        "traduction": "Provisions pour risques Montant annee N-1"
    },
    {
        "code": "DP.3",
        "traduction": ""
    },
    {
        "code": "DP.4",
        "traduction": ""
    },
    {
        "code": "DQ.1",
        "traduction": "Provisions pour charges Montant annee N"
    },
    {
        "code": "DQ.2",
        "traduction": "Provisions pour charges Montant annee N-1"
    },
    {
        "code": "DQ.3",
        "traduction": ""
    },
    {
        "code": "DQ.4",
        "traduction": ""
    },
    {
        "code": "DR.1",
        "traduction": "PROVISIONS POUR RISQUES ET CHARGES Montant annee N"
    },
    {
        "code": "DR.2",
        "traduction": "PROVISIONS POUR RISQUES ET CHARGES Montant annee N-1"
    },
    {
        "code": "DR.3",
        "traduction": ""
    },
    {
        "code": "DR.4",
        "traduction": ""
    },
    {
        "code": "DS.1",
        "traduction": "Emprunts obligataires convertibles Montant annee N"
    },
    {
        "code": "DS.2",
        "traduction": "Emprunts obligataires convertibles Montant annee N-1"
    },
    {
        "code": "DS.3",
        "traduction": ""
    },
    {
        "code": "DS.4",
        "traduction": ""
    },
    {
        "code": "DT.1",
        "traduction": "Autres emprunts obligataires Montant annee N"
    },
    {
        "code": "DT.2",
        "traduction": "Autres emprunts obligataires Montant annee N-1"
    },
    {
        "code": "DT.3",
        "traduction": ""
    },
    {
        "code": "DT.4",
        "traduction": ""
    },
    {
        "code": "DU.1",
        "traduction": "Emprunts et dettes aupres des etablissements de credit (3) Montant annee N"
    },
    {
        "code": "DU.2",
        "traduction": "Emprunts et dettes aupres des etablissements de credit (3) Montant annee N-1"
    },
    {
        "code": "DU.3",
        "traduction": ""
    },
    {
        "code": "DU.4",
        "traduction": ""
    },
    {
        "code": "DV.1",
        "traduction": "Emprunts et dettes financieres divers (4) Montant annee N"
    },
    {
        "code": "DV.2",
        "traduction": "Emprunts et dettes financieres divers (4) Montant annee N-1"
    },
    {
        "code": "DV.3",
        "traduction": ""
    },
    {
        "code": "DV.4",
        "traduction": ""
    },
    {
        "code": "DW.1",
        "traduction": "Avances et acomptes rea§us sur commandes en cours Montant annee N"
    },
    {
        "code": "DW.2",
        "traduction": "Avances et acomptes rea§us sur commandes en cours Montant annee N-1"
    },
    {
        "code": "DW.3",
        "traduction": ""
    },
    {
        "code": "DW.4",
        "traduction": ""
    },
    {
        "code": "DX.1",
        "traduction": "Dettes fournisseurs et comptes rattaches Montant annee N"
    },
    {
        "code": "DX.2",
        "traduction": "Dettes fournisseurs et comptes rattaches Montant annee N-1"
    },
    {
        "code": "DX.3",
        "traduction": ""
    },
    {
        "code": "DX.4",
        "traduction": ""
    },
    {
        "code": "DY.1",
        "traduction": "Dettes fiscales et sociales Montant annee N"
    },
    {
        "code": "DY.2",
        "traduction": "Dettes fiscales et sociales Montant annee N-1"
    },
    {
        "code": "DY.3",
        "traduction": ""
    },
    {
        "code": "DY.4",
        "traduction": ""
    },
    {
        "code": "DZ.1",
        "traduction": "Dettes sur immobilisations et comptes rattaches Montant annee N"
    },
    {
        "code": "DZ.2",
        "traduction": "Dettes sur immobilisations et comptes rattaches Montant annee N-1"
    },
    {
        "code": "DZ.3",
        "traduction": ""
    },
    {
        "code": "DZ.4",
        "traduction": ""
    },
    {
        "code": "EA.1",
        "traduction": "Autres dettes Montant annee N"
    },
    {
        "code": "EA.2",
        "traduction": "Autres dettes Montant annee N-1"
    },
    {
        "code": "EA.3",
        "traduction": ""
    },
    {
        "code": "EA.4",
        "traduction": ""
    },
    {
        "code": "EB.1",
        "traduction": "Produits constates d avance (2) Montant annee N"
    },
    {
        "code": "EB.2",
        "traduction": "Produits constates d avance (2) Montant annee N-1"
    },
    {
        "code": "EB.3",
        "traduction": ""
    },
    {
        "code": "EB.4",
        "traduction": ""
    },
    {
        "code": "EC.1",
        "traduction": "TOTAL DETTES Montant annee N"
    },
    {
        "code": "EC.2",
        "traduction": "TOTAL DETTES Montant annee N-1"
    },
    {
        "code": "EC.3",
        "traduction": ""
    },
    {
        "code": "EC.4",
        "traduction": ""
    },
    {
        "code": "ED.1",
        "traduction": "(V) Montant annee N"
    },
    {
        "code": "ED.2",
        "traduction": "(V) Montant annee N-1"
    },
    {
        "code": "ED.3",
        "traduction": ""
    },
    {
        "code": "ED.4",
        "traduction": ""
    },
    {
        "code": "EE.1",
        "traduction": "TOTAL PASSIF Montant annee N"
    },
    {
        "code": "EE.2",
        "traduction": "TOTAL PASSIF Montant annee N-1"
    },
    {
        "code": "EE.3",
        "traduction": ""
    },
    {
        "code": "EE.4",
        "traduction": ""
    },
    {
        "code": "EF.1",
        "traduction": "Reserve reglementee des plus-values a  long terme (sur Passif) Montant annee N"
    },
    {
        "code": "EF.2",
        "traduction": "Reserve reglementee des plus-values a  long terme (sur Passif) Montant annee N-1"
    },
    {
        "code": "EF.3",
        "traduction": ""
    },
    {
        "code": "EF.4",
        "traduction": ""
    },
    {
        "code": "EG.1",
        "traduction": "Dettes et produits constates d avance a  moins d un an (sur Passif) Montant annee N"
    },
    {
        "code": "EG.2",
        "traduction": "Dettes et produits constates d avance a  moins d un an (sur Passif) Montant annee N-1"
    },
    {
        "code": "EG.3",
        "traduction": ""
    },
    {
        "code": "EG.4",
        "traduction": ""
    },
    {
        "code": "EH.1",
        "traduction": "Concours bancaires courants,  et soldes crediteurs de banques et C.C.P (sur Passif) Montant annee N"
    },
    {
        "code": "EH.2",
        "traduction": "Concours bancaires courants,  et soldes crediteurs de banques et C.C.P (sur Passif) Montant annee N-1"
    },
    {
        "code": "EH.3",
        "traduction": ""
    },
    {
        "code": "EH.4",
        "traduction": ""
    },
    {
        "code": "EI.1",
        "traduction": "Emprunts participatifs (sur Passif) Montant annee N"
    },
    {
        "code": "EI.2",
        "traduction": "Emprunts participatifs (sur Passif) Montant annee N-1"
    },
    {
        "code": "EI.3",
        "traduction": ""
    },
    {
        "code": "EI.4",
        "traduction": ""
    },
    {
        "code": "CDR.1",
        "traduction": "Montant France annee N"
    },
    {
        "code": "CDR.2",
        "traduction": "Montant Export annee N"
    },
    {
        "code": "CDR.3",
        "traduction": "Total annee N"
    },
    {
        "code": "CDR.4",
        "traduction": "Total annee N-1"
    },
    {
        "code": "FA.1",
        "traduction": "Ventes de marchandises Montant France annee N"
    },
    {
        "code": "FA.2",
        "traduction": "Ventes de marchandises Montant Export annee N"
    },
    {
        "code": "FA.3",
        "traduction": "Ventes de marchandises Total annee N"
    },
    {
        "code": "FA.4",
        "traduction": "Ventes de marchandises Total annee N-1"
    },
    {
        "code": "FD.1",
        "traduction": "Production vendue biens Montant France annee N"
    },
    {
        "code": "FD.2",
        "traduction": "Production vendue biens Montant Export annee N"
    },
    {
        "code": "FD.3",
        "traduction": "Production vendue biens Total annee N"
    },
    {
        "code": "FD.4",
        "traduction": "Production vendue biens Total annee N-1"
    },
    {
        "code": "FG.1",
        "traduction": "Production vendue services Montant France annee N"
    },
    {
        "code": "FG.2",
        "traduction": "Production vendue services Montant Export annee N"
    },
    {
        "code": "FG.3",
        "traduction": "Production vendue services Total annee N"
    },
    {
        "code": "FG.4",
        "traduction": "Production vendue services Total annee N-1"
    },
    {
        "code": "FJ.1",
        "traduction": "Chiffres d affaires nets Montant France annee N"
    },
    {
        "code": "FJ.2",
        "traduction": "Chiffres d affaires nets Montant Export annee N"
    },
    {
        "code": "FJ.3",
        "traduction": "Chiffres d affaires nets Total annee N"
    },
    {
        "code": "FJ.4",
        "traduction": "Chiffres d affaires nets Total annee N-1"
    },
    {
        "code": "FM.1",
        "traduction": "Production stockee Montant France annee N"
    },
    {
        "code": "FM.2",
        "traduction": "Production stockee Montant Export annee N"
    },
    {
        "code": "FM.3",
        "traduction": "Production stockee Total annee N"
    },
    {
        "code": "FM.4",
        "traduction": "Production stockee Total annee N-1"
    },
    {
        "code": "FN.1",
        "traduction": "Production immobilisee Montant France annee N"
    },
    {
        "code": "FN.2",
        "traduction": "Production immobilisee Montant Export annee N"
    },
    {
        "code": "FN.3",
        "traduction": "Production immobilisee Total annee N"
    },
    {
        "code": "FN.4",
        "traduction": "Production immobilisee Total annee N-1"
    },
    {
        "code": "FO.1",
        "traduction": "Subventions d exploitation Montant France annee N"
    },
    {
        "code": "FO.2",
        "traduction": "Subventions d exploitation Montant Export annee N"
    },
    {
        "code": "FO.3",
        "traduction": "Subventions d exploitation Total annee N"
    },
    {
        "code": "FO.4",
        "traduction": "Subventions d exploitation Total annee N-1"
    },
    {
        "code": "FP.1",
        "traduction": "Reprises sur amortissements et provisions, transfert de charges Montant France annee N"
    },
    {
        "code": "FP.2",
        "traduction": "Reprises sur amortissements et provisions, transfert de charges Montant Export annee N"
    },
    {
        "code": "FP.3",
        "traduction": "Reprises sur amortissements et provisions, transfert de charges Total annee N"
    },
    {
        "code": "FP.4",
        "traduction": "Reprises sur amortissements et provisions, transfert de charges Total annee N-1"
    },
    {
        "code": "FQ.1",
        "traduction": "Autres produits Montant France annee N"
    },
    {
        "code": "FQ.2",
        "traduction": "Autres produits Montant Export annee N"
    },
    {
        "code": "FQ.3",
        "traduction": "Autres produits Total annee N"
    },
    {
        "code": "FQ.4",
        "traduction": "Autres produits Total annee N-1"
    },
    {
        "code": "FR.1",
        "traduction": "Total des produits d exploitation (I) Montant France annee N"
    },
    {
        "code": "FR.2",
        "traduction": "Total des produits d exploitation (I) Montant Export annee N"
    },
    {
        "code": "FR.3",
        "traduction": "Total des produits d exploitation (I) Total annee N"
    },
    {
        "code": "FR.4",
        "traduction": "Total des produits d exploitation (I) Total annee N-1"
    },
    {
        "code": "FS.1",
        "traduction": "Achats de marchandises (y compris droits de douane) Montant France annee N"
    },
    {
        "code": "FS.2",
        "traduction": "Achats de marchandises (y compris droits de douane) Montant Export annee N"
    },
    {
        "code": "FS.3",
        "traduction": "Achats de marchandises (y compris droits de douane) Total annee N"
    },
    {
        "code": "FS.4",
        "traduction": "Achats de marchandises (y compris droits de douane) Total annee N-1"
    },
    {
        "code": "FT.1",
        "traduction": "Variation de stock (marchandises) Montant France annee N"
    },
    {
        "code": "FT.2",
        "traduction": "Variation de stock (marchandises) Montant Export annee N"
    },
    {
        "code": "FT.3",
        "traduction": "Variation de stock (marchandises) Total annee N"
    },
    {
        "code": "FT.4",
        "traduction": "Variation de stock (marchandises) Total annee N-1"
    },
    {
        "code": "FU.1",
        "traduction": "Achats de matieres premieres et autres approvisionnements Montant France annee N"
    },
    {
        "code": "FU.2",
        "traduction": "Achats de matieres premieres et autres approvisionnements Montant Export annee N"
    },
    {
        "code": "FU.3",
        "traduction": "Achats de matieres premieres et autres approvisionnements Total annee N"
    },
    {
        "code": "FU.4",
        "traduction": "Achats de matieres premieres et autres approvisionnements Total annee N-1"
    },
    {
        "code": "FV.1",
        "traduction": "Variation de stock (matieres premieres et approvisionnements) Montant France annee N"
    },
    {
        "code": "FV.2",
        "traduction": "Variation de stock (matieres premieres et approvisionnements) Montant Export annee N"
    },
    {
        "code": "FV.3",
        "traduction": "Variation de stock (matieres premieres et approvisionnements) Total annee N"
    },
    {
        "code": "FV.4",
        "traduction": "Variation de stock (matieres premieres et approvisionnements) Total annee N-1"
    },
    {
        "code": "FW.1",
        "traduction": "Autres achats et charges externes Montant France annee N"
    },
    {
        "code": "FW.2",
        "traduction": "Autres achats et charges externes Montant Export annee N"
    },
    {
        "code": "FW.3",
        "traduction": "Autres achats et charges externes Total annee N"
    },
    {
        "code": "FW.4",
        "traduction": "Autres achats et charges externes Total annee N-1"
    },
    {
        "code": "FX.1",
        "traduction": "Impots, taxes et versements assimiles Montant France annee N"
    },
    {
        "code": "FX.2",
        "traduction": "Impots, taxes et versements assimiles Montant Export annee N"
    },
    {
        "code": "FX.3",
        "traduction": "Impots, taxes et versements assimiles Total annee N"
    },
    {
        "code": "FX.4",
        "traduction": "Impots, taxes et versements assimiles Total annee N-1"
    },
    {
        "code": "FY.1",
        "traduction": "Salaires et traitements Montant France annee N"
    },
    {
        "code": "FY.2",
        "traduction": "Salaires et traitements Montant Export annee N"
    },
    {
        "code": "FY.3",
        "traduction": "Salaires et traitements Total annee N"
    },
    {
        "code": "FY.4",
        "traduction": "Salaires et traitements Total annee N-1"
    },
    {
        "code": "FZ.1",
        "traduction": "Charges sociales Montant France annee N"
    },
    {
        "code": "FZ.2",
        "traduction": "Charges sociales Montant Export annee N"
    },
    {
        "code": "FZ.3",
        "traduction": "Charges sociales Total annee N"
    },
    {
        "code": "FZ.4",
        "traduction": "Charges sociales Total annee N-1"
    },
    {
        "code": "GA.1",
        "traduction": "Dot. d exploit. - Dotations aux amortissements Montant France annee N"
    },
    {
        "code": "GA.2",
        "traduction": "Dot. d exploit. - Dotations aux amortissements Montant Export annee N"
    },
    {
        "code": "GA.3",
        "traduction": "Dot. d exploit. - Dotations aux amortissements Total annee N"
    },
    {
        "code": "GA.4",
        "traduction": "Dot. d exploit. - Dotations aux amortissements Total annee N-1"
    },
    {
        "code": "GB.1",
        "traduction": "Dot. d exploit. - Dotations aux provisions Montant France annee N"
    },
    {
        "code": "GB.2",
        "traduction": "Dot. d exploit. - Dotations aux provisions Montant Export annee N"
    },
    {
        "code": "GB.3",
        "traduction": "Dot. d exploit. - Dotations aux provisions Total annee N"
    },
    {
        "code": "GB.4",
        "traduction": "Dot. d exploit. - Dotations aux provisions Total annee N-1"
    },
    {
        "code": "GC.1",
        "traduction": "Dot. d exploit. Sur actif circulant : dotations aux provisions Montant France annee N"
    },
    {
        "code": "GC.2",
        "traduction": "Dot. d exploit. Sur actif circulant : dotations aux provisions Montant Export annee N"
    },
    {
        "code": "GC.3",
        "traduction": "Dot. d exploit. Sur actif circulant : dotations aux provisions Total annee N"
    },
    {
        "code": "GC.4",
        "traduction": "Dot. d exploit. Sur actif circulant : dotations aux provisions Total annee N-1"
    },
    {
        "code": "GD.1",
        "traduction": "Dot. d exploit. Pour risques et charges : dotations aux provisions Montant France annee N"
    },
    {
        "code": "GD.2",
        "traduction": "Dot. d exploit. Pour risques et charges : dotations aux provisions Montant Export annee N"
    },
    {
        "code": "GD.3",
        "traduction": "Dot. d exploit. Pour risques et charges : dotations aux provisions Total annee N"
    },
    {
        "code": "GD.4",
        "traduction": "Dot. d exploit. Pour risques et charges : dotations aux provisions Total annee N-1"
    },
    {
        "code": "GE.1",
        "traduction": "Autres charges Montant France annee N"
    },
    {
        "code": "GE.2",
        "traduction": "Autres charges Montant Export annee N"
    },
    {
        "code": "GE.3",
        "traduction": "Autres charges Total annee N"
    },
    {
        "code": "GE.4",
        "traduction": "Autres charges Total annee N-1"
    },
    {
        "code": "GF.1",
        "traduction": "Total des charges d exploitation (II) Montant France annee N"
    },
    {
        "code": "GF.2",
        "traduction": "Total des charges d exploitation (II) Montant Export annee N"
    },
    {
        "code": "GF.3",
        "traduction": "Total des charges d exploitation (II) Total annee N"
    },
    {
        "code": "GF.4",
        "traduction": "Total des charges d exploitation (II) Total annee N-1"
    },
    {
        "code": "GG.1",
        "traduction": "RESULTAT D EXPLOITATION (I - II) Montant France annee N"
    },
    {
        "code": "GG.2",
        "traduction": "RESULTAT D EXPLOITATION (I - II) Montant Export annee N"
    },
    {
        "code": "GG.3",
        "traduction": "RESULTAT D EXPLOITATION (I - II) Total annee N"
    },
    {
        "code": "GG.4",
        "traduction": "RESULTAT D EXPLOITATION (I - II) Total annee N-1"
    },
    {
        "code": "GH.1",
        "traduction": "Benefice attribue ou perte transferee (III) Montant France annee N"
    },
    {
        "code": "GH.2",
        "traduction": "Benefice attribue ou perte transferee (III) Montant Export annee N"
    },
    {
        "code": "GH.3",
        "traduction": "Benefice attribue ou perte transferee (III) Total annee N"
    },
    {
        "code": "GH.4",
        "traduction": "Benefice attribue ou perte transferee (III) Total annee N-1"
    },
    {
        "code": "GI.1",
        "traduction": "Perte supportee ou benefice transfere (IV) Montant France annee N"
    },
    {
        "code": "GI.2",
        "traduction": "Perte supportee ou benefice transfere (IV) Montant Export annee N"
    },
    {
        "code": "GI.3",
        "traduction": "Perte supportee ou benefice transfere (IV) Total annee N"
    },
    {
        "code": "GI.4",
        "traduction": "Perte supportee ou benefice transfere (IV) Total annee N-1"
    },
    {
        "code": "GJ.1",
        "traduction": "Produits financiers de participations Montant France annee N"
    },
    {
        "code": "GJ.2",
        "traduction": "Produits financiers de participations Montant Export annee N"
    },
    {
        "code": "GJ.3",
        "traduction": "Produits financiers de participations Total annee N"
    },
    {
        "code": "GJ.4",
        "traduction": "Produits financiers de participations Total annee N-1"
    },
    {
        "code": "GK.1",
        "traduction": "Produits des autres valeurs mobilieres et creances de l actif immobilise Montant France annee N"
    },
    {
        "code": "GK.2",
        "traduction": "Produits des autres valeurs mobilieres et creances de l actif immobilise Montant Export annee N"
    },
    {
        "code": "GK.3",
        "traduction": "Produits des autres valeurs mobilieres et creances de l actif immobilise Total annee N"
    },
    {
        "code": "GK.4",
        "traduction": "Produits des autres valeurs mobilieres et creances de l actif immobilise Total annee N-1"
    },
    {
        "code": "GL.1",
        "traduction": "Autres interets et produits assimiles Montant France annee N"
    },
    {
        "code": "GL.2",
        "traduction": "Autres interets et produits assimiles Montant Export annee N"
    },
    {
        "code": "GL.3",
        "traduction": "Autres interets et produits assimiles Total annee N"
    },
    {
        "code": "GL.4",
        "traduction": "Autres interets et produits assimiles Total annee N-1"
    },
    {
        "code": "GM.1",
        "traduction": "Reprises sur provisions et transferts de charges Montant France annee N"
    },
    {
        "code": "GM.2",
        "traduction": "Reprises sur provisions et transferts de charges Montant Export annee N"
    },
    {
        "code": "GM.3",
        "traduction": "Reprises sur provisions et transferts de charges Total annee N"
    },
    {
        "code": "GM.4",
        "traduction": "Reprises sur provisions et transferts de charges Total annee N-1"
    },
    {
        "code": "GN.1",
        "traduction": "Differences positives de change Montant France annee N"
    },
    {
        "code": "GN.2",
        "traduction": "Differences positives de change Montant Export annee N"
    },
    {
        "code": "GN.3",
        "traduction": "Differences positives de change Total annee N"
    },
    {
        "code": "GN.4",
        "traduction": "Differences positives de change Total annee N-1"
    },
    {
        "code": "GO.1",
        "traduction": "Produits nets sur cessions de valeurs mobilieres de placement Montant France annee N"
    },
    {
        "code": "GO.2",
        "traduction": "Produits nets sur cessions de valeurs mobilieres de placement Montant Export annee N"
    },
    {
        "code": "GO.3",
        "traduction": "Produits nets sur cessions de valeurs mobilieres de placement Total annee N"
    },
    {
        "code": "GO.4",
        "traduction": "Produits nets sur cessions de valeurs mobilieres de placement Total annee N-1"
    },
    {
        "code": "GP.1",
        "traduction": "Total des produits financiers (V) Montant France annee N"
    },
    {
        "code": "GP.2",
        "traduction": "Total des produits financiers (V) Montant Export annee N"
    },
    {
        "code": "GP.3",
        "traduction": "Total des produits financiers (V) Total annee N"
    },
    {
        "code": "GP.4",
        "traduction": "Total des produits financiers (V) Total annee N-1"
    },
    {
        "code": "GQ.1",
        "traduction": "Dotations financieres sur amortissements et provisions Montant France annee N"
    },
    {
        "code": "GQ.2",
        "traduction": "Dotations financieres sur amortissements et provisions Montant Export annee N"
    },
    {
        "code": "GQ.3",
        "traduction": "Dotations financieres sur amortissements et provisions Total annee N"
    },
    {
        "code": "GQ.4",
        "traduction": "Dotations financieres sur amortissements et provisions Total annee N-1"
    },
    {
        "code": "GR.1",
        "traduction": "Interets et charges assimilees Montant France annee N"
    },
    {
        "code": "GR.2",
        "traduction": "Interets et charges assimilees Montant Export annee N"
    },
    {
        "code": "GR.3",
        "traduction": "Interets et charges assimilees Total annee N"
    },
    {
        "code": "GR.4",
        "traduction": "Interets et charges assimilees Total annee N-1"
    },
    {
        "code": "GS.1",
        "traduction": "Differences negatives de change Montant France annee N"
    },
    {
        "code": "GS.2",
        "traduction": "Differences negatives de change Montant Export annee N"
    },
    {
        "code": "GS.3",
        "traduction": "Differences negatives de change Total annee N"
    },
    {
        "code": "GS.4",
        "traduction": "Differences negatives de change Total annee N-1"
    },
    {
        "code": "GT.1",
        "traduction": "Charges nettes sur cessions de valeurs mobilieres de placement Montant France annee N"
    },
    {
        "code": "GT.2",
        "traduction": "Charges nettes sur cessions de valeurs mobilieres de placement Montant Export annee N"
    },
    {
        "code": "GT.3",
        "traduction": "Charges nettes sur cessions de valeurs mobilieres de placement Total annee N"
    },
    {
        "code": "GT.4",
        "traduction": "Charges nettes sur cessions de valeurs mobilieres de placement Total annee N-1"
    },
    {
        "code": "GU.1",
        "traduction": "Total des charges financieres (VI) Montant France annee N"
    },
    {
        "code": "GU.2",
        "traduction": "Total des charges financieres (VI) Montant Export annee N"
    },
    {
        "code": "GU.3",
        "traduction": "Total des charges financieres (VI) Total annee N"
    },
    {
        "code": "GU.4",
        "traduction": "Total des charges financieres (VI) Total annee N-1"
    },
    {
        "code": "GV.1",
        "traduction": "RESULTAT FINANCIER (V - VI) Montant France annee N"
    },
    {
        "code": "GV.2",
        "traduction": "RESULTAT FINANCIER (V - VI) Montant Export annee N"
    },
    {
        "code": "GV.3",
        "traduction": "RESULTAT FINANCIER (V - VI) Total annee N"
    },
    {
        "code": "GV.4",
        "traduction": "RESULTAT FINANCIER (V - VI) Total annee N-1"
    },
    {
        "code": "GW.1",
        "traduction": "RESULTAT COURANT AVANT IMPOTS (I - II + III - IV + V - VI) Montant France annee N"
    },
    {
        "code": "GW.2",
        "traduction": "RESULTAT COURANT AVANT IMPOTS (I - II + III - IV + V - VI) Montant Export annee N"
    },
    {
        "code": "GW.3",
        "traduction": "RESULTAT COURANT AVANT IMPOTS (I - II + III - IV + V - VI) Total annee N"
    },
    {
        "code": "GW.4",
        "traduction": "RESULTAT COURANT AVANT IMPOTS (I - II + III - IV + V - VI) Total annee N-1"
    },
    {
        "code": "CDR2.1",
        "traduction": "Montant annee N"
    },
    {
        "code": "CDR2.2",
        "traduction": "Montant annee N-1"
    },
    {
        "code": "CDR2.3",
        "traduction": ""
    },
    {
        "code": "CDR2.4",
        "traduction": ""
    },
    {
        "code": "HA.1",
        "traduction": "Produits exceptionnels sur operations de gestion Montant annee N"
    },
    {
        "code": "HA.2",
        "traduction": "Produits exceptionnels sur operations de gestion Montant annee N-1"
    },
    {
        "code": "HA.3",
        "traduction": ""
    },
    {
        "code": "HA.4",
        "traduction": ""
    },
    {
        "code": "HB.1",
        "traduction": "Produits exceptionnels sur operations en capital Montant annee N"
    },
    {
        "code": "HB.2",
        "traduction": "Produits exceptionnels sur operations en capital Montant annee N-1"
    },
    {
        "code": "HB.3",
        "traduction": ""
    },
    {
        "code": "HB.4",
        "traduction": ""
    },
    {
        "code": "HC.1",
        "traduction": "Reprises sur provisions et transferts de charges Montant annee N"
    },
    {
        "code": "HC.2",
        "traduction": "Reprises sur provisions et transferts de charges Montant annee N-1"
    },
    {
        "code": "HC.3",
        "traduction": ""
    },
    {
        "code": "HC.4",
        "traduction": ""
    },
    {
        "code": "HD.1",
        "traduction": "Total des produits exceptionnels (VII) Montant annee N"
    },
    {
        "code": "HD.2",
        "traduction": "Total des produits exceptionnels (VII) Montant annee N-1"
    },
    {
        "code": "HD.3",
        "traduction": ""
    },
    {
        "code": "HD.4",
        "traduction": ""
    },
    {
        "code": "HE.1",
        "traduction": "Charges exceptionnelles sur operations de gestion Montant annee N"
    },
    {
        "code": "HE.2",
        "traduction": "Charges exceptionnelles sur operations de gestion Montant annee N-1"
    },
    {
        "code": "HE.3",
        "traduction": ""
    },
    {
        "code": "HE.4",
        "traduction": ""
    },
    {
        "code": "HF.1",
        "traduction": "Charges exceptionnelles sur operations en capital Montant annee N"
    },
    {
        "code": "HF.2",
        "traduction": "Charges exceptionnelles sur operations en capital Montant annee N-1"
    },
    {
        "code": "HF.3",
        "traduction": ""
    },
    {
        "code": "HF.4",
        "traduction": ""
    },
    {
        "code": "HG.1",
        "traduction": "Dotations exceptionnelles aux amortissements et provisions Montant annee N"
    },
    {
        "code": "HG.2",
        "traduction": "Dotations exceptionnelles aux amortissements et provisions Montant annee N-1"
    },
    {
        "code": "HG.3",
        "traduction": ""
    },
    {
        "code": "HG.4",
        "traduction": ""
    },
    {
        "code": "HH.1",
        "traduction": "Total des charges exceptionnelles (VIII) Montant annee N"
    },
    {
        "code": "HH.2",
        "traduction": "Total des charges exceptionnelles (VIII) Montant annee N-1"
    },
    {
        "code": "HH.3",
        "traduction": ""
    },
    {
        "code": "HH.4",
        "traduction": ""
    },
    {
        "code": "HI.1",
        "traduction": "RESULTAT EXCEPTIONNEL (VII - VIII) Montant annee N"
    },
    {
        "code": "HI.2",
        "traduction": "RESULTAT EXCEPTIONNEL (VII - VIII) Montant annee N-1"
    },
    {
        "code": "HI.3",
        "traduction": ""
    },
    {
        "code": "HI.4",
        "traduction": ""
    },
    {
        "code": "HJ.1",
        "traduction": "Participation des salaries aux resultats de l entreprise Montant annee N"
    },
    {
        "code": "HJ.2",
        "traduction": "Participation des salaries aux resultats de l entreprise Montant annee N-1"
    },
    {
        "code": "HJ.3",
        "traduction": ""
    },
    {
        "code": "HJ.4",
        "traduction": ""
    },
    {
        "code": "HK.1",
        "traduction": "Impots sur les benefices Montant annee N"
    },
    {
        "code": "HK.2",
        "traduction": "Impots sur les benefices Montant annee N-1"
    },
    {
        "code": "HK.3",
        "traduction": ""
    },
    {
        "code": "HK.4",
        "traduction": ""
    },
    {
        "code": "HL.1",
        "traduction": "TOTAL DES PRODUITS (I + III + V +VII) Montant annee N"
    },
    {
        "code": "HL.2",
        "traduction": "TOTAL DES PRODUITS (I + III + V +VII) Montant annee N-1"
    },
    {
        "code": "HL.3",
        "traduction": ""
    },
    {
        "code": "HL.4",
        "traduction": ""
    },
    {
        "code": "HM.1",
        "traduction": "TOTAL DES CHARGES (II + IV + VI + VIII + IX + X) Montant annee N"
    },
    {
        "code": "HM.2",
        "traduction": "TOTAL DES CHARGES (II + IV + VI + VIII + IX + X) Montant annee N-1"
    },
    {
        "code": "HM.3",
        "traduction": ""
    },
    {
        "code": "HM.4",
        "traduction": ""
    },
    {
        "code": "HN.1",
        "traduction": "BENEFICE OU PERTE (Total des produits - Total des charges) Montant annee N"
    },
    {
        "code": "HN.2",
        "traduction": "BENEFICE OU PERTE (Total des produits - Total des charges) Montant annee N-1"
    },
    {
        "code": "HN.3",
        "traduction": ""
    },
    {
        "code": "HN.4",
        "traduction": ""
    },
    {
        "code": "HP.1",
        "traduction": "Renvois : Credit-bail mobilier Montant annee N"
    },
    {
        "code": "HP.2",
        "traduction": "Renvois : Credit-bail mobilier Montant annee N-1"
    },
    {
        "code": "HP.3",
        "traduction": ""
    },
    {
        "code": "HP.4",
        "traduction": ""
    },
    {
        "code": "HQ.1",
        "traduction": "Renvois : Credit-bail immobilier Montant annee N"
    },
    {
        "code": "HQ.2",
        "traduction": "Renvois : Credit-bail immobilier Montant annee N-1"
    },
    {
        "code": "HQ.3",
        "traduction": ""
    },
    {
        "code": "HQ.4",
        "traduction": ""
    },
    {
        "code": "A1.1",
        "traduction": "Renvois : Transfert de charges Montant annee N"
    },
    {
        "code": "A1.2",
        "traduction": "Renvois : Transfert de charges Montant annee N-1"
    },
    {
        "code": "A1.3",
        "traduction": ""
    },
    {
        "code": "A1.4",
        "traduction": ""
    },
    {
        "code": "A2.1",
        "traduction": "Renvois : Cotisations personnelles de l exploitant Montant annee N"
    },
    {
        "code": "A2.2",
        "traduction": "Renvois : Cotisations personnelles de l exploitant Montant annee N-1"
    },
    {
        "code": "A2.3",
        "traduction": ""
    },
    {
        "code": "A2.4",
        "traduction": ""
    },
    {
        "code": "A3.1",
        "traduction": "Renvois : Redevances pour concessions de brevets, de licences (produits) Montant annee N"
    },
    {
        "code": "A3.2",
        "traduction": "Renvois : Redevances pour concessions de brevets, de licences (produits) Montant annee N-1"
    },
    {
        "code": "A3.3",
        "traduction": ""
    },
    {
        "code": "A3.4",
        "traduction": ""
    },
    {
        "code": "A4.1",
        "traduction": "Renvois : Redevances pour concessions de brevets, de licences (charges) Montant annee N"
    },
    {
        "code": "A4.2",
        "traduction": "Renvois : Redevances pour concessions de brevets, de licences (charges) Montant annee N-1"
    },
    {
        "code": "A4.3",
        "traduction": ""
    },
    {
        "code": "A4.4",
        "traduction": ""
    },
    {
        "code": "IMM.1",
        "traduction": "Valeur brute debut exercice"
    },
    {
        "code": "IMM.2",
        "traduction": "Reevaluation"
    },
    {
        "code": "IMM.3",
        "traduction": "Acquisition"
    },
    {
        "code": "IMM.4",
        "traduction": ""
    },
    {
        "code": "CZ.1",
        "traduction": "ACQUISITIONS Frais d etablissement, et de developpement ou de recherche Valeur brute debut exercice"
    },
    {
        "code": "CZ.2",
        "traduction": "ACQUISITIONS Frais d etablissement, et de developpement ou de recherche Reevaluation"
    },
    {
        "code": "CZ.3",
        "traduction": "ACQUISITIONS Frais d etablissement, et de developpement ou de recherche Acquisition"
    },
    {
        "code": "CZ.4",
        "traduction": ""
    },
    {
        "code": "KD.1",
        "traduction": "ACQUISITIONS Total dont autres postes d immobilisations incorporelles Valeur brute debut exercice"
    },
    {
        "code": "KD.2",
        "traduction": "ACQUISITIONS Total dont autres postes d immobilisations incorporelles Reevaluation"
    },
    {
        "code": "KD.3",
        "traduction": "ACQUISITIONS Total dont autres postes d immobilisations incorporelles Acquisition"
    },
    {
        "code": "KD.4",
        "traduction": ""
    },
    {
        "code": "LN.1",
        "traduction": "ACQUISITIONS Total Immobilisations corporelles Valeur brute debut exercice"
    },
    {
        "code": "LN.2",
        "traduction": "ACQUISITIONS Total Immobilisations corporelles Reevaluation"
    },
    {
        "code": "LN.3",
        "traduction": "ACQUISITIONS Total Immobilisations corporelles Acquisition"
    },
    {
        "code": "LN.4",
        "traduction": ""
    },
    {
        "code": "LQ.1",
        "traduction": "ACQUISITIONS Total Immobilisations financieres Valeur brute debut exercice"
    },
    {
        "code": "LQ.2",
        "traduction": "ACQUISITIONS Total Immobilisations financieres Reevaluation"
    },
    {
        "code": "LQ.3",
        "traduction": "ACQUISITIONS Total Immobilisations financieres Acquisition"
    },
    {
        "code": "LQ.4",
        "traduction": ""
    },
    {
        "code": "0G.1",
        "traduction": "ACQUISITIONS Total General Valeur brute debut exercice"
    },
    {
        "code": "0G.2",
        "traduction": "ACQUISITIONS Total General Reevaluation"
    },
    {
        "code": "0G.3",
        "traduction": "ACQUISITIONS Total General Acquisition"
    },
    {
        "code": "0G.4",
        "traduction": ""
    },
    {
        "code": "IMM.1",
        "traduction": "Poste a  poste"
    },
    {
        "code": "IMM.2",
        "traduction": "Cessions"
    },
    {
        "code": "IMM.3",
        "traduction": "Valeur brute fin d exercice"
    },
    {
        "code": "IMM.4",
        "traduction": ""
    },
    {
        "code": "IN.1",
        "traduction": "DIMINUTIONS Frais d etablissement, et de developpement ou de recherche Poste a  poste"
    },
    {
        "code": "IN.2",
        "traduction": "DIMINUTIONS Frais d etablissement, et de developpement ou de recherche Reevaluation"
    },
    {
        "code": "IN.3",
        "traduction": "DIMINUTIONS Frais d etablissement, et de developpement ou de recherche Acquisition"
    },
    {
        "code": "IN.4",
        "traduction": ""
    },
    {
        "code": "IO.1",
        "traduction": "DIMINUTIONS Total dont autres postes d immobilisations incorporelles Valeur brute debut exercice"
    },
    {
        "code": "IO.2",
        "traduction": "DIMINUTIONS Total dont autres postes d immobilisations incorporelles Reevaluation"
    },
    {
        "code": "IO.3",
        "traduction": "DIMINUTIONS Total dont autres postes d immobilisations incorporelles Acquisition"
    },
    {
        "code": "IO.4",
        "traduction": ""
    },
    {
        "code": "MY.1",
        "traduction": "DIMINUTIONS Virement postes immobilisations corporelles en cours Valeur brute debut exercice"
    },
    {
        "code": "MY.2",
        "traduction": "DIMINUTIONS Virement postes immobilisations corporelles en cours Reevaluation"
    },
    {
        "code": "MY.3",
        "traduction": "DIMINUTIONS Virement postes immobilisations corporelles en cours Acquisition"
    },
    {
        "code": "MY.4",
        "traduction": ""
    },
    {
        "code": "NC.1",
        "traduction": "DIMINUTIONS Virement postes - Avances et acomptes Valeur brute debut exercice"
    },
    {
        "code": "NC.2",
        "traduction": "DIMINUTIONS Virement postes - Avances et acomptes Reevaluation"
    },
    {
        "code": "NC.3",
        "traduction": "DIMINUTIONS Virement postes - Avances et acomptes Acquisition"
    },
    {
        "code": "NC.4",
        "traduction": ""
    },
    {
        "code": "IY.1",
        "traduction": "DIMINUTIONS Total Immobilisations corporelles Valeur brute debut exercice"
    },
    {
        "code": "IY.2",
        "traduction": "DIMINUTIONS Total Immobilisations corporelles Reevaluation"
    },
    {
        "code": "IY.3",
        "traduction": "DIMINUTIONS Total Immobilisations corporelles Acquisition"
    },
    {
        "code": "IY.4",
        "traduction": ""
    },
    {
        "code": "I2.1",
        "traduction": "DIMINUTIONS Prets et immobilisations financieres Valeur brute debut exercice"
    },
    {
        "code": "I2.2",
        "traduction": "DIMINUTIONS Prets et immobilisations financieres Reevaluation"
    },
    {
        "code": "I2.3",
        "traduction": "DIMINUTIONS Prets et immobilisations financieres Acquisition"
    },
    {
        "code": "I2.4",
        "traduction": ""
    },
    {
        "code": "I3.1",
        "traduction": "DIMINUTIONS Total Immobilisations financieres Valeur brute debut exercice"
    },
    {
        "code": "I3.2",
        "traduction": "DIMINUTIONS Total Immobilisations financieres Reevaluation"
    },
    {
        "code": "I3.3",
        "traduction": "DIMINUTIONS Total Immobilisations financieres Acquisition"
    },
    {
        "code": "I3.4",
        "traduction": ""
    },
    {
        "code": "I4.1",
        "traduction": "DIMINUTIONS Total General Valeur brute debut exercice"
    },
    {
        "code": "I4.2",
        "traduction": "DIMINUTIONS Total General Reevaluation"
    },
    {
        "code": "I4.3",
        "traduction": "DIMINUTIONS Total General Acquisition"
    },
    {
        "code": "I4.4",
        "traduction": ""
    },
    {
        "code": "AMO.1",
        "traduction": "Montant debut d exercice"
    },
    {
        "code": "AMO.2",
        "traduction": "Augmentation : dotation exercice"
    },
    {
        "code": "AMO.3",
        "traduction": "Diminution reprise"
    },
    {
        "code": "AMO.4",
        "traduction": "Montant fin d exercice"
    },
    {
        "code": "CY.1",
        "traduction": "AMORTISSEMENTS Frais d etablissement, et de developpement ou de recherche Montant debut d exercice"
    },
    {
        "code": "CY.2",
        "traduction": "AMORTISSEMENTS Frais d etablissement, et de developpement ou de recherche Augmentation : dotation exercice"
    },
    {
        "code": "CY.3",
        "traduction": "AMORTISSEMENTS Frais d etablissement, et de developpement ou de recherche Diminution reprise"
    },
    {
        "code": "CY.4",
        "traduction": "AMORTISSEMENTS Frais d etablissement, et de developpement ou de recherche Montant fin d exercice"
    },
    {
        "code": "PE.1",
        "traduction": "AMORTISSEMENTS Total dont autres immobilisations incorporelles Montant debut d exercice"
    },
    {
        "code": "PE.2",
        "traduction": "AMORTISSEMENTS Total dont autres immobilisations incorporelles Augmentation : dotation exercice"
    },
    {
        "code": "PE.3",
        "traduction": "AMORTISSEMENTS Total dont autres immobilisations incorporelles Diminution reprise"
    },
    {
        "code": "PE.4",
        "traduction": "AMORTISSEMENTS Total dont autres immobilisations incorporelles Montant fin d exercice"
    },
    {
        "code": "QU.1",
        "traduction": "AMORTISSEMENTS Total Immobilisations corporelles Montant debut d exercice"
    },
    {
        "code": "QU.2",
        "traduction": "AMORTISSEMENTS Total Immobilisations corporelles Augmentation : dotation exercice"
    },
    {
        "code": "QU.3",
        "traduction": "AMORTISSEMENTS Total Immobilisations corporelles Diminution reprise"
    },
    {
        "code": "QU.4",
        "traduction": "AMORTISSEMENTS Total Immobilisations corporelles Montant fin d exercice"
    },
    {
        "code": "0N.1",
        "traduction": "AMORTISSEMENTS Total General Montant debut d exercice"
    },
    {
        "code": "0N.2",
        "traduction": "AMORTISSEMENTS Total General Augmentation : dotation exercice"
    },
    {
        "code": "0N.3",
        "traduction": "AMORTISSEMENTS Total General Diminution reprise"
    },
    {
        "code": "0N.4",
        "traduction": "AMORTISSEMENTS Total General Montant fin d exercice"
    },
    {
        "code": "Z9.1",
        "traduction": "Charges a  repartir ou frais d emission d emprunt Montant debut d exercice"
    },
    {
        "code": "Z9.2",
        "traduction": "Charges a  repartir ou frais d emission d emprunt Augmentation : dotation exercice"
    },
    {
        "code": "Z9.3",
        "traduction": "Charges a  repartir ou frais d emission d emprunt Diminution reprise"
    },
    {
        "code": "Z9.4",
        "traduction": "Charges a  repartir ou frais d emission d emprunt Montant fin d exercice"
    },
    {
        "code": "SP.1",
        "traduction": "mvt. sur charges rep. - Primes de remboursement des obligations Montant debut d exercice"
    },
    {
        "code": "SP.2",
        "traduction": "mvt. sur charges rep. - Primes de remboursement des obligations Augmentation : dotation exercice"
    },
    {
        "code": "SP.3",
        "traduction": "mvt. sur charges rep. - Primes de remboursement des obligations Diminution reprise"
    },
    {
        "code": "SP.4",
        "traduction": "mvt. sur charges rep. - Primes de remboursement des obligations Montant fin d exercice"
    },
    {
        "code": "PRO.1",
        "traduction": "Montant debut d exercice"
    },
    {
        "code": "PRO.2",
        "traduction": "Augmentation : dotation exercice"
    },
    {
        "code": "PRO.3",
        "traduction": "Diminution reprise"
    },
    {
        "code": "PRO.4",
        "traduction": "Montant fin d exercice"
    },
    {
        "code": "3X.1",
        "traduction": "Amortissements derogatoires Montant debut d exercice"
    },
    {
        "code": "3X.2",
        "traduction": "Amortissements derogatoires Augmentation : dotation exercice"
    },
    {
        "code": "3X.3",
        "traduction": "Amortissements derogatoires Diminution reprise"
    },
    {
        "code": "3X.4",
        "traduction": "Amortissements derogatoires Montant fin d exercice"
    },
    {
        "code": "3Z.1",
        "traduction": "Total Provisions reglementees Montant debut d exercice"
    },
    {
        "code": "3Z.2",
        "traduction": "Total Provisions reglementees Augmentation : dotation exercice"
    },
    {
        "code": "3Z.3",
        "traduction": "Total Provisions reglementees Diminution reprise"
    },
    {
        "code": "3Z.4",
        "traduction": "Total Provisions reglementees Montant fin d exercice"
    },
    {
        "code": "4A.1",
        "traduction": "Provisions pour litiges Montant debut d exercice"
    },
    {
        "code": "4A.2",
        "traduction": "Provisions pour litiges Augmentation : dotation exercice"
    },
    {
        "code": "4A.3",
        "traduction": "Provisions pour litiges Diminution reprise"
    },
    {
        "code": "4A.4",
        "traduction": "Provisions pour litiges Montant fin d exercice"
    },
    {
        "code": "4E.1",
        "traduction": "Provisions pour garanties donnees aux clients Montant debut d exercice"
    },
    {
        "code": "4E.2",
        "traduction": "Provisions pour garanties donnees aux clients Augmentation : dotation exercice"
    },
    {
        "code": "4E.3",
        "traduction": "Provisions pour garanties donnees aux clients Diminution reprise"
    },
    {
        "code": "4E.4",
        "traduction": "Provisions pour garanties donnees aux clients Montant fin d exercice"
    },
    {
        "code": "4J.1",
        "traduction": "Provisions pour perte sur marches a  terme Montant debut d exercice"
    },
    {
        "code": "4J.2",
        "traduction": "Provisions pour perte sur marches a  terme Augmentation : dotation exercice"
    },
    {
        "code": "4J.3",
        "traduction": "Provisions pour perte sur marches a  terme Diminution reprise"
    },
    {
        "code": "4J.4",
        "traduction": "Provisions pour perte sur marches a  terme Montant fin d exercice"
    },
    {
        "code": "4N.1",
        "traduction": "Provisions pour amendes et penalites Montant debut d exercice"
    },
    {
        "code": "4N.2",
        "traduction": "Provisions pour amendes et penalites Augmentation : dotation exercice"
    },
    {
        "code": "4N.3",
        "traduction": "Provisions pour amendes et penalites Diminution reprise"
    },
    {
        "code": "4N.4",
        "traduction": "Provisions pour amendes et penalites Montant fin d exercice"
    },
    {
        "code": "4T.1",
        "traduction": "Provisions pour perte de change Montant debut d exercice"
    },
    {
        "code": "4T.2",
        "traduction": "Provisions pour perte de change Augmentation : dotation exercice"
    },
    {
        "code": "4T.3",
        "traduction": "Provisions pour perte de change Diminution reprise"
    },
    {
        "code": "4T.4",
        "traduction": "Provisions pour perte de change Montant fin d exercice"
    },
    {
        "code": "4X.1",
        "traduction": "Provisions pour pensions et obligations similaires Montant debut d exercice"
    },
    {
        "code": "4X.2",
        "traduction": "Provisions pour pensions et obligations similaires Augmentation : dotation exercice"
    },
    {
        "code": "4X.3",
        "traduction": "Provisions pour pensions et obligations similaires Diminution reprise"
    },
    {
        "code": "4X.4",
        "traduction": "Provisions pour pensions et obligations similaires Montant fin d exercice"
    },
    {
        "code": "5B.1",
        "traduction": "Provisions pour impots Montant debut d exercice"
    },
    {
        "code": "5B.2",
        "traduction": "Provisions pour impots Augmentation : dotation exercice"
    },
    {
        "code": "5B.3",
        "traduction": "Provisions pour impots Diminution reprise"
    },
    {
        "code": "5B.4",
        "traduction": "Provisions pour impots Montant fin d exercice"
    },
    {
        "code": "5F.1",
        "traduction": "Provisions pour renouvellement des immobilisations Montant debut d exercice"
    },
    {
        "code": "5F.2",
        "traduction": "Provisions pour renouvellement des immobilisations Augmentation : dotation exercice"
    },
    {
        "code": "5F.3",
        "traduction": "Provisions pour renouvellement des immobilisations Diminution reprise"
    },
    {
        "code": "5F.4",
        "traduction": "Provisions pour renouvellement des immobilisations Montant fin d exercice"
    },
    {
        "code": "EO.1",
        "traduction": "Provisions pour gros entretien et grandes revisions ou grosses reparations Montant debut d exercice"
    },
    {
        "code": "EO.2",
        "traduction": "Provisions pour gros entretien et grandes revisions ou grosses reparations Augmentation : dotation exercice"
    },
    {
        "code": "EO.3",
        "traduction": "Provisions pour gros entretien et grandes revisions ou grosses reparations Diminution reprise"
    },
    {
        "code": "EO.4",
        "traduction": "Provisions pour gros entretien et grandes revisions ou grosses reparations Montant fin d exercice"
    },
    {
        "code": "5R.1",
        "traduction": "Provisions pour charges sociales et fiscales sur conges a  payer Montant debut d exercice"
    },
    {
        "code": "5R.2",
        "traduction": "Provisions pour charges sociales et fiscales sur conges a  payer Augmentation : dotation exercice"
    },
    {
        "code": "5R.3",
        "traduction": "Provisions pour charges sociales et fiscales sur conges a  payer Diminution reprise"
    },
    {
        "code": "5R.4",
        "traduction": "Provisions pour charges sociales et fiscales sur conges a  payer Montant fin d exercice"
    },
    {
        "code": "5V.1",
        "traduction": "Autres provisions pour risques et charges Montant debut d exercice"
    },
    {
        "code": "5V.2",
        "traduction": "Autres provisions pour risques et charges Augmentation : dotation exercice"
    },
    {
        "code": "5V.3",
        "traduction": "Autres provisions pour risques et charges Diminution reprise"
    },
    {
        "code": "5V.4",
        "traduction": "Autres provisions pour risques et charges Montant fin d exercice"
    },
    {
        "code": "5Z.1",
        "traduction": "Total Provisions pour risques et charges Montant debut d exercice"
    },
    {
        "code": "5Z.2",
        "traduction": "Total Provisions pour risques et charges Augmentation : dotation exercice"
    },
    {
        "code": "5Z.3",
        "traduction": "Total Provisions pour risques et charges Diminution reprise"
    },
    {
        "code": "5Z.4",
        "traduction": "Total Provisions pour risques et charges Montant fin d exercice"
    },
    {
        "code": "6A.1",
        "traduction": "sur immobilisations  incorporelles Montant debut d exercice"
    },
    {
        "code": "6A.2",
        "traduction": "sur immobilisations  incorporelles Augmentation : dotation exercice"
    },
    {
        "code": "6A.3",
        "traduction": "sur immobilisations  incorporelles Diminution reprise"
    },
    {
        "code": "6A.4",
        "traduction": "sur immobilisations  incorporelles Montant fin d exercice"
    },
    {
        "code": "6E.1",
        "traduction": "sur immobilisations  corporelles Montant debut d exercice"
    },
    {
        "code": "6E.2",
        "traduction": "sur immobilisations  corporelles Augmentation : dotation exercice"
    },
    {
        "code": "6E.3",
        "traduction": "sur immobilisations  corporelles Diminution reprise"
    },
    {
        "code": "6E.4",
        "traduction": "sur immobilisations  corporelles Montant fin d exercice"
    },
    {
        "code": "2.1",
        "traduction": "sur immobilisations  titres mis en equivalence Montant debut d exercice"
    },
    {
        "code": "2.2",
        "traduction": "sur immobilisations  titres mis en equivalence Augmentation : dotation exercice"
    },
    {
        "code": "2.3",
        "traduction": "sur immobilisations  titres mis en equivalence Diminution reprise"
    },
    {
        "code": "2.4",
        "traduction": "sur immobilisations  titres mis en equivalence Montant fin d exercice"
    },
    {
        "code": "9U.1",
        "traduction": "sur immobilisations  titres de participation Montant debut d exercice"
    },
    {
        "code": "9U.2",
        "traduction": "sur immobilisations  titres de participation Augmentation : dotation exercice"
    },
    {
        "code": "9U.3",
        "traduction": "sur immobilisations  titres de participation Diminution reprise"
    },
    {
        "code": "9U.4",
        "traduction": "sur immobilisations  titres de participation Montant fin d exercice"
    },
    {
        "code": "6.1",
        "traduction": "sur immobilisations autres immobilisations financieres Montant debut d exercice"
    },
    {
        "code": "6.2",
        "traduction": "sur immobilisations autres immobilisations financieres Augmentation : dotation exercice"
    },
    {
        "code": "6.3",
        "traduction": "sur immobilisations autres immobilisations financieres Diminution reprise"
    },
    {
        "code": "6.4",
        "traduction": "sur immobilisations autres immobilisations financieres Montant fin d exercice"
    },
    {
        "code": "6N.1",
        "traduction": "Sur stocks et en cours Montant debut d exercice"
    },
    {
        "code": "6N.2",
        "traduction": "Sur stocks et en cours Augmentation : dotation exercice"
    },
    {
        "code": "6N.3",
        "traduction": "Sur stocks et en cours Diminution reprise"
    },
    {
        "code": "6N.4",
        "traduction": "Sur stocks et en cours Montant fin d exercice"
    },
    {
        "code": "6T.1",
        "traduction": "Sur comptes clients Montant debut d exercice"
    },
    {
        "code": "6T.2",
        "traduction": "Sur comptes clients Augmentation : dotation exercice"
    },
    {
        "code": "6T.3",
        "traduction": "Sur comptes clients Diminution reprise"
    },
    {
        "code": "6T.4",
        "traduction": "Sur comptes clients Montant fin d exercice"
    },
    {
        "code": "6X.1",
        "traduction": "Autres provisions pour depreciation Montant debut d exercice"
    },
    {
        "code": "6X.2",
        "traduction": "Autres provisions pour depreciation Augmentation : dotation exercice"
    },
    {
        "code": "6X.3",
        "traduction": "Autres provisions pour depreciation Diminution reprise"
    },
    {
        "code": "6X.4",
        "traduction": "Autres provisions pour depreciation Montant fin d exercice"
    },
    {
        "code": "7B.1",
        "traduction": "Total Provisions pour depreciation Montant debut d exercice"
    },
    {
        "code": "7B.2",
        "traduction": "Total Provisions pour depreciation Augmentation : dotation exercice"
    },
    {
        "code": "7B.3",
        "traduction": "Total Provisions pour depreciation Diminution reprise"
    },
    {
        "code": "7B.4",
        "traduction": "Total Provisions pour depreciation Montant fin d exercice"
    },
    {
        "code": "7C.1",
        "traduction": "TOTAL GENERAL Montant debut d exercice"
    },
    {
        "code": "7C.2",
        "traduction": "TOTAL GENERAL Augmentation : dotation exercice"
    },
    {
        "code": "7C.3",
        "traduction": "TOTAL GENERAL Diminution reprise"
    },
    {
        "code": "7C.4",
        "traduction": "TOTAL GENERAL Montant fin d exercice"
    },
    {
        "code": "UE.1",
        "traduction": "dont dotations et reprises : - d Exploitation Montant debut d exercice"
    },
    {
        "code": "UE.2",
        "traduction": "dont dotations et reprises : - d Exploitation Augmentation : dotation exercice"
    },
    {
        "code": "UE.3",
        "traduction": "dont dotations et reprises : - d Exploitation Diminution reprise"
    },
    {
        "code": "UE.4",
        "traduction": "dont dotations et reprises : - d Exploitation Montant fin d exercice"
    },
    {
        "code": "UG.1",
        "traduction": "dont dotations et reprises : - Financieres Montant debut d exercice"
    },
    {
        "code": "UG.2",
        "traduction": "dont dotations et reprises : - Financieres Augmentation : dotation exercice"
    },
    {
        "code": "UG.3",
        "traduction": "dont dotations et reprises : - Financieres Diminution reprise"
    },
    {
        "code": "UG.4",
        "traduction": "dont dotations et reprises : - Financieres Montant fin d exercice"
    },
    {
        "code": "UJ.1",
        "traduction": "dont dotations et reprises : - Exceptionnelles Montant debut d exercice"
    },
    {
        "code": "UJ.2",
        "traduction": "dont dotations et reprises : - Exceptionnelles Augmentation : dotation exercice"
    },
    {
        "code": "UJ.3",
        "traduction": "dont dotations et reprises : - Exceptionnelles Diminution reprise"
    },
    {
        "code": "UJ.4",
        "traduction": "dont dotations et reprises : - Exceptionnelles Montant fin d exercice"
    },
    {
        "code": "CREDET.1",
        "traduction": "Montant brut"
    },
    {
        "code": "CREDET.2",
        "traduction": "Montant 1 an au plus"
    },
    {
        "code": "CREDET.3",
        "traduction": "Montant de 1 a  5 ans"
    },
    {
        "code": "CREDET.4",
        "traduction": "Montant plus de 5 ans"
    },
    {
        "code": "UL.1",
        "traduction": "Creances rattachees a  des participations Montant brut"
    },
    {
        "code": "UL.2",
        "traduction": "Creances rattachees a  des participations Montant 1 an au plus"
    },
    {
        "code": "UL.3",
        "traduction": "Creances rattachees a  des participations Montant de 1 a  5 ans"
    },
    {
        "code": "UL.4",
        "traduction": "Creances rattachees a  des participations Montant plus de 5 ans"
    },
    {
        "code": "UP.1",
        "traduction": "Prets Montant brut"
    },
    {
        "code": "UP.2",
        "traduction": "Prets Montant 1 an au plus"
    },
    {
        "code": "UP.3",
        "traduction": "Prets Montant de 1 a  5 ans"
    },
    {
        "code": "UP.4",
        "traduction": "Prets Montant plus de 5 ans"
    },
    {
        "code": "UT.1",
        "traduction": "Autres immobilisations financieres Montant brut"
    },
    {
        "code": "UT.2",
        "traduction": "Autres immobilisations financieres Montant 1 an au plus"
    },
    {
        "code": "UT.3",
        "traduction": "Autres immobilisations financieres Montant de 1 a  5 ans"
    },
    {
        "code": "UT.4",
        "traduction": "Autres immobilisations financieres Montant plus de 5 ans"
    },
    {
        "code": "VA.1",
        "traduction": "Clients douteux ou litigieux Montant brut"
    },
    {
        "code": "VA.2",
        "traduction": "Clients douteux ou litigieux Montant 1 an au plus"
    },
    {
        "code": "VA.3",
        "traduction": "Clients douteux ou litigieux Montant de 1 a  5 ans"
    },
    {
        "code": "VA.4",
        "traduction": "Clients douteux ou litigieux Montant plus de 5 ans"
    },
    {
        "code": "UX.1",
        "traduction": "Autres creances clients Montant brut"
    },
    {
        "code": "UX.2",
        "traduction": "Autres creances clients Montant 1 an au plus"
    },
    {
        "code": "UX.3",
        "traduction": "Autres creances clients Montant de 1 a  5 ans"
    },
    {
        "code": "UX.4",
        "traduction": "Autres creances clients Montant plus de 5 ans"
    },
    {
        "code": "UO.1",
        "traduction": "(provision pour depreciation anterieurement constituee) Montant brut"
    },
    {
        "code": "UO.2",
        "traduction": "(provision pour depreciation anterieurement constituee) Montant 1 an au plus"
    },
    {
        "code": "UO.3",
        "traduction": "(provision pour depreciation anterieurement constituee) Montant de 1 a  5 ans"
    },
    {
        "code": "UO.4",
        "traduction": "(provision pour depreciation anterieurement constituee) Montant plus de 5 ans"
    },
    {
        "code": "Z1.1",
        "traduction": "Creances representatives de titres pretes Montant brut"
    },
    {
        "code": "Z1.2",
        "traduction": "Creances representatives de titres pretes Montant 1 an au plus"
    },
    {
        "code": "Z1.3",
        "traduction": "Creances representatives de titres pretes Montant de 1 a  5 ans"
    },
    {
        "code": "Z1.4",
        "traduction": "Creances representatives de titres pretes Montant plus de 5 ans"
    },
    {
        "code": "UY.1",
        "traduction": "Personnel et comptes rattaches Montant brut"
    },
    {
        "code": "UY.2",
        "traduction": "Personnel et comptes rattaches Montant 1 an au plus"
    },
    {
        "code": "UY.3",
        "traduction": "Personnel et comptes rattaches Montant de 1 a  5 ans"
    },
    {
        "code": "UY.4",
        "traduction": "Personnel et comptes rattaches Montant plus de 5 ans"
    },
    {
        "code": "UZ.1",
        "traduction": "Securite Sociale, autres organismes sociaux Montant brut"
    },
    {
        "code": "UZ.2",
        "traduction": "Securite Sociale, autres organismes sociaux Montant 1 an au plus"
    },
    {
        "code": "UZ.3",
        "traduction": "Securite Sociale, autres organismes sociaux Montant de 1 a  5 ans"
    },
    {
        "code": "UZ.4",
        "traduction": "Securite Sociale, autres organismes sociaux Montant plus de 5 ans"
    },
    {
        "code": "VM.1",
        "traduction": "Impots sur les benefices Montant brut"
    },
    {
        "code": "VM.2",
        "traduction": "Impots sur les benefices Montant 1 an au plus"
    },
    {
        "code": "VM.3",
        "traduction": "Impots sur les benefices Montant de 1 a  5 ans"
    },
    {
        "code": "VM.4",
        "traduction": "Impots sur les benefices Montant plus de 5 ans"
    },
    {
        "code": "VB.1",
        "traduction": "T. V. A. (Créances) Montant brut"
    },
    {
        "code": "VB.2",
        "traduction": "T. V. A. (Créances) Montant 1 an au plus"
    },
    {
        "code": "VB.3",
        "traduction": "T. V. A. (Créances) Montant de 1 a  5 ans"
    },
    {
        "code": "VB.4",
        "traduction": "T. V. A. (Créances) Montant plus de 5 ans"
    },
    {
        "code": "VN.1",
        "traduction": "Autres impots, taxes versements assimiles Montant brut"
    },
    {
        "code": "VN.2",
        "traduction": "Autres impots, taxes versements assimiles Montant 1 an au plus"
    },
    {
        "code": "VN.3",
        "traduction": "Autres impots, taxes versements assimiles Montant de 1 a  5 ans"
    },
    {
        "code": "VN.4",
        "traduction": "Autres impots, taxes versements assimiles Montant plus de 5 ans"
    },
    {
        "code": "VP.1",
        "traduction": "Divers Montant brut"
    },
    {
        "code": "VP.2",
        "traduction": "Divers Montant 1 an au plus"
    },
    {
        "code": "VP.3",
        "traduction": "Divers Montant de 1 a  5 ans"
    },
    {
        "code": "VP.4",
        "traduction": "Divers Montant plus de 5 ans"
    },
    {
        "code": "VC.1",
        "traduction": "Groupe et associes Montant brut"
    },
    {
        "code": "VC.2",
        "traduction": "Groupe et associes Montant 1 an au plus"
    },
    {
        "code": "VC.3",
        "traduction": "Groupe et associes Montant de 1 a  5 ans"
    },
    {
        "code": "VC.4",
        "traduction": "Groupe et associes Montant plus de 5 ans"
    },
    {
        "code": "VR.1",
        "traduction": "Debiteurs divers (dont creances relatives a  des operations de pension de titres) Montant brut"
    },
    {
        "code": "VR.2",
        "traduction": "Debiteurs divers (dont creances relatives a  des operations de pension de titres) Montant 1 an au plus"
    },
    {
        "code": "VR.3",
        "traduction": "Debiteurs divers (dont creances relatives a  des operations de pension de titres) Montant de 1 a  5 ans"
    },
    {
        "code": "VR.4",
        "traduction": "Debiteurs divers (dont creances relatives a  des operations de pension de titres) Montant plus de 5 ans"
    },
    {
        "code": "VS.1",
        "traduction": "Charges constatees d avance Montant brut"
    },
    {
        "code": "VS.2",
        "traduction": "Charges constatees d avance Montant 1 an au plus"
    },
    {
        "code": "VS.3",
        "traduction": "Charges constatees d avance Montant de 1 a  5 ans"
    },
    {
        "code": "VS.4",
        "traduction": "Charges constatees d avance Montant plus de 5 ans"
    },
    {
        "code": "VT.1",
        "traduction": "TOTAL  ETAT DES CREANCES Montant brut"
    },
    {
        "code": "VT.2",
        "traduction": "TOTAL  ETAT DES CREANCES Montant 1 an au plus"
    },
    {
        "code": "VT.3",
        "traduction": "TOTAL  ETAT DES CREANCES Montant de 1 a  5 ans"
    },
    {
        "code": "VT.4",
        "traduction": "TOTAL  ETAT DES CREANCES Montant plus de 5 ans"
    },
    {
        "code": "7Y.1",
        "traduction": "Emprunts obligataires convertibles brut a  un an au plus Montant brut"
    },
    {
        "code": "7Y.2",
        "traduction": "Emprunts obligataires convertibles brut a  un an au plus Montant 1 an au plus"
    },
    {
        "code": "7Y.3",
        "traduction": "Emprunts obligataires convertibles brut a  un an au plus Montant de 1 a  5 ans"
    },
    {
        "code": "7Y.4",
        "traduction": "Emprunts obligataires convertibles brut a  un an au plus Montant plus de 5 ans"
    },
    {
        "code": "7Z.1",
        "traduction": "Autres emprunts obligataires brut a  un an au plus Montant brut"
    },
    {
        "code": "7Z.2",
        "traduction": "Autres emprunts obligataires brut a  un an au plus Montant 1 an au plus"
    },
    {
        "code": "7Z.3",
        "traduction": "Autres emprunts obligataires brut a  un an au plus Montant de 1 a  5 ans"
    },
    {
        "code": "7Z.4",
        "traduction": "Autres emprunts obligataires brut a  un an au plus Montant plus de 5 ans"
    },
    {
        "code": "VG.1",
        "traduction": "Emprunts a  1 an maximum a  l origine Montant brut"
    },
    {
        "code": "VG.2",
        "traduction": "Emprunts a  1 an maximum a  l origine Montant 1 an au plus"
    },
    {
        "code": "VG.3",
        "traduction": "Emprunts a  1 an maximum a  l origine Montant de 1 a  5 ans"
    },
    {
        "code": "VG.4",
        "traduction": "Emprunts a  1 an maximum a  l origine Montant plus de 5 ans"
    },
    {
        "code": "VH.1",
        "traduction": "Emprunts a  plus d 1 an a  l origine Montant brut"
    },
    {
        "code": "VH.2",
        "traduction": "Emprunts a  plus d 1 an a  l origine Montant 1 an au plus"
    },
    {
        "code": "VH.3",
        "traduction": "Emprunts a  plus d 1 an a  l origine Montant de 1 a  5 ans"
    },
    {
        "code": "VH.4",
        "traduction": "Emprunts a  plus d 1 an a  l origine Montant plus de 5 ans"
    },
    {
        "code": "8A.1",
        "traduction": "Emprunts et dettes financieres divers Montant brut"
    },
    {
        "code": "8A.2",
        "traduction": "Emprunts et dettes financieres divers Montant 1 an au plus"
    },
    {
        "code": "8A.3",
        "traduction": "Emprunts et dettes financieres divers Montant de 1 a  5 ans"
    },
    {
        "code": "8A.4",
        "traduction": "Emprunts et dettes financieres divers Montant plus de 5 ans"
    },
    {
        "code": "8B.1",
        "traduction": "Fournisseurs et comptes rattaches Montant brut"
    },
    {
        "code": "8B.2",
        "traduction": "Fournisseurs et comptes rattaches Montant 1 an au plus"
    },
    {
        "code": "8B.3",
        "traduction": "Fournisseurs et comptes rattaches Montant de 1 a  5 ans"
    },
    {
        "code": "8B.4",
        "traduction": "Fournisseurs et comptes rattaches Montant plus de 5 ans"
    },
    {
        "code": "8C.1",
        "traduction": "Personnel et comptes rattaches Montant brut"
    },
    {
        "code": "8C.2",
        "traduction": "Personnel et comptes rattaches Montant 1 an au plus"
    },
    {
        "code": "8C.3",
        "traduction": "Personnel et comptes rattaches Montant de 1 a  5 ans"
    },
    {
        "code": "8C.4",
        "traduction": "Personnel et comptes rattaches Montant plus de 5 ans"
    },
    {
        "code": "8D.1",
        "traduction": "Securite sociale et autres organismes sociaux Montant brut"
    },
    {
        "code": "8D.2",
        "traduction": "Securite sociale et autres organismes sociaux Montant 1 an au plus"
    },
    {
        "code": "8D.3",
        "traduction": "Securite sociale et autres organismes sociaux Montant de 1 a  5 ans"
    },
    {
        "code": "8D.4",
        "traduction": "Securite sociale et autres organismes sociaux Montant plus de 5 ans"
    },
    {
        "code": "8E.1",
        "traduction": "Impots sur les benefices Montant brut"
    },
    {
        "code": "8E.2",
        "traduction": "Impots sur les benefices Montant 1 an au plus"
    },
    {
        "code": "8E.3",
        "traduction": "Impots sur les benefices Montant de 1 a  5 ans"
    },
    {
        "code": "8E.4",
        "traduction": "Impots sur les benefices Montant plus de 5 ans"
    },
    {
        "code": "VW.1",
        "traduction": "T.V.A. (Dettes) Montant brut"
    },
    {
        "code": "VW.2",
        "traduction": "T.V.A. (Dettes) Montant 1 an au plus"
    },
    {
        "code": "VW.3",
        "traduction": "T.V.A. (Dettes) Montant de 1 a  5 ans"
    },
    {
        "code": "VW.4",
        "traduction": "T.V.A. (Dettes) Montant plus de 5 ans"
    },
    {
        "code": "VX.1",
        "traduction": "Obligations cautionnees Montant brut"
    },
    {
        "code": "VX.2",
        "traduction": "Obligations cautionnees Montant 1 an au plus"
    },
    {
        "code": "VX.3",
        "traduction": "Obligations cautionnees Montant de 1 a  5 ans"
    },
    {
        "code": "VX.4",
        "traduction": "Obligations cautionnees Montant plus de 5 ans"
    },
    {
        "code": "VQ.1",
        "traduction": "Autres impots, taxes et assimiles Montant brut"
    },
    {
        "code": "VQ.2",
        "traduction": "Autres impots, taxes et assimiles Montant 1 an au plus"
    },
    {
        "code": "VQ.3",
        "traduction": "Autres impots, taxes et assimiles Montant de 1 a  5 ans"
    },
    {
        "code": "VQ.4",
        "traduction": "Autres impots, taxes et assimiles Montant plus de 5 ans"
    },
    {
        "code": "8J.1",
        "traduction": "Dettes sur immobilisations et comptes rattaches Montant brut"
    },
    {
        "code": "8J.2",
        "traduction": "Dettes sur immobilisations et comptes rattaches Montant 1 an au plus"
    },
    {
        "code": "8J.3",
        "traduction": "Dettes sur immobilisations et comptes rattaches Montant de 1 a  5 ans"
    },
    {
        "code": "8J.4",
        "traduction": "Dettes sur immobilisations et comptes rattaches Montant plus de 5 ans"
    },
    {
        "code": "VI.1",
        "traduction": "Groupe et associes Montant brut"
    },
    {
        "code": "VI.2",
        "traduction": "Groupe et associes Montant 1 an au plus"
    },
    {
        "code": "VI.3",
        "traduction": "Groupe et associes Montant de 1 a  5 ans"
    },
    {
        "code": "VI.4",
        "traduction": "Groupe et associes Montant plus de 5 ans"
    },
    {
        "code": "8K.1",
        "traduction": "Autres dettes (dont dettes relatives a  des operations de pension de titre) Montant brut"
    },
    {
        "code": "8K.2",
        "traduction": "Autres dettes (dont dettes relatives a  des operations de pension de titre) Montant 1 an au plus"
    },
    {
        "code": "8K.3",
        "traduction": "Autres dettes (dont dettes relatives a  des operations de pension de titre) Montant de 1 a  5 ans"
    },
    {
        "code": "8K.4",
        "traduction": "Autres dettes (dont dettes relatives a  des operations de pension de titre) Montant plus de 5 ans"
    },
    {
        "code": "Z2.1",
        "traduction": "Dette representative de titres empruntes Montant brut"
    },
    {
        "code": "Z2.2",
        "traduction": "Dette representative de titres empruntes Montant 1 an au plus"
    },
    {
        "code": "Z2.3",
        "traduction": "Dette representative de titres empruntes Montant de 1 a  5 ans"
    },
    {
        "code": "Z2.4",
        "traduction": "Dette representative de titres empruntes Montant plus de 5 ans"
    },
    {
        "code": "8L.1",
        "traduction": "Produits constates d avance Montant brut"
    },
    {
        "code": "8L.2",
        "traduction": "Produits constates d avance Montant 1 an au plus"
    },
    {
        "code": "8L.3",
        "traduction": "Produits constates d avance Montant de 1 a  5 ans"
    },
    {
        "code": "8L.4",
        "traduction": "Produits constates d avance Montant plus de 5 ans"
    },
    {
        "code": "VY.1",
        "traduction": "TOTAL  ETAT DES DETTES Montant brut"
    },
    {
        "code": "VY.2",
        "traduction": "TOTAL  ETAT DES DETTES Montant 1 an au plus"
    },
    {
        "code": "VY.3",
        "traduction": "TOTAL  ETAT DES DETTES Montant de 1 a  5 ans"
    },
    {
        "code": "VY.4",
        "traduction": "TOTAL  ETAT DES DETTES Montant plus de 5 ans"
    },
    {
        "code": "VJ.1",
        "traduction": "Emprunts souscrits en cours d exercice Montant brut"
    },
    {
        "code": "VJ.2",
        "traduction": "Emprunts souscrits en cours d exercice Montant 1 an au plus"
    },
    {
        "code": "VJ.3",
        "traduction": "Emprunts souscrits en cours d exercice Montant de 1 a  5 ans"
    },
    {
        "code": "VJ.4",
        "traduction": "Emprunts souscrits en cours d exercice Montant plus de 5 ans"
    },
    {
        "code": "VK.1",
        "traduction": "Emprunts rembourses en cours d exercice Montant brut"
    },
    {
        "code": "VK.2",
        "traduction": "Emprunts rembourses en cours d exercice Montant 1 an au plus"
    },
    {
        "code": "VK.3",
        "traduction": "Emprunts rembourses en cours d exercice Montant de 1 a  5 ans"
    },
    {
        "code": "VK.4",
        "traduction": "Emprunts rembourses en cours d exercice Montant plus de 5 ans"
    },
    {
        "code": "AFF.1",
        "traduction": "Montant annee N"
    },
    {
        "code": "AFF.2",
        "traduction": "Montant annee N-1"
    },
    {
        "code": "ZE.1",
        "traduction": "Dividendes Montant annee N"
    },
    {
        "code": "ZE.2",
        "traduction": "Dividendes Montant annee N-1"
    },
    {
        "code": "YQ.1",
        "traduction": "Engagement de credit-bail mobilier Montant annee N"
    },
    {
        "code": "YQ.2",
        "traduction": "Engagement de credit-bail mobilier Montant annee N-1"
    },
    {
        "code": "YR.1",
        "traduction": "Engagement de credit-bail immobilier Montant annee N"
    },
    {
        "code": "YR.2",
        "traduction": "Engagement de credit-bail immobilier Montant annee N-1"
    },
    {
        "code": "YS.1",
        "traduction": "Effets portes a  l escompte et non echus Montant annee N"
    },
    {
        "code": "YS.2",
        "traduction": "Effets portes a  l escompte et non echus Montant annee N-1"
    },
    {
        "code": "YT.1",
        "traduction": "Sous-traitance Montant annee N"
    },
    {
        "code": "YT.2",
        "traduction": "Sous-traitance Montant annee N-1"
    },
    {
        "code": "XQ.1",
        "traduction": "Location, charges locatives et de copropriete Montant annee N"
    },
    {
        "code": "XQ.2",
        "traduction": "Location, charges locatives et de copropriete Montant annee N-1"
    },
    {
        "code": "YU.1",
        "traduction": "Personnel exterieur a  l entreprise Montant annee N"
    },
    {
        "code": "YU.2",
        "traduction": "Personnel exterieur a  l entreprise Montant annee N-1"
    },
    {
        "code": "SS.1",
        "traduction": "Remuneration d intermediaires et honoraires (hors retrocessions) Montant annee N"
    },
    {
        "code": "SS.2",
        "traduction": "Remuneration d intermediaires et honoraires (hors retrocessions) Montant annee N-1"
    },
    {
        "code": "YV.1",
        "traduction": "Retrocessions d honoraires, commissions et courtages Montant annee N"
    },
    {
        "code": "YV.2",
        "traduction": "Retrocessions d honoraires, commissions et courtages Montant annee N-1"
    },
    {
        "code": "ST.1",
        "traduction": "Autres comptes Montant annee N"
    },
    {
        "code": "ST.2",
        "traduction": "Autres comptes Montant annee N-1"
    },
    {
        "code": "ZJ.1",
        "traduction": "Total du poste correspondant a  la ligne FW du tableau nÂ° 2052 Montant annee N"
    },
    {
        "code": "ZJ.2",
        "traduction": "Total du poste correspondant a  la ligne FW du tableau nÂ° 2052 Montant annee N-1"
    },
    {
        "code": "YW.1",
        "traduction": "Taxe professionnelle Montant annee N"
    },
    {
        "code": "YW.2",
        "traduction": "Taxe professionnelle Montant annee N-1"
    },
    {
        "code": "9Z.1",
        "traduction": "Autres impots, taxes et versements assimiles Montant annee N"
    },
    {
        "code": "9Z.2",
        "traduction": "Autres impots, taxes et versements assimiles Montant annee N-1"
    },
    {
        "code": "YX.1",
        "traduction": "Total du compte correspondant a  la ligne FX du tableau nÂ° 2052 Montant annee N"
    },
    {
        "code": "YX.2",
        "traduction": "Total du compte correspondant a  la ligne FX du tableau nÂ° 2052 Montant annee N-1"
    },
    {
        "code": "YY.1",
        "traduction": "Montant de la TVA. collectee Montant annee N"
    },
    {
        "code": "YY.2",
        "traduction": "Montant de la TVA. collectee Montant annee N-1"
    },
    {
        "code": "YZ.1",
        "traduction": "Total TVA. deductible sur biens et services Montant annee N"
    },
    {
        "code": "YZ.2",
        "traduction": "Total TVA. deductible sur biens et services Montant annee N-1"
    },
    {
        "code": "YP.1",
        "traduction": "Effectif moyen du personnel Montant annee N"
    },
    {
        "code": "YP.2",
        "traduction": "Effectif moyen du personnel Montant annee N-1"
    }
]