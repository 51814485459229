import React, { useState, useEffect } from 'react'

import { Button, OverlayTrigger, Overlay, Popover, Tooltip } from 'react-bootstrap';
import { AlertInfo, MessageInfo } from './AlertInfo';

function CustomOverlay(props: { show: boolean; placement: any; children: any, title: string | undefined, content: string }) {

  return (
    <OverlayTrigger
      show={props.show}
      key={props.placement}
      placement={props.placement}
      overlay={
        <Tooltip id={`tooltip-${props.placement}`}>
          {props.title && <strong >{props.title} : </strong>} {props.content}
        </Tooltip>
      }
    >
      {props.children}
    </OverlayTrigger>
  );
}

/* 
  Placement value :  ["top","top-start","top-end","bottom","bottom-start","bottom-end","right","right-start","right-end","left","left-start","left-end","auto","auto-start","auto-end"]
  Content exemple : And here's some <strong>amazing</strong> content. It's very engaging. right? 
*/
function CustomPopOver(props: { reference:any, target: any, show: boolean; placement: any; title: string | undefined, content: string }) {

  return (
    <Overlay
      show={props.show}
      key={props.placement} 
      placement={props.placement}
      container={props.reference}
      target={props.target}
      >
        <Popover id={`popover-positioned-${props.placement}`}>
          {props.title && <Popover.Title as="h3">{props.title}</Popover.Title>}
          <Popover.Content>
            {props.content}

          </Popover.Content>
        </Popover>
    </Overlay>
  );
}

function CustomPopOverTrigger(props: { reference:any, target: any, show: boolean; placement: any; children: any, title: string | undefined, content: string }) {

  return (
    <OverlayTrigger
      show={props.show ? undefined : false}
      key={props.placement} 
      placement={props.placement}
      container={props.reference}
      target={props.target}
      overlay={
        <Popover show={false} id={`popover-positioned-${props.placement}`}>
          {props.title && <Popover.Title as="h3">{props.title}</Popover.Title>}
          <Popover.Content>
            {props.content}

          </Popover.Content>
        </Popover>
      }
    >
      {props.children}
    </OverlayTrigger>
  );
}

export { CustomOverlay, CustomPopOver, CustomPopOverTrigger }

// const CustomPopOver = React.forwardRef((props: { reference: any; show: boolean; placement: any; children: any, title: string | undefined, content: string }) => (

//   <Overlay
//     show={props.show}
//     placement={props.placement}
//     target={null}
//     container={props.reference}
//   >
//     <Popover id={`popover-positioned-${props.placement}`}>
//       {props.title && <Popover.Title as="h3">{props.title}</Popover.Title>}
//       <Popover.Content>
//         {props.content}
//         {/* And here's some <strong>amazing</strong> content. It's very engaging.
//           right? */}
//       </Popover.Content>
//     </Popover>
//   </Overlay>

// ));

/* 
const CustomPopOver: React.FC<{}> = () => {

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <div ref={ref}>
      <Button onClick={handleClick}>Holy guacamole!</Button>

      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={ref}
        containerPadding={20}
      >
        <Popover id="popover-contained">
          <Popover.Title as="h3">Popover bottom</Popover.Title>
          <Popover.Content>
            <strong>Holy guacamole!</strong> Check this info.
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
}
 */