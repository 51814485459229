import React from "react";
import GlobalModal from "./components/modals/GlobalModal";
import "./modal.scss";

export const ModalContext = React.createContext({
  props: {},
  showModal: {},
  hideModal: {}
});

export class ModalProvider extends React.Component {
  showModal = (props = {}) => {
    console.log("click modal", props);
    this.setState({
      props
    });
  };
  
  hideModal = () =>
    this.setState({
      props: {}
    });
  

  state = {
    props: {},
    showModal: this.showModal,
    hideModal: this.hideModal
  };

  render() {
    const {modalType, ...modalData}:any = this.state.props;

    return (
      <ModalContext.Provider value={this.state}>
        {this.props.children}
        {modalType ? 
        GlobalModal.getModal(modalType, {hideModal : this.hideModal,...modalData }) : ""}
      </ModalContext.Provider>
    );
  }
}
