import React, { Component } from 'react';
//import { useSelector, useDispatch } from 'react-redux';

import { connect } from 'react-redux';
import { register } from '../actions/userActions'
import { Link } from 'react-router-dom';

import { MessageInfo } from '../components/AlertInfo';

//import { MessageInfo } from '../components/AlertInfo'

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = (props: Readonly<MyState>) => {
  const { formErrors, ...rest } = props;
  let valid = true;
  // validate form errors being empty
  Object.values(formErrors).forEach(val => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  Object.values(rest).forEach(val => {
    val === null && (valid = false);
  });

  return valid;
};

type MyProps = {
  DC_Info: any,
  userRegister: any,
  register: any,
  history: any
};

type MyState = {
  email: string | null,
  password: string | null,
  invalidForm: boolean,
  formErrors: {
    email: string,
    password: string
  }
};

class Register extends React.Component<MyProps, MyState> {

  constructor(props: any) {
    super(props);

    this.state = {
      email: null,
      password: null,
      invalidForm: false,
      formErrors: {
        email: "",
        password: ""
      }
    };

  }

  componentDidMount() {
    if (this.props.DC_Info) {
      this.props.history.push('/');
    }
  }

  componentDidUpdate(prevProps: { userRegister: any; DC_Info: any; }, prevState: any, snapshot: any) {

    if (this.props.userRegister !== prevProps.userRegister) {
      const { loading, user, error } = this.props.userRegister;

      //console.log("prevPros userRegister : " + JSON.stringify(prevProps.userRegister) + "\\ props : " + JSON.stringify(this.props.userRegister));
      if (!error && !loading && user) {
        console.log("user " + user);
        this.props.history.push('/');
      }
    }

  }

  handleSubmit = (e: { preventDefault: () => void; }) => {

    e.preventDefault();
    if (formValid(this.state)) {
      const { email, password } = this.state;
      console.log("state .email: " + email + " pass : " + password)
      this.props.register(
        email,
        password
      );

    } else {
      let invalidForm = true;
      this.setState({ invalidForm })
      console.error("FORM INVALID - DISPLAY ERROR MESSAGE");
    }
  };

  handleChange = (e: { preventDefault: () => void; target: { name: any; value: any; }; }) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };

    //console.log("name : " + name)
    switch (name) {
      case "email":
        formErrors.email = emailRegex.test(value)
          ? ""
          : "Addresse mail invalide";
        break;
      case "password":
        formErrors.password =
          value.length < 6 ? "6 charactères minimum sont requis" : "";
        break;
      default:
        break;
    }
    //console.log(formErrors);
    this.setState({ ...this.state, [name]: value, formErrors });

    //this.setState({ ...formErrors, [name]: value }, () => console.log(this.state));
  };

  render() {
    const { formErrors, invalidForm } = this.state;
    const { loading, error } = this.props.userRegister

    return (
      <div className='centerForm'>

        <form className=" mb-4 col-md-4 col-12 card p-4 shadow" onSubmit={this.handleSubmit} noValidate>
          <div className="row">

            <div className="col-12 text-center ">
              <h1>Inscription</h1>
            </div>

            <div className="col-12 text-center mt-4">
              <input
                className={' form-control ' + (formErrors.email.length > 0 ? " is-invalid " : null)}
                placeholder="Adresse mail"
                type="email"
                name="email"
                onChange={this.handleChange}
              />
            </div>
            <div className="col-12 text-center error">
              {formErrors.email.length > 0 && (
                <span className="font-weight-bold">{formErrors.email}</span>
              )}
            </div>

            <div className="col-12 text-center mt-4">
              <input
                className={' form-control ' + (formErrors.password.length > 0 ? " is-invalid " : null)}
                placeholder="Mot de passe"
                type="password"
                name="password"
                onChange={this.handleChange}
              />
            </div>
            <div className="col-12 text-center error">
              {formErrors.password.length > 0 && (
                <span className="font-weight-bold">{formErrors.password}</span>
              )}
            </div>

            <div className="col-12 text-center mt-4">
              <button className="btn btn-primary" type="submit">Créer un compte</button>
            </div>

            <div className="col-12 text-center mt-4" >
              <Link
                to='/signIn'
                className=''
              >
                j'ai déjà un compte 
              </Link>
            </div>

            <div className="col-12 text-center mt-4" >
              {(loading && 'chargement')}

              {invalidForm &&
                <MessageInfo style="danger" text="Formulaire incorrecte" />
              }

              {error &&
                <MessageInfo style="danger" text={error} />
              }
            </div>
          </div>
        </form>
      </div>

    )
  }
}

// rend la props disponible  au composant
const mapStateToProps = (state: { userRegister: any; userSignin: { DC_Info: any; }; }) => {
  return {
    userRegister: state.userRegister,
    DC_Info: state.userSignin.DC_Info,
  }
}

// rend les fonctions disponibles au composant
const mapDispatchToProps = (dispatch: any) => {
  return {
    register: (email: string, password: string) => dispatch(register(email, password))
  }
}
//export default connect(mapStateToProps, mapDispatchToProps)(Register)
export default connect(mapStateToProps, mapDispatchToProps)(Register)