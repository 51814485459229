
function About() {
  
  return (
    
    <div className="container p-5 bg-light card mt-4">
        <p className="font-weight-bold ">
            Data Companies vous permet de rechercher des comptes d'entreprises Françaises
        </p>
        <p>
        datacompaniesback@gmail.com
        </p>
    </div>

  );
}

export default About;

