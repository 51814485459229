import { Critere } from "../models/Critere";

/* export const listCriteresConsolide: Critere[] = [
    {
      code: "FJ.3",
      traduction: "Chiffre d'affaire"
    },
    {
      code: "GG.3",
      traduction: "Resultat d'exploitation"
    },
    {
      code: "R6.1",
      traduction: "Resultat Net Consolidé"
    },
    {
      code: "CO.3",
      traduction: "Total Actif Net"
    },
    {
      code: "BJ.3",
      traduction: "Immobilisations Nettes"
    },
    {
      code: "CJ.3",
      traduction: "Actifs Circulants Nettes"
    },
    {
      code: "DL.1",
      traduction: "Capitaux propres"
    },
    
    {
      code: "EC.1",
      traduction: "Total Dettes"
    }
]; */

export const listCriteresConsolide: Critere[] = [
    {
        "code": "siren",
        "traduction": "siren"
    },
    {
        "code": "date_cloture_exercice",
        "traduction": "date_cloture_exercice"
    },
    {
        "code": "code_greffe",
        "traduction": "code_greffe"
    },
    {
        "code": "num_depot",
        "traduction": "num_depot"
    },
    {
        "code": "num_gestion",
        "traduction": "num_gestion"
    },
    {
        "code": "code_activite",
        "traduction": "code_activite"
    },
    {
        "code": "date_cloture_exercice_n_precedent",
        "traduction": "date_cloture_exercice_n_precedent"
    },
    {
        "code": "duree_exercice_n",
        "traduction": "duree_exercice_n"
    },
    {
        "code": "duree_exercice_n_precedent",
        "traduction": "duree_exercice_n_precedent"
    },
    {
        "code": "date_depot",
        "traduction": "date_depot"
    },
    {
        "code": "code_motif",
        "traduction": "code_motif"
    },
    {
        "code": "code_type_bilan",
        "traduction": "code_type_bilan"
    },
    {
        "code": "code_devise",
        "traduction": "code_devise"
    },
    {
        "code": "code_origine_devise",
        "traduction": "code_origine_devise"
    },
    {
        "code": "code_confidentialite",
        "traduction": "code_confidentialite"
    },
    {
        "code": "denomination",
        "traduction": "denomination"
    },
    {
        "code": "adresse",
        "traduction": "adresse"
    },
    {
        "code": "A.1",
        "traduction": "Montant brut année N"
    },
    {
        "code": "A.2",
        "traduction": "Amortissement année N"
    },
    {
        "code": "A.3",
        "traduction": "Net année N"
    },
    {
        "code": "A.4",
        "traduction": "Net année N-1"
    },
    {
        "code": "AA.1",
        "traduction": "Capital souscrit non appelé Montant brut année N"
    },
    {
        "code": "AA.2",
        "traduction": "Capital souscrit non appelé Amortissement année N"
    },
    {
        "code": "AA.3",
        "traduction": "Capital souscrit non appelé Net année N"
    },
    {
        "code": "AA.4",
        "traduction": "Capital souscrit non appelé Net année N-1"
    },
    {
        "code": "AB.1",
        "traduction": "Frais d’établissement Montant brut année N"
    },
    {
        "code": "AB.2",
        "traduction": "Frais d’établissement Amortissement année N"
    },
    {
        "code": "AB.3",
        "traduction": "Frais d’établissement Net année N"
    },
    {
        "code": "AB.4",
        "traduction": "Frais d’établissement Net année N-1"
    },
    {
        "code": "CX.1",
        "traduction": "Frais de développement ou de recherche et développement Montant brut année N"
    },
    {
        "code": "CX.2",
        "traduction": "Frais de développement ou de recherche et développement Amortissement année N"
    },
    {
        "code": "CX.3",
        "traduction": "Frais de développement ou de recherche et développement Net année N"
    },
    {
        "code": "CX.4",
        "traduction": "Frais de développement ou de recherche et développement Net année N-1"
    },
    {
        "code": "AF.1",
        "traduction": "Concessions, brevets et droits similaires Montant brut année N"
    },
    {
        "code": "AF.2",
        "traduction": "Concessions, brevets et droits similaires Amortissement année N"
    },
    {
        "code": "AF.3",
        "traduction": "Concessions, brevets et droits similaires Net année N"
    },
    {
        "code": "AF.4",
        "traduction": "Concessions, brevets et droits similaires Net année N-1"
    },
    {
        "code": "AH.1",
        "traduction": "Fonds commercial Montant brut année N"
    },
    {
        "code": "AH.2",
        "traduction": "Fonds commercial Amortissement année N"
    },
    {
        "code": "AH.3",
        "traduction": "Fonds commercial Net année N"
    },
    {
        "code": "AH.4",
        "traduction": "Fonds commercial Net année N-1"
    },
    {
        "code": "AJ.1",
        "traduction": "Autres immobilisations incorporelles Montant brut année N"
    },
    {
        "code": "AJ.2",
        "traduction": "Autres immobilisations incorporelles Amortissement année N"
    },
    {
        "code": "AJ.3",
        "traduction": "Autres immobilisations incorporelles Net année N"
    },
    {
        "code": "AJ.4",
        "traduction": "Autres immobilisations incorporelles Net année N-1"
    },
    {
        "code": "AL.1",
        "traduction": "Avances et acomptes sur immobilisations incorporelles. Montant brut année N"
    },
    {
        "code": "AL.2",
        "traduction": "Avances et acomptes sur immobilisations incorporelles. Amortissement année N"
    },
    {
        "code": "AL.3",
        "traduction": "Avances et acomptes sur immobilisations incorporelles. Net année N"
    },
    {
        "code": "AL.4",
        "traduction": "Avances et acomptes sur immobilisations incorporelles. Net année N-1"
    },
    {
        "code": "A1.1",
        "traduction": "Ecarts d’acquisition Montant brut année N"
    },
    {
        "code": "A1.2",
        "traduction": "Ecarts d’acquisition Amortissement année N"
    },
    {
        "code": "A1.3",
        "traduction": "Ecarts d’acquisition Net année N"
    },
    {
        "code": "A1.4",
        "traduction": "Ecarts d’acquisition Net année N-1"
    },
    {
        "code": "A2.1",
        "traduction": "Sur valeurs - goodwill Montant brut année N"
    },
    {
        "code": "A2.2",
        "traduction": "Sur valeurs - goodwill Amortissement année N"
    },
    {
        "code": "A2.3",
        "traduction": "Sur valeurs - goodwill Net année N"
    },
    {
        "code": "A2.4",
        "traduction": "Sur valeurs - goodwill Net année N-1"
    },
    {
        "code": "A3.1",
        "traduction": "Différence de première consolidation Montant brut année N"
    },
    {
        "code": "A3.2",
        "traduction": "Différence de première consolidation Amortissement année N"
    },
    {
        "code": "A3.3",
        "traduction": "Différence de première consolidation Net année N"
    },
    {
        "code": "A3.4",
        "traduction": "Différence de première consolidation Net année N-1"
    },
    {
        "code": "AN.1",
        "traduction": "Terrains Montant brut année N"
    },
    {
        "code": "AN.2",
        "traduction": "Terrains Amortissement année N"
    },
    {
        "code": "AN.3",
        "traduction": "Terrains Net année N"
    },
    {
        "code": "AN.4",
        "traduction": "Terrains Net année N-1"
    },
    {
        "code": "AP.1",
        "traduction": "Constructions Montant brut année N"
    },
    {
        "code": "AP.2",
        "traduction": "Constructions Amortissement année N"
    },
    {
        "code": "AP.3",
        "traduction": "Constructions Net année N"
    },
    {
        "code": "AP.4",
        "traduction": "Constructions Net année N-1"
    },
    {
        "code": "AR.1",
        "traduction": "Installations techniques, matériel et outillage industriels Montant brut année N"
    },
    {
        "code": "AR.2",
        "traduction": "Installations techniques, matériel et outillage industriels Amortissement année N"
    },
    {
        "code": "AR.3",
        "traduction": "Installations techniques, matériel et outillage industriels Net année N"
    },
    {
        "code": "AR.4",
        "traduction": "Installations techniques, matériel et outillage industriels Net année N-1"
    },
    {
        "code": "AT.1",
        "traduction": "Autres immobilisations corporelles Montant brut année N"
    },
    {
        "code": "AT.2",
        "traduction": "Autres immobilisations corporelles Amortissement année N"
    },
    {
        "code": "AT.3",
        "traduction": "Autres immobilisations corporelles Net année N"
    },
    {
        "code": "AT.4",
        "traduction": "Autres immobilisations corporelles Net année N-1"
    },
    {
        "code": "AV.1",
        "traduction": "Immobilisations en cours Montant brut année N"
    },
    {
        "code": "AV.2",
        "traduction": "Immobilisations en cours Amortissement année N"
    },
    {
        "code": "AV.3",
        "traduction": "Immobilisations en cours Net année N"
    },
    {
        "code": "AV.4",
        "traduction": "Immobilisations en cours Net année N-1"
    },
    {
        "code": "AX.1",
        "traduction": "Avances et acomptes Montant brut année N"
    },
    {
        "code": "AX.2",
        "traduction": "Avances et acomptes Amortissement année N"
    },
    {
        "code": "AX.3",
        "traduction": "Avances et acomptes Net année N"
    },
    {
        "code": "AX.4",
        "traduction": "Avances et acomptes Net année N-1"
    },
    {
        "code": "CS.1",
        "traduction": "Participations évaluées - mise en équivalence Montant brut année N"
    },
    {
        "code": "CS.2",
        "traduction": "Participations évaluées - mise en équivalence Amortissement année N"
    },
    {
        "code": "CS.3",
        "traduction": "Participations évaluées - mise en équivalence Net année N"
    },
    {
        "code": "CS.4",
        "traduction": "Participations évaluées - mise en équivalence Net année N-1"
    },
    {
        "code": "CU.1",
        "traduction": "Autres participations Montant brut année N"
    },
    {
        "code": "CU.2",
        "traduction": "Autres participations Amortissement année N"
    },
    {
        "code": "CU.3",
        "traduction": "Autres participations Net année N"
    },
    {
        "code": "CU.4",
        "traduction": "Autres participations Net année N-1"
    },
    {
        "code": "BB.1",
        "traduction": "Créances rattachées à des participations Montant brut année N"
    },
    {
        "code": "BB.2",
        "traduction": "Créances rattachées à des participations Amortissement année N"
    },
    {
        "code": "BB.3",
        "traduction": "Créances rattachées à des participations Net année N"
    },
    {
        "code": "BB.4",
        "traduction": "Créances rattachées à des participations Net année N-1"
    },
    {
        "code": "BD.1",
        "traduction": "Autres titres immobilisés Montant brut année N"
    },
    {
        "code": "BD.2",
        "traduction": "Autres titres immobilisés Amortissement année N"
    },
    {
        "code": "BD.3",
        "traduction": "Autres titres immobilisés Net année N"
    },
    {
        "code": "BD.4",
        "traduction": "Autres titres immobilisés Net année N-1"
    },
    {
        "code": "BF.1",
        "traduction": "Prêts Montant brut année N"
    },
    {
        "code": "BF.2",
        "traduction": "Prêts Amortissement année N"
    },
    {
        "code": "BF.3",
        "traduction": "Prêts Net année N"
    },
    {
        "code": "BF.4",
        "traduction": "Prêts Net année N-1"
    },
    {
        "code": "BH.1",
        "traduction": "Autres immobilisations financières Montant brut année N"
    },
    {
        "code": "BH.2",
        "traduction": "Autres immobilisations financières Amortissement année N"
    },
    {
        "code": "BH.3",
        "traduction": "Autres immobilisations financières Net année N"
    },
    {
        "code": "BH.4",
        "traduction": "Autres immobilisations financières Net année N-1"
    },
    {
        "code": "A4.1",
        "traduction": "Titres mis en équivalence Montant brut année N"
    },
    {
        "code": "A4.2",
        "traduction": "Titres mis en équivalence Amortissement année N"
    },
    {
        "code": "A4.3",
        "traduction": "Titres mis en équivalence Net année N"
    },
    {
        "code": "A4.4",
        "traduction": "Titres mis en équivalence Net année N-1"
    },
    {
        "code": "BJ.1",
        "traduction": "TOTAL IMMOBILISATIONS Montant brut année N"
    },
    {
        "code": "BJ.2",
        "traduction": "TOTAL IMMOBILISATIONS Amortissement année N"
    },
    {
        "code": "BJ.3",
        "traduction": "TOTAL IMMOBILISATIONS Net année N"
    },
    {
        "code": "BJ.4",
        "traduction": "TOTAL IMMOBILISATIONS Net année N-1"
    },
    {
        "code": "BL.1",
        "traduction": "Matières premières, approvisionnements Montant brut année N"
    },
    {
        "code": "BL.2",
        "traduction": "Matières premières, approvisionnements Amortissement année N"
    },
    {
        "code": "BL.3",
        "traduction": "Matières premières, approvisionnements Net année N"
    },
    {
        "code": "BL.4",
        "traduction": "Matières premières, approvisionnements Net année N-1"
    },
    {
        "code": "BN.1",
        "traduction": "En cours de production de biens Montant brut année N"
    },
    {
        "code": "BN.2",
        "traduction": "En cours de production de biens Amortissement année N"
    },
    {
        "code": "BN.3",
        "traduction": "En cours de production de biens Net année N"
    },
    {
        "code": "BN.4",
        "traduction": "En cours de production de biens Net année N-1"
    },
    {
        "code": "BP.1",
        "traduction": "En cours de production de services Montant brut année N"
    },
    {
        "code": "BP.2",
        "traduction": "En cours de production de services Amortissement année N"
    },
    {
        "code": "BP.3",
        "traduction": "En cours de production de services Net année N"
    },
    {
        "code": "BP.4",
        "traduction": "En cours de production de services Net année N-1"
    },
    {
        "code": "BR.1",
        "traduction": "Produits intermédiaires et finis Montant brut année N"
    },
    {
        "code": "BR.2",
        "traduction": "Produits intermédiaires et finis Amortissement année N"
    },
    {
        "code": "BR.3",
        "traduction": "Produits intermédiaires et finis Net année N"
    },
    {
        "code": "BR.4",
        "traduction": "Produits intermédiaires et finis Net année N-1"
    },
    {
        "code": "BT.1",
        "traduction": "Marchandises Montant brut année N"
    },
    {
        "code": "BT.2",
        "traduction": "Marchandises Amortissement année N"
    },
    {
        "code": "BT.3",
        "traduction": "Marchandises Net année N"
    },
    {
        "code": "BT.4",
        "traduction": "Marchandises Net année N-1"
    },
    {
        "code": "BV.1",
        "traduction": "Avances et acomptes versés sur commandes Montant brut année N"
    },
    {
        "code": "BV.2",
        "traduction": "Avances et acomptes versés sur commandes Amortissement année N"
    },
    {
        "code": "BV.3",
        "traduction": "Avances et acomptes versés sur commandes Net année N"
    },
    {
        "code": "BV.4",
        "traduction": "Avances et acomptes versés sur commandes Net année N-1"
    },
    {
        "code": "BX.1",
        "traduction": "Clients et comptes rattachés Montant brut année N"
    },
    {
        "code": "BX.2",
        "traduction": "Clients et comptes rattachés Amortissement année N"
    },
    {
        "code": "BX.3",
        "traduction": "Clients et comptes rattachés Net année N"
    },
    {
        "code": "BX.4",
        "traduction": "Clients et comptes rattachés Net année N-1"
    },
    {
        "code": "BZ.1",
        "traduction": "Autres créances Montant brut année N"
    },
    {
        "code": "BZ.2",
        "traduction": "Autres créances Amortissement année N"
    },
    {
        "code": "BZ.3",
        "traduction": "Autres créances Net année N"
    },
    {
        "code": "BZ.4",
        "traduction": "Autres créances Net année N-1"
    },
    {
        "code": "CB.1",
        "traduction": "Capital souscrit et appelé, non versé Montant brut année N"
    },
    {
        "code": "CB.2",
        "traduction": "Capital souscrit et appelé, non versé Amortissement année N"
    },
    {
        "code": "CB.3",
        "traduction": "Capital souscrit et appelé, non versé Net année N"
    },
    {
        "code": "CB.4",
        "traduction": "Capital souscrit et appelé, non versé Net année N-1"
    },
    {
        "code": "CD.1",
        "traduction": "Valeurs mobilières de placement Montant brut année N"
    },
    {
        "code": "CD.2",
        "traduction": "Valeurs mobilières de placement Amortissement année N"
    },
    {
        "code": "CD.3",
        "traduction": "Valeurs mobilières de placement Net année N"
    },
    {
        "code": "CD.4",
        "traduction": "Valeurs mobilières de placement Net année N-1"
    },
    {
        "code": "CF.1",
        "traduction": "Disponibilités Montant brut année N"
    },
    {
        "code": "CF.2",
        "traduction": "Disponibilités Amortissement année N"
    },
    {
        "code": "CF.3",
        "traduction": "Disponibilités Net année N"
    },
    {
        "code": "CF.4",
        "traduction": "Disponibilités Net année N-1"
    },
    {
        "code": "CH.1",
        "traduction": "Charges constatées d’avance Montant brut année N"
    },
    {
        "code": "CH.2",
        "traduction": "Charges constatées d’avance Amortissement année N"
    },
    {
        "code": "CH.3",
        "traduction": "Charges constatées d’avance Net année N"
    },
    {
        "code": "CH.4",
        "traduction": "Charges constatées d’avance Net année N-1"
    },
    {
        "code": "CJ.1",
        "traduction": "TOTAL ACTIFS CIRCULANTS Montant brut année N"
    },
    {
        "code": "CJ.2",
        "traduction": "TOTAL ACTIFS CIRCULANTS Amortissement année N"
    },
    {
        "code": "CJ.3",
        "traduction": "TOTAL ACTIFS CIRCULANTS Net année N"
    },
    {
        "code": "CJ.4",
        "traduction": "TOTAL ACTIFS CIRCULANTS Net année N-1"
    },
    {
        "code": "CW.1",
        "traduction": "Charges à répartir ou frais d’émission d’emprunt Montant brut année N"
    },
    {
        "code": "CW.2",
        "traduction": "Charges à répartir ou frais d’émission d’emprunt Amortissement année N"
    },
    {
        "code": "CW.3",
        "traduction": "Charges à répartir ou frais d’émission d’emprunt Net année N"
    },
    {
        "code": "CW.4",
        "traduction": "Charges à répartir ou frais d’émission d’emprunt Net année N-1"
    },
    {
        "code": "CM.1",
        "traduction": "Primes de remboursement des obligations (IV) Montant brut année N"
    },
    {
        "code": "CM.2",
        "traduction": "Primes de remboursement des obligations (IV) Amortissement année N"
    },
    {
        "code": "CM.3",
        "traduction": "Primes de remboursement des obligations (IV) Net année N"
    },
    {
        "code": "CM.4",
        "traduction": "Primes de remboursement des obligations (IV) Net année N-1"
    },
    {
        "code": "CN.1",
        "traduction": "Ecarts de conversion actif (V) Montant brut année N"
    },
    {
        "code": "CN.2",
        "traduction": "Ecarts de conversion actif (V) Amortissement année N"
    },
    {
        "code": "CN.3",
        "traduction": "Ecarts de conversion actif (V) Net année N"
    },
    {
        "code": "CN.4",
        "traduction": "Ecarts de conversion actif (V) Net année N-1"
    },
    {
        "code": "CO.1",
        "traduction": "TOTAL GENERAL (0 à V) Montant brut année N"
    },
    {
        "code": "CO.2",
        "traduction": "TOTAL GENERAL (0 à V) Amortissement année N"
    },
    {
        "code": "CO.3",
        "traduction": "TOTAL GENERAL (0 à V) Net année N"
    },
    {
        "code": "CO.4",
        "traduction": "TOTAL GENERAL (0 à V) Net année N-1"
    },
    {
        "code": "CP.1",
        "traduction": "Parts à moins d’un an Montant brut année N"
    },
    {
        "code": "CP.2",
        "traduction": "Parts à moins d’un an Amortissement année N"
    },
    {
        "code": "CP.3",
        "traduction": "Parts à moins d’un an Net année N"
    },
    {
        "code": "CP.4",
        "traduction": "Parts à moins d’un an Net année N-1"
    },
    {
        "code": "CR.1",
        "traduction": "Parts à plus d’un an Montant brut année N"
    },
    {
        "code": "CR.2",
        "traduction": "Parts à plus d’un an Amortissement année N"
    },
    {
        "code": "CR.3",
        "traduction": "Parts à plus d’un an Net année N"
    },
    {
        "code": "CR.4",
        "traduction": "Parts à plus d’un an Net année N-1"
    },
    {
        "code": "P.1",
        "traduction": "Montant année N"
    },
    {
        "code": "P.2",
        "traduction": "Montant année N-1"
    },
    {
        "code": "P.3",
        "traduction": ""
    },
    {
        "code": "P.4",
        "traduction": ""
    },
    {
        "code": "DA.1",
        "traduction": "Capital social ou individuel Montant année N"
    },
    {
        "code": "DA.2",
        "traduction": "Capital social ou individuel Montant année N-1"
    },
    {
        "code": "DB.1",
        "traduction": "Primes d’émission, de fusion, d’apport, ... Montant année N"
    },
    {
        "code": "DB.2",
        "traduction": "Primes d’émission, de fusion, d’apport, ... Montant année N-1"
    },
    {
        "code": "EK.1",
        "traduction": "Ecart d’équivalence (issu des primes d'émission, fusion,…) Montant année N"
    },
    {
        "code": "EK.2",
        "traduction": "Ecart d’équivalence (issu des primes d'émission, fusion,…) Montant année N-1"
    },
    {
        "code": "DC.1",
        "traduction": "Ecarts de réévaluation Montant année N"
    },
    {
        "code": "DC.2",
        "traduction": "Ecarts de réévaluation Montant année N-1"
    },
    {
        "code": "DD.1",
        "traduction": "Réserve légale (1) Montant année N"
    },
    {
        "code": "DD.2",
        "traduction": "Réserve légale (1) Montant année N-1"
    },
    {
        "code": "DE.1",
        "traduction": "Réserves statutaires ou contractuelles Montant année N"
    },
    {
        "code": "DE.2",
        "traduction": "Réserves statutaires ou contractuelles Montant année N-1"
    },
    {
        "code": "DF.1",
        "traduction": "Réserves réglementées (1) Montant année N"
    },
    {
        "code": "DF.2",
        "traduction": "Réserves réglementées (1) Montant année N-1"
    },
    {
        "code": "DG.1",
        "traduction": "Autres réserves Montant année N"
    },
    {
        "code": "DG.2",
        "traduction": "Autres réserves Montant année N-1"
    },
    {
        "code": "DH.1",
        "traduction": "Report à nouveau Montant année N"
    },
    {
        "code": "DH.2",
        "traduction": "Report à nouveau Montant année N-1"
    },
    {
        "code": "P1.1",
        "traduction": "Ecarts de conversion Montant année N"
    },
    {
        "code": "P1.2",
        "traduction": "Ecarts de conversion Montant année N-1"
    },
    {
        "code": "P2.1",
        "traduction": "Résultat consolidé part du groupe Montant année N"
    },
    {
        "code": "P2.2",
        "traduction": "Résultat consolidé part du groupe Montant année N-1"
    },
    {
        "code": "DJ.1",
        "traduction": "Subventions d’investissement Montant année N"
    },
    {
        "code": "DJ.2",
        "traduction": "Subventions d’investissement Montant année N-1"
    },
    {
        "code": "DK.1",
        "traduction": "Provisions réglementées Montant année N"
    },
    {
        "code": "DK.2",
        "traduction": "Provisions réglementées Montant année N-1"
    },
    {
        "code": "DL.1",
        "traduction": "TOTAL CAPITAUX PROPRES Montant année N"
    },
    {
        "code": "DL.2",
        "traduction": "TOTAL CAPITAUX PROPRES Montant année N-1"
    },
    {
        "code": "DM.1",
        "traduction": "Produit des émissions de titres participatifs Montant année N"
    },
    {
        "code": "DM.2",
        "traduction": "Produit des émissions de titres participatifs Montant année N-1"
    },
    {
        "code": "DN.1",
        "traduction": "Avances conditionnées Montant année N"
    },
    {
        "code": "DN.2",
        "traduction": "Avances conditionnées Montant année N-1"
    },
    {
        "code": "P3.1",
        "traduction": "Autres Montant année N"
    },
    {
        "code": "P3.2",
        "traduction": "Autres Montant année N-1"
    },
    {
        "code": "DO.1",
        "traduction": "TOTAL AUTRES FONDS PROPRES Montant année N"
    },
    {
        "code": "DO.2",
        "traduction": "TOTAL AUTRES FONDS PROPRES Montant année N-1"
    },
    {
        "code": "P4.1",
        "traduction": "Ecarts de conversion Montant année N"
    },
    {
        "code": "P4.2",
        "traduction": "Ecarts de conversion Montant année N-1"
    },
    {
        "code": "P5.1",
        "traduction": "Dans les réserves Montant année N"
    },
    {
        "code": "P5.2",
        "traduction": "Dans les réserves Montant année N-1"
    },
    {
        "code": "P6.1",
        "traduction": "Dans les résultats Montant année N"
    },
    {
        "code": "P6.2",
        "traduction": "Dans les résultats Montant année N-1"
    },
    {
        "code": "P7.1",
        "traduction": "TOTAL PROVISIONS POUR RISQUES ET CHARGES Montant année N"
    },
    {
        "code": "P7.2",
        "traduction": "TOTAL PROVISIONS POUR RISQUES ET CHARGES Montant année N-1"
    },
    {
        "code": "P8.1",
        "traduction": "Impôts différés Montant année N"
    },
    {
        "code": "P8.2",
        "traduction": "Impôts différés Montant année N-1"
    },
    {
        "code": "P9.1",
        "traduction": "Ecarts d’acquisition Montant année N"
    },
    {
        "code": "P9.2",
        "traduction": "Ecarts d’acquisition Montant année N-1"
    },
    {
        "code": "DP.1",
        "traduction": "Provisions pour risques Montant année N"
    },
    {
        "code": "DP.2",
        "traduction": "Provisions pour risques Montant année N-1"
    },
    {
        "code": "DQ.1",
        "traduction": "Provisions pour charges Montant année N"
    },
    {
        "code": "DQ.2",
        "traduction": "Provisions pour charges Montant année N-1"
    },
    {
        "code": "DR.1",
        "traduction": "TOTAL PROVISIONS POUR RISQUES ET CHARGES, IMPOTS DIFFERES ET ECART D ACQUISITION Montant année N"
    },
    {
        "code": "DR.2",
        "traduction": "TOTAL PROVISIONS POUR RISQUES ET CHARGES, IMPOTS DIFFERES ET ECART D ACQUISITION Montant année N-1"
    },
    {
        "code": "DS.1",
        "traduction": "Emprunts obligataires convertibles Montant année N"
    },
    {
        "code": "DS.2",
        "traduction": "Emprunts obligataires convertibles Montant année N-1"
    },
    {
        "code": "DT.1",
        "traduction": "Autres emprunts obligataires Montant année N"
    },
    {
        "code": "DT.2",
        "traduction": "Autres emprunts obligataires Montant année N-1"
    },
    {
        "code": "DU.1",
        "traduction": "Emprunts et dettes auprès des établissements de crédit (3) Montant année N"
    },
    {
        "code": "DU.2",
        "traduction": "Emprunts et dettes auprès des établissements de crédit (3) Montant année N-1"
    },
    {
        "code": "DV.1",
        "traduction": "Emprunts et dettes financières divers (4) Montant année N"
    },
    {
        "code": "DV.2",
        "traduction": "Emprunts et dettes financières divers (4) Montant année N-1"
    },
    {
        "code": "DW.1",
        "traduction": "Avances et acomptes reçus sur commandes en cours Montant année N"
    },
    {
        "code": "DW.2",
        "traduction": "Avances et acomptes reçus sur commandes en cours Montant année N-1"
    },
    {
        "code": "DX.1",
        "traduction": "Dettes fournisseurs et comptes rattachés Montant année N"
    },
    {
        "code": "DX.2",
        "traduction": "Dettes fournisseurs et comptes rattachés Montant année N-1"
    },
    {
        "code": "DY.1",
        "traduction": "Dettes fiscales et sociales Montant année N"
    },
    {
        "code": "DY.2",
        "traduction": "Dettes fiscales et sociales Montant année N-1"
    },
    {
        "code": "DZ.1",
        "traduction": "Dettes sur immobilisations et comptes rattachés Montant année N"
    },
    {
        "code": "DZ.2",
        "traduction": "Dettes sur immobilisations et comptes rattachés Montant année N-1"
    },
    {
        "code": "EA.1",
        "traduction": "Autres dettes Montant année N"
    },
    {
        "code": "EA.2",
        "traduction": "Autres dettes Montant année N-1"
    },
    {
        "code": "EB.1",
        "traduction": "Produits constatés d’avance (2) Montant année N"
    },
    {
        "code": "EB.2",
        "traduction": "Produits constatés d’avance (2) Montant année N-1"
    },
    {
        "code": "EC.1",
        "traduction": "TOTAL DETTES Montant année N"
    },
    {
        "code": "EC.2",
        "traduction": "TOTAL DETTES Montant année N-1"
    },
    {
        "code": "ED.1",
        "traduction": "(V) Montant année N"
    },
    {
        "code": "ED.2",
        "traduction": "(V) Montant année N-1"
    },
    {
        "code": "EE.1",
        "traduction": "TOTAL PASSIF Montant année N"
    },
    {
        "code": "EE.2",
        "traduction": "TOTAL PASSIF Montant année N-1"
    },
    {
        "code": "EF.1",
        "traduction": "Reserve reglementee des plus-values a  long terme (sur Passif) Montant année N"
    },
    {
        "code": "EF.2",
        "traduction": "Reserve reglementee des plus-values a  long terme (sur Passif) Montant année N-1"
    },
    {
        "code": "EG.1",
        "traduction": "Dettes et produits constates d avance a  moins d un an (sur Passif) Montant année N"
    },
    {
        "code": "EG.2",
        "traduction": "Dettes et produits constates d avance a  moins d un an (sur Passif) Montant année N-1"
    },
    {
        "code": "EH.1",
        "traduction": "Concours bancaires courants,  et soldes crediteurs de banques et C.C.P (sur Passif) Montant année N"
    },
    {
        "code": "EH.2",
        "traduction": "Concours bancaires courants,  et soldes crediteurs de banques et C.C.P (sur Passif) Montant année N-1"
    },
    {
        "code": "EI.1",
        "traduction": "Emprunts participatifs (sur Passif) Montant année N"
    },
    {
        "code": "EI.2",
        "traduction": "Emprunts participatifs (sur Passif) Montant année N-1"
    },
    {
        "code": "CDR.1",
        "traduction": "Montant France année N"
    },
    {
        "code": "CDR.2",
        "traduction": "Montant Export année N"
    },
    {
        "code": "CDR.3",
        "traduction": "Total année N"
    },
    {
        "code": "CDR.4",
        "traduction": "Total année N-1"
    },
    {
        "code": "FA.1",
        "traduction": "Ventes de marchandises Montant France année N"
    },
    {
        "code": "FA.2",
        "traduction": "Ventes de marchandises Montant Export année N"
    },
    {
        "code": "FA.3",
        "traduction": "Ventes de marchandises Total année N"
    },
    {
        "code": "FA.4",
        "traduction": "Ventes de marchandises Total année N-1"
    },
    {
        "code": "FD.1",
        "traduction": "Production vendue biens Montant France année N"
    },
    {
        "code": "FD.2",
        "traduction": "Production vendue biens Montant Export année N"
    },
    {
        "code": "FD.3",
        "traduction": "Production vendue biens Total année N"
    },
    {
        "code": "FD.4",
        "traduction": "Production vendue biens Total année N-1"
    },
    {
        "code": "FG.1",
        "traduction": "Production vendue services Montant France année N"
    },
    {
        "code": "FG.2",
        "traduction": "Production vendue services Montant Export année N"
    },
    {
        "code": "FG.3",
        "traduction": "Production vendue services Total année N"
    },
    {
        "code": "FG.4",
        "traduction": "Production vendue services Total année N-1"
    },
    {
        "code": "FJ.1",
        "traduction": "Chiffres d’affaires nets Montant France année N"
    },
    {
        "code": "FJ.2",
        "traduction": "Chiffres d’affaires nets Montant Export année N"
    },
    {
        "code": "FJ.3",
        "traduction": "Chiffres d’affaires nets Total année N"
    },
    {
        "code": "FJ.4",
        "traduction": "Chiffres d’affaires nets Total année N-1"
    },
    {
        "code": "FM.1",
        "traduction": "Production stockée Montant France année N"
    },
    {
        "code": "FM.2",
        "traduction": "Production stockée Montant Export année N"
    },
    {
        "code": "FM.3",
        "traduction": "Production stockée Total année N"
    },
    {
        "code": "FM.4",
        "traduction": "Production stockée Total année N-1"
    },
    {
        "code": "FN.1",
        "traduction": "Production immobilisée Montant France année N"
    },
    {
        "code": "FN.2",
        "traduction": "Production immobilisée Montant Export année N"
    },
    {
        "code": "FN.3",
        "traduction": "Production immobilisée Total année N"
    },
    {
        "code": "FN.4",
        "traduction": "Production immobilisée Total année N-1"
    },
    {
        "code": "FO.1",
        "traduction": "Subventions d’exploitation Montant France année N"
    },
    {
        "code": "FO.2",
        "traduction": "Subventions d’exploitation Montant Export année N"
    },
    {
        "code": "FO.3",
        "traduction": "Subventions d’exploitation Total année N"
    },
    {
        "code": "FO.4",
        "traduction": "Subventions d’exploitation Total année N-1"
    },
    {
        "code": "FP.1",
        "traduction": "Reprises sur amortissements et provisions, transfert de charges Montant France année N"
    },
    {
        "code": "FP.2",
        "traduction": "Reprises sur amortissements et provisions, transfert de charges Montant Export année N"
    },
    {
        "code": "FP.3",
        "traduction": "Reprises sur amortissements et provisions, transfert de charges Total année N"
    },
    {
        "code": "FP.4",
        "traduction": "Reprises sur amortissements et provisions, transfert de charges Total année N-1"
    },
    {
        "code": "FQ.1",
        "traduction": "Autres produits Montant France année N"
    },
    {
        "code": "FQ.2",
        "traduction": "Autres produits Montant Export année N"
    },
    {
        "code": "FQ.3",
        "traduction": "Autres produits Total année N"
    },
    {
        "code": "FQ.4",
        "traduction": "Autres produits Total année N-1"
    },
    {
        "code": "FR.1",
        "traduction": "Total des produits d’exploitation (I) Montant France année N"
    },
    {
        "code": "FR.2",
        "traduction": "Total des produits d’exploitation (I) Montant Export année N"
    },
    {
        "code": "FR.3",
        "traduction": "Total des produits d’exploitation (I) Total année N"
    },
    {
        "code": "FR.4",
        "traduction": "Total des produits d’exploitation (I) Total année N-1"
    },
    {
        "code": "FS.1",
        "traduction": "Achats de marchandises (y compris droits de douane) Montant France année N"
    },
    {
        "code": "FS.2",
        "traduction": "Achats de marchandises (y compris droits de douane) Montant Export année N"
    },
    {
        "code": "FS.3",
        "traduction": "Achats de marchandises (y compris droits de douane) Total année N"
    },
    {
        "code": "FS.4",
        "traduction": "Achats de marchandises (y compris droits de douane) Total année N-1"
    },
    {
        "code": "FT.1",
        "traduction": "Variation de stock (marchandises) Montant France année N"
    },
    {
        "code": "FT.2",
        "traduction": "Variation de stock (marchandises) Montant Export année N"
    },
    {
        "code": "FT.3",
        "traduction": "Variation de stock (marchandises) Total année N"
    },
    {
        "code": "FT.4",
        "traduction": "Variation de stock (marchandises) Total année N-1"
    },
    {
        "code": "FU.1",
        "traduction": "Achats de matières premières et autres approvisionnements Montant France année N"
    },
    {
        "code": "FU.2",
        "traduction": "Achats de matières premières et autres approvisionnements Montant Export année N"
    },
    {
        "code": "FU.3",
        "traduction": "Achats de matières premières et autres approvisionnements Total année N"
    },
    {
        "code": "FU.4",
        "traduction": "Achats de matières premières et autres approvisionnements Total année N-1"
    },
    {
        "code": "FV.1",
        "traduction": "Variation de stock (matières premières et approvisionnements) Montant France année N"
    },
    {
        "code": "FV.2",
        "traduction": "Variation de stock (matières premières et approvisionnements) Montant Export année N"
    },
    {
        "code": "FV.3",
        "traduction": "Variation de stock (matières premières et approvisionnements) Total année N"
    },
    {
        "code": "FV.4",
        "traduction": "Variation de stock (matières premières et approvisionnements) Total année N-1"
    },
    {
        "code": "FW.1",
        "traduction": "Autres achats et charges externes Montant France année N"
    },
    {
        "code": "FW.2",
        "traduction": "Autres achats et charges externes Montant Export année N"
    },
    {
        "code": "FW.3",
        "traduction": "Autres achats et charges externes Total année N"
    },
    {
        "code": "FW.4",
        "traduction": "Autres achats et charges externes Total année N-1"
    },
    {
        "code": "FX.1",
        "traduction": "Impôts, taxes et versements assimilés Montant France année N"
    },
    {
        "code": "FX.2",
        "traduction": "Impôts, taxes et versements assimilés Montant Export année N"
    },
    {
        "code": "FX.3",
        "traduction": "Impôts, taxes et versements assimilés Total année N"
    },
    {
        "code": "FX.4",
        "traduction": "Impôts, taxes et versements assimilés Total année N-1"
    },
    {
        "code": "FY.1",
        "traduction": "Salaires et traitements Montant France année N"
    },
    {
        "code": "FY.2",
        "traduction": "Salaires et traitements Montant Export année N"
    },
    {
        "code": "FY.3",
        "traduction": "Salaires et traitements Total année N"
    },
    {
        "code": "FY.4",
        "traduction": "Salaires et traitements Total année N-1"
    },
    {
        "code": "FZ.1",
        "traduction": "Charges sociales Montant France année N"
    },
    {
        "code": "FZ.2",
        "traduction": "Charges sociales Montant Export année N"
    },
    {
        "code": "FZ.3",
        "traduction": "Charges sociales Total année N"
    },
    {
        "code": "FZ.4",
        "traduction": "Charges sociales Total année N-1"
    },
    {
        "code": "GA.1",
        "traduction": "Dot. d’exploit. - Dotations aux amortissements Montant France année N"
    },
    {
        "code": "GA.2",
        "traduction": "Dot. d’exploit. - Dotations aux amortissements Montant Export année N"
    },
    {
        "code": "GA.3",
        "traduction": "Dot. d’exploit. - Dotations aux amortissements Total année N"
    },
    {
        "code": "GA.4",
        "traduction": "Dot. d’exploit. - Dotations aux amortissements Total année N-1"
    },
    {
        "code": "GB.1",
        "traduction": "Dot. d’exploit. - Dotations aux provisions Montant France année N"
    },
    {
        "code": "GB.2",
        "traduction": "Dot. d’exploit. - Dotations aux provisions Montant Export année N"
    },
    {
        "code": "GB.3",
        "traduction": "Dot. d’exploit. - Dotations aux provisions Total année N"
    },
    {
        "code": "GB.4",
        "traduction": "Dot. d’exploit. - Dotations aux provisions Total année N-1"
    },
    {
        "code": "GC.1",
        "traduction": "Dot. d’exploit. Sur actif circulant : dotations aux provisions Montant France année N"
    },
    {
        "code": "GC.2",
        "traduction": "Dot. d’exploit. Sur actif circulant : dotations aux provisions Montant Export année N"
    },
    {
        "code": "GC.3",
        "traduction": "Dot. d’exploit. Sur actif circulant : dotations aux provisions Total année N"
    },
    {
        "code": "GC.4",
        "traduction": "Dot. d’exploit. Sur actif circulant : dotations aux provisions Total année N-1"
    },
    {
        "code": "GD.1",
        "traduction": "Dot. d’exploit. Pour risques et charges : dotations aux provisions Montant France année N"
    },
    {
        "code": "GD.2",
        "traduction": "Dot. d’exploit. Pour risques et charges : dotations aux provisions Montant Export année N"
    },
    {
        "code": "GD.3",
        "traduction": "Dot. d’exploit. Pour risques et charges : dotations aux provisions Total année N"
    },
    {
        "code": "GD.4",
        "traduction": "Dot. d’exploit. Pour risques et charges : dotations aux provisions Total année N-1"
    },
    {
        "code": "GE.1",
        "traduction": "Autres charges Montant France année N"
    },
    {
        "code": "GE.2",
        "traduction": "Autres charges Montant Export année N"
    },
    {
        "code": "GE.3",
        "traduction": "Autres charges Total année N"
    },
    {
        "code": "GE.4",
        "traduction": "Autres charges Total année N-1"
    },
    {
        "code": "GF.1",
        "traduction": "Total des charges d’exploitation (II) Montant France année N"
    },
    {
        "code": "GF.2",
        "traduction": "Total des charges d’exploitation (II) Montant Export année N"
    },
    {
        "code": "GF.3",
        "traduction": "Total des charges d’exploitation (II) Total année N"
    },
    {
        "code": "GF.4",
        "traduction": "Total des charges d’exploitation (II) Total année N-1"
    },
    {
        "code": "GG.1",
        "traduction": "RESULTAT D EXPLOITATION (I - II) Montant France année N"
    },
    {
        "code": "GG.2",
        "traduction": "RESULTAT D EXPLOITATION (I - II) Montant Export année N"
    },
    {
        "code": "GG.3",
        "traduction": "RESULTAT D EXPLOITATION (I - II) Total année N"
    },
    {
        "code": "GG.4",
        "traduction": "RESULTAT D EXPLOITATION (I - II) Total année N-1"
    },
    {
        "code": "GH.1",
        "traduction": "Bénéfice attribué ou perte transférée (III) Montant France année N"
    },
    {
        "code": "GH.2",
        "traduction": "Bénéfice attribué ou perte transférée (III) Montant Export année N"
    },
    {
        "code": "GH.3",
        "traduction": "Bénéfice attribué ou perte transférée (III) Total année N"
    },
    {
        "code": "GH.4",
        "traduction": "Bénéfice attribué ou perte transférée (III) Total année N-1"
    },
    {
        "code": "GI.1",
        "traduction": "Perte supportée ou bénéfice transféré (IV) Montant France année N"
    },
    {
        "code": "GI.2",
        "traduction": "Perte supportée ou bénéfice transféré (IV) Montant Export année N"
    },
    {
        "code": "GI.3",
        "traduction": "Perte supportée ou bénéfice transféré (IV) Total année N"
    },
    {
        "code": "GI.4",
        "traduction": "Perte supportée ou bénéfice transféré (IV) Total année N-1"
    },
    {
        "code": "GJ.1",
        "traduction": "Produits financiers de participations Montant France année N"
    },
    {
        "code": "GJ.2",
        "traduction": "Produits financiers de participations Montant Export année N"
    },
    {
        "code": "GJ.3",
        "traduction": "Produits financiers de participations Total année N"
    },
    {
        "code": "GJ.4",
        "traduction": "Produits financiers de participations Total année N-1"
    },
    {
        "code": "GK.1",
        "traduction": "Produits des autres valeurs mobilières et créances de l’actif immobilisé Montant France année N"
    },
    {
        "code": "GK.2",
        "traduction": "Produits des autres valeurs mobilières et créances de l’actif immobilisé Montant Export année N"
    },
    {
        "code": "GK.3",
        "traduction": "Produits des autres valeurs mobilières et créances de l’actif immobilisé Total année N"
    },
    {
        "code": "GK.4",
        "traduction": "Produits des autres valeurs mobilières et créances de l’actif immobilisé Total année N-1"
    },
    {
        "code": "GL.1",
        "traduction": "Autres intérêts et produits assimilés Montant France année N"
    },
    {
        "code": "GL.2",
        "traduction": "Autres intérêts et produits assimilés Montant Export année N"
    },
    {
        "code": "GL.3",
        "traduction": "Autres intérêts et produits assimilés Total année N"
    },
    {
        "code": "GL.4",
        "traduction": "Autres intérêts et produits assimilés Total année N-1"
    },
    {
        "code": "GM.1",
        "traduction": "Reprises sur provisions et transferts de charges Montant France année N"
    },
    {
        "code": "GM.2",
        "traduction": "Reprises sur provisions et transferts de charges Montant Export année N"
    },
    {
        "code": "GM.3",
        "traduction": "Reprises sur provisions et transferts de charges Total année N"
    },
    {
        "code": "GM.4",
        "traduction": "Reprises sur provisions et transferts de charges Total année N-1"
    },
    {
        "code": "GN.1",
        "traduction": "Différences positives de change Montant France année N"
    },
    {
        "code": "GN.2",
        "traduction": "Différences positives de change Montant Export année N"
    },
    {
        "code": "GN.3",
        "traduction": "Différences positives de change Total année N"
    },
    {
        "code": "GN.4",
        "traduction": "Différences positives de change Total année N-1"
    },
    {
        "code": "GO.1",
        "traduction": "Produits nets sur cessions de valeurs mobilières de placement Montant France année N"
    },
    {
        "code": "GO.2",
        "traduction": "Produits nets sur cessions de valeurs mobilières de placement Montant Export année N"
    },
    {
        "code": "GO.3",
        "traduction": "Produits nets sur cessions de valeurs mobilières de placement Total année N"
    },
    {
        "code": "GO.4",
        "traduction": "Produits nets sur cessions de valeurs mobilières de placement Total année N-1"
    },
    {
        "code": "GP.1",
        "traduction": "Total des produits financiers (V) Montant France année N"
    },
    {
        "code": "GP.2",
        "traduction": "Total des produits financiers (V) Montant Export année N"
    },
    {
        "code": "GP.3",
        "traduction": "Total des produits financiers (V) Total année N"
    },
    {
        "code": "GP.4",
        "traduction": "Total des produits financiers (V) Total année N-1"
    },
    {
        "code": "GQ.1",
        "traduction": "Dotations financières sur amortissements et provisions Montant France année N"
    },
    {
        "code": "GQ.2",
        "traduction": "Dotations financières sur amortissements et provisions Montant Export année N"
    },
    {
        "code": "GQ.3",
        "traduction": "Dotations financières sur amortissements et provisions Total année N"
    },
    {
        "code": "GQ.4",
        "traduction": "Dotations financières sur amortissements et provisions Total année N-1"
    },
    {
        "code": "GR.1",
        "traduction": "Intérêts et charges assimilées Montant France année N"
    },
    {
        "code": "GR.2",
        "traduction": "Intérêts et charges assimilées Montant Export année N"
    },
    {
        "code": "GR.3",
        "traduction": "Intérêts et charges assimilées Total année N"
    },
    {
        "code": "GR.4",
        "traduction": "Intérêts et charges assimilées Total année N-1"
    },
    {
        "code": "GS.1",
        "traduction": "Différences négatives de change Montant France année N"
    },
    {
        "code": "GS.2",
        "traduction": "Différences négatives de change Montant Export année N"
    },
    {
        "code": "GS.3",
        "traduction": "Différences négatives de change Total année N"
    },
    {
        "code": "GS.4",
        "traduction": "Différences négatives de change Total année N-1"
    },
    {
        "code": "GT.1",
        "traduction": "Charges nettes sur cessions de valeurs mobilières de placement Montant France année N"
    },
    {
        "code": "GT.2",
        "traduction": "Charges nettes sur cessions de valeurs mobilières de placement Montant Export année N"
    },
    {
        "code": "GT.3",
        "traduction": "Charges nettes sur cessions de valeurs mobilières de placement Total année N"
    },
    {
        "code": "GT.4",
        "traduction": "Charges nettes sur cessions de valeurs mobilières de placement Total année N-1"
    },
    {
        "code": "GU.1",
        "traduction": "Total des charges financières (VI) Montant France année N"
    },
    {
        "code": "GU.2",
        "traduction": "Total des charges financières (VI) Montant Export année N"
    },
    {
        "code": "GU.3",
        "traduction": "Total des charges financières (VI) Total année N"
    },
    {
        "code": "GU.4",
        "traduction": "Total des charges financières (VI) Total année N-1"
    },
    {
        "code": "GV.1",
        "traduction": "RESULTAT FINANCIER (V - VI) Montant France année N"
    },
    {
        "code": "GV.2",
        "traduction": "RESULTAT FINANCIER (V - VI) Montant Export année N"
    },
    {
        "code": "GV.3",
        "traduction": "RESULTAT FINANCIER (V - VI) Total année N"
    },
    {
        "code": "GV.4",
        "traduction": "RESULTAT FINANCIER (V - VI) Total année N-1"
    },
    {
        "code": "GW.1",
        "traduction": "RESULTAT COURANT AVANT IMPOTS (I - II + III - IV + V - VI) Montant France année N"
    },
    {
        "code": "GW.2",
        "traduction": "RESULTAT COURANT AVANT IMPOTS (I - II + III - IV + V - VI) Montant Export année N"
    },
    {
        "code": "GW.3",
        "traduction": "RESULTAT COURANT AVANT IMPOTS (I - II + III - IV + V - VI) Total année N"
    },
    {
        "code": "GW.4",
        "traduction": "RESULTAT COURANT AVANT IMPOTS (I - II + III - IV + V - VI) Total année N-1"
    },
    {
        "code": "CDR2.1",
        "traduction": "Montant année N"
    },
    {
        "code": "CDR2.2",
        "traduction": "Montant année N-1"
    },
    {
        "code": "HA.1",
        "traduction": "Produits exceptionnels sur opérations de gestion Montant année N"
    },
    {
        "code": "HA.2",
        "traduction": "Produits exceptionnels sur opérations de gestion Montant année N-1"
    },
    {
        "code": "HB.1",
        "traduction": "Produits exceptionnels sur opérations en capital Montant année N"
    },
    {
        "code": "HB.2",
        "traduction": "Produits exceptionnels sur opérations en capital Montant année N-1"
    },
    {
        "code": "HC.1",
        "traduction": "Reprises sur provisions et transferts de charges Montant année N"
    },
    {
        "code": "HC.2",
        "traduction": "Reprises sur provisions et transferts de charges Montant année N-1"
    },
    {
        "code": "HD.1",
        "traduction": "Total des produits exceptionnels (VII) Montant année N"
    },
    {
        "code": "HD.2",
        "traduction": "Total des produits exceptionnels (VII) Montant année N-1"
    },
    {
        "code": "HE.1",
        "traduction": "Charges exceptionnelles sur opérations de gestion Montant année N"
    },
    {
        "code": "HE.2",
        "traduction": "Charges exceptionnelles sur opérations de gestion Montant année N-1"
    },
    {
        "code": "HF.1",
        "traduction": "Charges exceptionnelles sur opérations en capital Montant année N"
    },
    {
        "code": "HF.2",
        "traduction": "Charges exceptionnelles sur opérations en capital Montant année N-1"
    },
    {
        "code": "HG.1",
        "traduction": "Dotations exceptionnelles aux amortissements et provisions Montant année N"
    },
    {
        "code": "HG.2",
        "traduction": "Dotations exceptionnelles aux amortissements et provisions Montant année N-1"
    },
    {
        "code": "HH.1",
        "traduction": "Total des charges exceptionnelles (VIII) Montant année N"
    },
    {
        "code": "HH.2",
        "traduction": "Total des charges exceptionnelles (VIII) Montant année N-1"
    },
    {
        "code": "HI.1",
        "traduction": "RESULTAT EXCEPTIONNEL (VII - VIII) Montant année N"
    },
    {
        "code": "HI.2",
        "traduction": "RESULTAT EXCEPTIONNEL (VII - VIII) Montant année N-1"
    },
    {
        "code": "HJ.1",
        "traduction": "Participation des salariés aux résultats de l’entreprise Montant année N"
    },
    {
        "code": "HJ.2",
        "traduction": "Participation des salariés aux résultats de l’entreprise Montant année N-1"
    },
    {
        "code": "HK.1",
        "traduction": "Impôts sur les bénéfices Montant année N"
    },
    {
        "code": "HK.2",
        "traduction": "Impôts sur les bénéfices Montant année N-1"
    },
    {
        "code": "R1.1",
        "traduction": "Impôts différés Montant année N"
    },
    {
        "code": "R1.2",
        "traduction": "Impôts différés Montant année N-1"
    },
    {
        "code": "R2.1",
        "traduction": "RESULTAT NET AVANT AMORTISSEMENT DES ECARTS D’ACQUISITION Montant année N"
    },
    {
        "code": "R2.2",
        "traduction": "RESULTAT NET AVANT AMORTISSEMENT DES ECARTS D’ACQUISITION Montant année N-1"
    },
    {
        "code": "R3.1",
        "traduction": "Dotation aux amortissements des écarts d’acquisition Montant année N"
    },
    {
        "code": "R3.2",
        "traduction": "Dotation aux amortissements des écarts d’acquisition Montant année N-1"
    },
    {
        "code": "R4.1",
        "traduction": "Résultat net des sociétés mises en équivalence Montant année N"
    },
    {
        "code": "R4.2",
        "traduction": "Résultat net des sociétés mises en équivalence Montant année N-1"
    },
    {
        "code": "R5.1",
        "traduction": "Résultat net des entreprises intégrées Montant année N"
    },
    {
        "code": "R5.2",
        "traduction": "Résultat net des entreprises intégrées Montant année N-1"
    },
    {
        "code": "R6.1",
        "traduction": "Résultat Groupe (Résultat net consolidé) Montant année N"
    },
    {
        "code": "R6.2",
        "traduction": "Résultat Groupe (Résultat net consolidé) Montant année N-1"
    },
    {
        "code": "R7.1",
        "traduction": "Part des intérêts minoritaires (Résultat hors groupe) Montant année N"
    },
    {
        "code": "R7.2",
        "traduction": "Part des intérêts minoritaires (Résultat hors groupe) Montant année N-1"
    },
    {
        "code": "R8.1",
        "traduction": "Résultat net part du groupe (part de la société mère) Montant année N"
    },
    {
        "code": "R8.2",
        "traduction": "Résultat net part du groupe (part de la société mère) Montant année N-1"
    },
    {
        "code": "HP.1",
        "traduction": "Renvois : Crédit-bail mobilier Montant année N"
    },
    {
        "code": "HP.2",
        "traduction": "Renvois : Crédit-bail mobilier Montant année N-1"
    },
    {
        "code": "HQ.1",
        "traduction": "Renvois : Crédit-bail immobilier Montant année N"
    },
    {
        "code": "HQ.2",
        "traduction": "Renvois : Crédit-bail immobilier Montant année N-1"
    }
]